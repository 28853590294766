export var ANTMEDIA_ICE_SERVERS = [{
        urls: 'stun:stun1.l.google.com:19302',
    }];
export var ANTMEDIA_PEERCONNECTION_CONFIG = {
    iceServers: ANTMEDIA_ICE_SERVERS,
};
export var ANTMEDIA_WEBRTC_ADAPTOR_CONSTRAINS = {
    sdpConstraints: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
    },
    mediaConstraints: {
        video: false,
        audio: false,
    },
};
export var ANTMEDIA_DEBUG_MODE = false;
export var ANTMEDIA_WEBRTC_PING_PONG_INTERVAL = 3000;
export var ANTMEDIA_WEBRTC_CONNECTION_TIMEOUT = 3000;
export var ANTMEDIA_WEBRTC_PLAY_TIMEOUT = 5000;
export var ANTMEDIA_WEBRTC_RETRY_PLAYING_TIMEOUT = 5000;
export var ANTMEDIA_WEBRTC_RTC_STATS_INTERVAL = 200;
export var ANTMEDIA_WEBRTC_FPS_MIN = 20;
export var ANTMEDIA_WEBRTC_FPS_TARGET = 25;
export var ANTMEDIA_WEBRTC_FPS_TOLERANCE = 2;
export var ANTMEDIA_WEBRTC_PACKET_LOST_PERCENTAGE_DOWNGRADE = 0.2; // downgrade resolution if >= 20%
export var ANTMEDIA_WEBRTC_PACKET_LOST_PERCENTAGE_UPGRADE = 0.05; // update resolution if <= 5%
export var ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_MEDIUM = 800000;
export var ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_GOOD = 1200000;
export var ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_VERY_GOOD = 2000000;
export var ANTMEDIA_DEFAULT_OPTIONS = {
    enableStats: true,
    autoRetryPlay: false,
    debug: ANTMEDIA_DEBUG_MODE,
};
export var AntmediaWebRTCError;
(function (AntmediaWebRTCError) {
    AntmediaWebRTCError["HIGH_RESOURCE_USAGE"] = "highResourceUsage";
    AntmediaWebRTCError["INTERNAL_ERROR"] = "internalError";
})(AntmediaWebRTCError || (AntmediaWebRTCError = {}));
export var AntmediaWebRTCTimeoutType;
(function (AntmediaWebRTCTimeoutType) {
    AntmediaWebRTCTimeoutType["CONNECTION"] = "connection";
    AntmediaWebRTCTimeoutType["PLAY"] = "play";
})(AntmediaWebRTCTimeoutType || (AntmediaWebRTCTimeoutType = {}));
export var AntmediaWebRTCNetworkLevel;
(function (AntmediaWebRTCNetworkLevel) {
    AntmediaWebRTCNetworkLevel["UNKNOWN"] = "unknown";
    AntmediaWebRTCNetworkLevel["LOW"] = "low";
    AntmediaWebRTCNetworkLevel["MEDIUM"] = "medium";
    AntmediaWebRTCNetworkLevel["GOOD"] = "good";
    AntmediaWebRTCNetworkLevel["VERY_GOOD"] = "very_good";
})(AntmediaWebRTCNetworkLevel || (AntmediaWebRTCNetworkLevel = {}));
