var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import packagejson from '../../assets/package.json';
export var generateRandomId = function (digit) {
    if (digit === void 0) { digit = 16; }
    return __spreadArray([], Array(digit), true).map(function () { return Math.floor(Math.random() * 16).toString(16); }).join('');
};
export var cssPropertiesToStyledComponent = function (dict) {
    var str = '';
    Object.entries(dict).forEach(function (pair) {
        var key = pair[0], value = pair[1];
        var clo = '';
        key.split('').forEach(function (lt) {
            if (lt.toUpperCase() === lt) {
                clo += "-".concat(lt.toLowerCase());
            }
            else {
                clo += lt;
            }
        });
        str += "".concat(clo, ":").concat(value, ";");
    });
    return str;
};
export var addToPathNameUrl = function (url) {
    var values = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        values[_i - 1] = arguments[_i];
    }
    if (!(values === null || values === void 0 ? void 0 : values.length))
        return url;
    var urlObj = new URL(url);
    if (urlObj.pathname)
        urlObj.pathname = urlObj.pathname.trim();
    values.forEach(function (value) {
        var suffix = value === null || value === void 0 ? void 0 : value.trim();
        if (urlObj.pathname.endsWith('/') && value.startsWith('/')) {
            suffix = value.replace('/', '');
        }
        else if (!urlObj.pathname.endsWith('/') && !value.startsWith('/')) {
            suffix = "/".concat(suffix);
        }
        urlObj.pathname += suffix;
    });
    return urlObj.href;
};
export var isMobilePlatform = function () {
    var toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];
    return toMatch.some(function (toMatchItem) { return navigator.userAgent.match(toMatchItem); });
};
export var isTouchEnabled = function () { return (('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0)); };
export var getCurrentNpmModuleVersion = function () { return (packagejson === null || packagejson === void 0 ? void 0 : packagejson.version); };
export var deepEqual = function (obj1, obj2) {
    if (!obj1 || !obj2)
        return false;
    var isObject = function (object) { return object != null && typeof object === 'object'; };
    var keys1 = Object.keys(obj1);
    var keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (var _i = 0, keys1_1 = keys1; _i < keys1_1.length; _i++) {
        var key = keys1_1[_i];
        var val1 = obj1[key];
        var val2 = obj2[key];
        var areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2))
            || (!areObjects && val1 !== val2)) {
            return false;
        }
    }
    return true;
};
export default {
    generateRandomId: generateRandomId,
    cssPropertiesToStyledComponent: cssPropertiesToStyledComponent,
    addToPathNameUrl: addToPathNameUrl,
    getCurrentNpmModuleVersion: getCurrentNpmModuleVersion,
    deepEqual: deepEqual,
};
