import { __awaiter, __generator, __read, __spreadArray } from "tslib";
export var generateRandomId = function (digit) {
    if (digit === void 0) { digit = 16; }
    return __spreadArray([], __read(Array(digit)), false).map(function () { return Math.floor(Math.random() * 16).toString(16); }).join('');
};
export var convertHttpToWsIfNeeded = function (url) {
    if (!url)
        return url;
    var formatUrl = new URL(url);
    if (formatUrl.protocol.startsWith('http')) {
        formatUrl.protocol = formatUrl.protocol.replace('http', 'ws');
    }
    return formatUrl.href;
};
export var convertSrcOvenMediaToUrl = function (src) {
    var result;
    if (typeof src === 'string') {
        result = src;
    }
    else {
        var currUrl = new URL(src.url);
        if (src.policy)
            currUrl.searchParams.set('policy', src.policy);
        if (src.signature)
            currUrl.searchParams.set('signature', src.signature);
        result = currUrl.href;
    }
    return result;
};
export var convertSrcOvenMediaToWebsocketUrl = function (src) {
    return convertHttpToWsIfNeeded(convertSrcOvenMediaToUrl(src));
};
export var generateKalyzeeSocketToken = function (endpoint, userToken, errorCallback) { return __awaiter(void 0, void 0, void 0, function () {
    var init, result, response, error, err_1, status;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                init = {
                    method: 'GET',
                    headers: userToken ? { Authorization: "Bearer ".concat(userToken) } : {},
                };
                result = undefined;
                response = undefined;
                error = undefined;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch(endpoint, init)];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 3:
                result = _a.sent();
                return [3 /*break*/, 5];
            case 4:
                err_1 = _a.sent();
                error = err_1;
                return [3 /*break*/, 5];
            case 5:
                status = response === null || response === void 0 ? void 0 : response.status;
                if (status === 403) {
                    errorCallback === null || errorCallback === void 0 ? void 0 : errorCallback('unauthorized', result);
                    result = undefined;
                }
                else if (status === 401
                    || status === 400 || status === 404) {
                    errorCallback === null || errorCallback === void 0 ? void 0 : errorCallback('not_found', result);
                    result = undefined;
                }
                else if (error || !result) {
                    errorCallback === null || errorCallback === void 0 ? void 0 : errorCallback('error', result);
                    result = undefined;
                }
                return [2 /*return*/, result === null || result === void 0 ? void 0 : result.token];
        }
    });
}); };
export var convertSrcKalyzeeToUrl = function (src, errorCallback) { return __awaiter(void 0, void 0, void 0, function () {
    var result, currUrl, token;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(typeof src === 'string')) return [3 /*break*/, 1];
                result = src;
                return [3 /*break*/, 5];
            case 1:
                currUrl = new URL(src.url);
                if (!src.token) return [3 /*break*/, 2];
                currUrl.searchParams.set('token', src.token);
                return [3 /*break*/, 4];
            case 2:
                if (!src.generateTokenEndpoint) return [3 /*break*/, 4];
                return [4 /*yield*/, generateKalyzeeSocketToken(src.generateTokenEndpoint, src.generateTokenWithUserToken, errorCallback)];
            case 3:
                token = _a.sent();
                if (token) {
                    currUrl.searchParams.set('token', token);
                }
                _a.label = 4;
            case 4:
                result = currUrl.href;
                _a.label = 5;
            case 5: return [2 /*return*/, result];
        }
    });
}); };
export var convertSrcKalyzeeToWebsocketUrl = function (src, errorCallback) { return __awaiter(void 0, void 0, void 0, function () {
    var url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, convertSrcKalyzeeToUrl(src, errorCallback)];
            case 1:
                url = _a.sent();
                if (!url)
                    return [2 /*return*/, undefined];
                return [2 /*return*/, convertHttpToWsIfNeeded(url)];
        }
    });
}); };
export var convertSrcAntmediaToUrl = function (src) {
    var result;
    if (typeof src === 'string') {
        // Extract streamId
        var currUrl = new URL(src);
        var splitted = currUrl.pathname.split('/');
        var streamId = splitted.pop();
        currUrl.pathname = splitted.join('/');
        result = [currUrl.href, streamId];
    }
    else {
        result = [src.url, src.streamId];
    }
    return result;
};
export var convertSrcAntmediaToWebSocketUrl = function (src) {
    var result = convertSrcAntmediaToUrl(src);
    if ((result === null || result === void 0 ? void 0 : result.length) > 0)
        result[0] = convertHttpToWsIfNeeded(result[0]);
    return result;
};
