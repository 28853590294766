export var AntmediaWebRTCSocketState;
(function (AntmediaWebRTCSocketState) {
    AntmediaWebRTCSocketState["CONNECTING"] = "connecting";
    AntmediaWebRTCSocketState["CONNECTED"] = "connected";
    AntmediaWebRTCSocketState["CLOSED"] = "closed";
})(AntmediaWebRTCSocketState || (AntmediaWebRTCSocketState = {}));
export var AntmediaWebRTCAdaptorCallbackType;
(function (AntmediaWebRTCAdaptorCallbackType) {
    AntmediaWebRTCAdaptorCallbackType["INITIALIZED"] = "initialized";
    AntmediaWebRTCAdaptorCallbackType["STREAM_INFO"] = "streamInformation";
    AntmediaWebRTCAdaptorCallbackType["PLAY_START"] = "play_started";
    AntmediaWebRTCAdaptorCallbackType["PLAY_FINISHED"] = "play_finished";
    AntmediaWebRTCAdaptorCallbackType["CLOSE"] = "close";
    AntmediaWebRTCAdaptorCallbackType["BITRATE_MEASUREMENT"] = "bitrateMeasurement";
    AntmediaWebRTCAdaptorCallbackType["ICE_CONNECTION_STATE_CHANGED"] = "ice_connection_state_changed";
    AntmediaWebRTCAdaptorCallbackType["NEW_STREAM_AVAILABLE"] = "newStreamAvailable";
    AntmediaWebRTCAdaptorCallbackType["UPDATE_STATS"] = "updated_stats";
    AntmediaWebRTCAdaptorCallbackType["HIGH_RESOURCE_USAGE"] = "highResourceUsage";
    AntmediaWebRTCAdaptorCallbackType["PUBLISH_STARTED"] = "publish_started";
    AntmediaWebRTCAdaptorCallbackType["PUBLISH_FINISHED"] = "publish_finished";
})(AntmediaWebRTCAdaptorCallbackType || (AntmediaWebRTCAdaptorCallbackType = {}));
export var AntmediaWebRTCAdaptorErrorCallbackType;
(function (AntmediaWebRTCAdaptorErrorCallbackType) {
    AntmediaWebRTCAdaptorErrorCallbackType["NO_STREAM_EXIST"] = "no_stream_exist";
    AntmediaWebRTCAdaptorErrorCallbackType["NOT_SET_REMOTE_DESCRIPTION"] = "notSetRemoteDescription";
    AntmediaWebRTCAdaptorErrorCallbackType["WEBSOCKET_NOT_CONNECTED"] = "WebSocketNotConnected";
})(AntmediaWebRTCAdaptorErrorCallbackType || (AntmediaWebRTCAdaptorErrorCallbackType = {}));
export var AntmediaWebRTCAdaptorCandidateTypes;
(function (AntmediaWebRTCAdaptorCandidateTypes) {
    AntmediaWebRTCAdaptorCandidateTypes["UDP"] = "udp";
    AntmediaWebRTCAdaptorCandidateTypes["TCP"] = "tcp";
})(AntmediaWebRTCAdaptorCandidateTypes || (AntmediaWebRTCAdaptorCandidateTypes = {}));
