import { __awaiter, __extends, __generator } from "tslib";
import { convertSrcOvenMediaToWebsocketUrl } from "../service/utils";
import { SocketSession, SocketType } from "../session/socket";
export var OvenMediaSocketSessionAction;
(function (OvenMediaSocketSessionAction) {
    OvenMediaSocketSessionAction["REQUEST_OFFER"] = "request_offer";
    OvenMediaSocketSessionAction["OFFER"] = "offer";
    OvenMediaSocketSessionAction["ANSWER"] = "answer";
    OvenMediaSocketSessionAction["NOTIFICATION"] = "notification";
    OvenMediaSocketSessionAction["CANDIDATE"] = "candidate";
    OvenMediaSocketSessionAction["CHANGE_RENDITION"] = "change_rendition";
})(OvenMediaSocketSessionAction || (OvenMediaSocketSessionAction = {}));
export var OvenMediaSocketSessionNotificationType;
(function (OvenMediaSocketSessionNotificationType) {
    OvenMediaSocketSessionNotificationType["PLAYLIST"] = "playlist";
    OvenMediaSocketSessionNotificationType["RENDITION_CHANGED"] = "rendition_changed";
})(OvenMediaSocketSessionNotificationType || (OvenMediaSocketSessionNotificationType = {}));
var SOCKET_TYPE_VALUE = SocketType.WEBSOCKET;
var OvenMediaSocketSession = /** @class */ (function (_super) {
    __extends(OvenMediaSocketSession, _super);
    function OvenMediaSocketSession() {
        var _this = _super.call(this, SOCKET_TYPE_VALUE) || this;
        _this.addEventListener('connect', function () {
            _this.emitStart();
        });
        return _this;
    }
    OvenMediaSocketSession.prototype.destroy = function (reason) {
        this.close(reason);
        this.removeAllEventListeners();
    };
    OvenMediaSocketSession.prototype.start = function (src) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.connect.call(this, convertSrcOvenMediaToWebsocketUrl(src))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // ---------------- Emit message ------------------ //
    OvenMediaSocketSession.prototype.emitMessage = function (message) {
        this.emit(JSON.stringify(message));
    };
    OvenMediaSocketSession.prototype.emitStart = function () {
        if (!this.socket)
            return;
        var message = {
            command: OvenMediaSocketSessionAction.REQUEST_OFFER,
        };
        this.emitMessage(message);
    };
    OvenMediaSocketSession.prototype.emitStop = function () {
        return; // No stop message
    };
    OvenMediaSocketSession.prototype.emitSpd = function (data) {
        var _a;
        if (!this.socket || this.id === undefined || this.peerId === undefined)
            return;
        var sdp = { sdp: data === null || data === void 0 ? void 0 : data.sdp, type: data === null || data === void 0 ? void 0 : data.type };
        sdp.sdp = (_a = sdp.sdp) === null || _a === void 0 ? void 0 : _a.replace(/a=inactive/g, 'a=sendonly');
        var message = {
            command: OvenMediaSocketSessionAction.ANSWER,
            id: this.id,
            peer_id: this.peerId,
            sdp: sdp
        };
        this.emitMessage(message);
    };
    OvenMediaSocketSession.prototype.emitIceCandidate = function (data) {
        if (!this.socket || this.id === undefined || this.peerId === undefined)
            return;
        var message = {
            command: OvenMediaSocketSessionAction.CANDIDATE,
            id: this.id,
            peer_id: this.peerId,
            candidates: [data],
        };
        this.emitMessage(message);
    };
    // ---------------- Receive message ------------------ //
    OvenMediaSocketSession.prototype.parseIncomingMessage = function (message) {
        var result;
        if (typeof message === 'string') {
            try {
                result = JSON.parse(message);
            }
            catch (error) { }
        }
        else {
            result = message;
        }
        return result;
    };
    OvenMediaSocketSession.prototype.onReceivedMessage = function (data) {
        var _a, _b;
        var message = this.parseIncomingMessage(data);
        if (!message)
            return;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'socketOnMessage : ', message, message.command);
        if (message.command === OvenMediaSocketSessionAction.OFFER) {
            var offer = message;
            if (offer.id === undefined || offer.peer_id === undefined)
                return;
            this.id = offer.id;
            this.peerId = offer.peer_id;
            var candidates_1 = [];
            var sdp = undefined;
            if (offer.sdp) {
                sdp = offer.sdp;
            }
            if (offer.candidates) {
                offer.candidates.forEach(function (candidate) {
                    if (candidate)
                        candidates_1.push(candidate);
                });
            }
            this.triggersStartListeners(offer.iceServers, sdp, candidates_1);
        }
        else if (!message.command && message.error) {
            this.close("Ovenmedia socket error : ".concat(message.error));
        }
        (_b = this.onMessage) === null || _b === void 0 ? void 0 : _b.call(this, message);
    };
    return OvenMediaSocketSession;
}(SocketSession));
export { OvenMediaSocketSession };
