import { __assign, __awaiter, __extends, __generator, __read, __spreadArray } from "tslib";
import 'webrtc-adapter';
import { RTCSession } from './rtc';
import { getLogHeader, LogColors } from '../service/logs';
import { BaseWebRTCSession, WebRTCSessionState } from './session';
export var defaultWebRTCLoggerOptions = {
    enableRtc: true,
    enableWebsocket: true,
    enableWebrtc: true,
};
var WebRTCSession = /** @class */ (function (_super) {
    __extends(WebRTCSession, _super);
    function WebRTCSession(socketSession, options) {
        var _this = _super.call(this, options) || this;
        _this.socketSession = socketSession;
        _this.rtcSession = new RTCSession();
        _this.initCallbacks();
        _this.closed = false;
        return _this;
    }
    WebRTCSession.prototype.initSocketListenerStart = function () {
        var _this = this;
        this.socketSession.addEventListener('start', function (iceServers, offer, candidates) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rtcSession.start(iceServers, offer, candidates)];
                    case 1:
                        _a.sent();
                        this.triggersInitializedListeners();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    WebRTCSession.prototype.initSocketListenerIceCandidate = function () {
        var _this = this;
        this.socketSession.addEventListener('iceCandidate', function (candidate) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rtcSession.processIceCandidate(candidate)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    WebRTCSession.prototype.initSocketListenerSdp = function () {
        var _this = this;
        this.socketSession.addEventListener('sdp', function (sdp) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rtcSession.processSdp(sdp)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    WebRTCSession.prototype.initSocketListenerClose = function () {
        var _this = this;
        this.socketSession.addEventListener('close', function () {
            _this.close('Socket closed');
        });
    };
    WebRTCSession.prototype.initSocketListenerError = function () {
        var _this = this;
        this.socketSession.addEventListener('error', function (error) {
            _this.close("Socket error : ".concat(error));
            _this.state = WebRTCSessionState.ERROR;
        });
    };
    WebRTCSession.prototype.initRtcListenerConnect = function () {
        var _this = this;
        this.rtcSession.addEventListener('connect', function () {
            _this.state = WebRTCSessionState.RUNNING;
        });
    };
    WebRTCSession.prototype.initRtcListenerIceCandidate = function () {
        var _this = this;
        this.rtcSession.addEventListener('iceCandidate', function (candidate) {
            _this.socketSession.emitIceCandidate(candidate);
        });
    };
    WebRTCSession.prototype.initRtcListenerSdpOffer = function () {
        var _this = this;
        this.rtcSession.addEventListener('sdpOffer', function (offer) {
            _this.socketSession.emitSpd(offer);
        });
    };
    WebRTCSession.prototype.initRtcListenerStream = function () {
        var _this = this;
        this.rtcSession.addEventListener('stream', function (stream) {
            var _a;
            (_a = _this.onStream) === null || _a === void 0 ? void 0 : _a.call(_this, stream);
            var rtcConnection = _this.rtcSession.getRTCPeerConnection();
            if (stream && rtcConnection) {
                if (_this.options.enableRtcStats) {
                    var tracks = stream.getTracks();
                    tracks.forEach(function (track) { return _this.enableStats(rtcConnection, track); });
                }
            }
        });
    };
    WebRTCSession.prototype.initRtcListenerClose = function () {
        var _this = this;
        this.rtcSession.addEventListener('close', function () {
            _this.close('RTC closed');
        });
    };
    WebRTCSession.prototype.initRtcListenerError = function () {
        var _this = this;
        this.rtcSession.addEventListener('error', function (error) {
            _this.close("RTC error : ".concat(error));
            _this.state = WebRTCSessionState.ERROR;
        });
    };
    WebRTCSession.prototype.initCallbacks = function () {
        // ---- SOCKET ------ //    
        this.initSocketListenerStart();
        this.initSocketListenerIceCandidate();
        this.initSocketListenerSdp();
        this.initSocketListenerClose();
        this.initSocketListenerError();
        // ---- RTC ------ //
        this.initRtcListenerConnect();
        this.initRtcListenerIceCandidate();
        this.initRtcListenerSdpOffer();
        this.initRtcListenerStream();
        this.initRtcListenerClose();
        this.initRtcListenerError();
    };
    WebRTCSession.prototype.setLogger = function (logger, options) {
        var opt = __assign(__assign({}, defaultWebRTCLoggerOptions), (options || {}));
        if (logger) {
            if (opt.enableWebrtc) {
                this.log = function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    logger.apply(void 0, __spreadArray([getLogHeader('WEBRTC', LogColors.FG_BLUE)], __read(args), false));
                };
            }
            else {
                this.log = undefined;
            }
            if (opt.enableWebsocket) {
                this.socketSession.setLogger(function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    logger.apply(void 0, __spreadArray([getLogHeader('SOCKET', LogColors.FG_GREEN)], __read(args), false));
                });
            }
            else {
                this.socketSession.setLogger(undefined);
            }
            if (opt.enableRtc) {
                this.rtcSession.setLogger(function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    logger.apply(void 0, __spreadArray([getLogHeader('RTC', LogColors.FG_YELLOW)], __read(args), false));
                });
            }
            else {
                this.rtcSession.setLogger(undefined);
            }
        }
        else {
            this.log = undefined;
            this.socketSession.setLogger(undefined);
            this.rtcSession.setLogger(undefined);
        }
    };
    WebRTCSession.prototype.startSession = function (url, stream) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var success, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'start');
                        this.started = true;
                        this.closed = false;
                        this.state = WebRTCSessionState.LOADING;
                        this.localStream = stream;
                        this.rtcSession.localStream = stream;
                        success = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.socketSession.start(url)];
                    case 2:
                        _b.sent();
                        success = true;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.started = false;
                        this.state = WebRTCSessionState.ERROR;
                        this.triggersErrorListeners(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, success];
                }
            });
        });
    };
    WebRTCSession.prototype.close = function (reason) {
        var _a;
        if (this.closed)
            return;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, "CLOSED : ".concat(reason));
        this.started = false;
        this.closed = true;
        this.state = WebRTCSessionState.CLOSED;
        this.socketSession.close(reason);
        this.rtcSession.close();
        this.triggersCloseListeners(reason);
    };
    WebRTCSession.prototype.stop = function (reason) {
        this.close(reason);
    };
    WebRTCSession.prototype.getSocketSession = function () {
        return this.socketSession;
    };
    WebRTCSession.prototype.getRTCSession = function () {
        return this.rtcSession;
    };
    // ---------------- WebRTC Actions ------------------ //
    WebRTCSession.prototype.addStream = function (stream) {
        this.rtcSession.addStream(stream);
    };
    return WebRTCSession;
}(BaseWebRTCSession));
export { WebRTCSession };
