import { SocketActionType } from "../../constants";
// ------------------------------- //
// ------------ Device ------------ // 
// ------------------------------- //
function getDevices(options) {
    return this.emitMessage(SocketActionType.ORGANIZATION_DEVICES, undefined, options);
}
function getDeviceUpdateSessions(options) {
    return this.emitMessage(SocketActionType.ORGANIZATION_DEVICES_UPDATE_SESSIONS, undefined, options);
}
function getInformationAboutDevices(payload, options) {
    return this.emitMessage(SocketActionType.ORGANIZATION_DEVICES_INFOMRATION, payload !== null && payload !== void 0 ? payload : {}, options);
}
var organizationCommands = {
    // Device
    getDevices: getDevices,
    getDeviceUpdateSessions: getDeviceUpdateSessions,
    getInformationAboutDevices: getInformationAboutDevices
};
export default organizationCommands;
