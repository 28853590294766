import React, { useEffect, useImperativeHandle, useState } from 'react';
import './DisplayTimeControls.css';
export var VideoDisplayTimeMode;
(function (VideoDisplayTimeMode) {
    VideoDisplayTimeMode["NONE"] = "none";
    VideoDisplayTimeMode["NORMAL"] = "normal";
    VideoDisplayTimeMode["CURRENT_TIME_ONLY"] = "current_time_only";
})(VideoDisplayTimeMode || (VideoDisplayTimeMode = {}));
var DEFAULT_DURATION = 0;
var DEFAULT_STARTS_AT = 0;
var DEFAULT_CURRENT_TIME = 0;
var DEFAULT_DISPLAY_MODE = VideoDisplayTimeMode.NORMAL;
var MINUTE = 60; // in seconds
var HOUR = 60 * MINUTE;
export var VideoDisplayTimeControls = React.forwardRef(function (_a, forwardRef) {
    var currentTime = _a.currentTime, startsAt = _a.startsAt, duration = _a.duration, displayMode = _a.displayMode, className = _a.className, style = _a.style;
    var _b = useState(displayMode !== null && displayMode !== void 0 ? displayMode : DEFAULT_DISPLAY_MODE), stateDisplayMode = _b[0], setStateDisplayMode = _b[1];
    var _c = useState(currentTime !== null && currentTime !== void 0 ? currentTime : DEFAULT_CURRENT_TIME), stateCurrentTime = _c[0], setStateCurrentTime = _c[1];
    var _d = useState(startsAt !== null && startsAt !== void 0 ? startsAt : DEFAULT_STARTS_AT), stateStartsAt = _d[0], setStateStartsAt = _d[1];
    var _e = useState(duration !== null && duration !== void 0 ? duration : DEFAULT_DURATION), stateDuration = _e[0], setStateDuration = _e[1];
    useEffect(function () { return setStateCurrentTime(currentTime !== null && currentTime !== void 0 ? currentTime : DEFAULT_CURRENT_TIME); }, [currentTime]);
    useEffect(function () { return setStateStartsAt(startsAt !== null && startsAt !== void 0 ? startsAt : DEFAULT_STARTS_AT); }, [startsAt]);
    useEffect(function () { return setStateDuration(duration !== null && duration !== void 0 ? duration : DEFAULT_DURATION); }, [duration]);
    useEffect(function () { return setStateDisplayMode(displayMode !== null && displayMode !== void 0 ? displayMode : DEFAULT_DISPLAY_MODE); }, [displayMode]);
    useImperativeHandle(forwardRef, function () { return ({
        setCurrentTime: function (value) { return setStateCurrentTime(value); },
        setStartsAt: function (value) { return setStateStartsAt(value); },
        setDuration: function (value) { return setStateDuration(value); },
        setDisplayMode: function (value) { return setStateDisplayMode(value); },
    }); }, []);
    var start = stateStartsAt !== null && stateStartsAt !== void 0 ? stateStartsAt : DEFAULT_STARTS_AT;
    var current = start + (stateCurrentTime !== null && stateCurrentTime !== void 0 ? stateCurrentTime : DEFAULT_CURRENT_TIME);
    var end = start + (stateDuration !== null && stateDuration !== void 0 ? stateDuration : DEFAULT_DURATION);
    var convertTimeToHourMinuteSeconde = function (time) {
        var currentSeconds = time;
        var hours = Math.floor(currentSeconds / HOUR);
        currentSeconds -= hours * HOUR;
        var minutes = Math.floor(currentSeconds / MINUTE);
        currentSeconds -= minutes * MINUTE;
        var secondes = Math.floor(currentSeconds);
        if (hours > 0) {
            return "".concat(hours, ":").concat(("0".concat(minutes)).slice(-2), ":").concat(("0".concat(secondes)).slice(-2));
        }
        return "".concat(minutes, ":").concat(("0".concat(secondes)).slice(-2));
    };
    var renderContent = function () {
        if (stateDisplayMode === VideoDisplayTimeMode.NONE)
            return null;
        if (stateDisplayMode === VideoDisplayTimeMode.CURRENT_TIME_ONLY) {
            if (Number.isNaN(current))
                return '-';
            return convertTimeToHourMinuteSeconde(current);
        }
        if (stateDisplayMode === VideoDisplayTimeMode.NORMAL) {
            if (Number.isNaN(current) || Number.isNaN(end))
                return '-';
            return "".concat(convertTimeToHourMinuteSeconde(current), " / ").concat(convertTimeToHourMinuteSeconde(end));
        }
        return null;
    };
    var divStyle = {};
    if (style)
        Object.assign(divStyle, style);
    var classes = ['video_display_time-container'];
    if (className)
        classes.push(className);
    var render = function () { return (React.createElement("div", { style: divStyle, className: classes.join(' ') }, renderContent())); };
    return stateDisplayMode === VideoDisplayTimeMode.NONE ? null : render();
});
VideoDisplayTimeControls.defaultProps = {
    currentTime: DEFAULT_CURRENT_TIME,
    startsAt: DEFAULT_STARTS_AT,
    duration: DEFAULT_DURATION,
    displayMode: DEFAULT_DISPLAY_MODE,
    className: undefined,
    style: undefined,
};
export default VideoDisplayTimeControls;
