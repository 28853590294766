import React, { useEffect, useState } from 'react';
import { Touchable } from './Touchable';
import './TouchableSwitch.css';
var DEFAULT_VALUE = true;
export var TouchableSwitchTrigger;
(function (TouchableSwitchTrigger) {
    TouchableSwitchTrigger["PRESS_IN"] = "press_in";
    TouchableSwitchTrigger["PRESS_OUT"] = "press_out";
})(TouchableSwitchTrigger || (TouchableSwitchTrigger = {}));
export var TouchableSwitch = function (_a) {
    var enable = _a.enable, children = _a.children, onEnable = _a.onEnable, trigger = _a.trigger, className = _a.className, style = _a.style;
    var _b = useState(enable === undefined ? DEFAULT_VALUE : enable), enabled = _b[0], setEnable = _b[1];
    useEffect(function () {
        setEnable(enable === undefined ? DEFAULT_VALUE : enable);
    }, [enable]);
    var render = function () { return (React.createElement(Touchable, { onPressIn: function (event) {
            if (trigger !== TouchableSwitchTrigger.PRESS_IN)
                return;
            onEnable === null || onEnable === void 0 ? void 0 : onEnable(!enabled, event);
            setEnable(!enabled);
        }, onPressOut: function (event) {
            if (trigger !== TouchableSwitchTrigger.PRESS_OUT)
                return;
            onEnable === null || onEnable === void 0 ? void 0 : onEnable(!enabled, event);
            setEnable(!enabled);
        }, style: style, className: className }, children(enabled))); };
    return render();
};
TouchableSwitch.defaultProps = {
    enable: false,
    onEnable: undefined,
    trigger: TouchableSwitchTrigger.PRESS_IN,
    className: undefined,
    style: undefined,
};
export default TouchableSwitch;
