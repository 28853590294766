import React from 'react';
import { ElementOnHover } from './ElementOnHover';
import InfoIcon from '../../../assets/icons/info.svg';
import WarningIcon from '../../../assets/icons/warning.svg';
import ErrorIcon from '../../../assets/icons/error.svg';
import IconSuccess from '../../../assets/icons/success.svg';
import './MessageOnHover.css';
export var MessageOnHoverMode;
(function (MessageOnHoverMode) {
    MessageOnHoverMode["INFO"] = "info";
    MessageOnHoverMode["WARNING"] = "warning";
    MessageOnHoverMode["ERROR"] = "error";
    MessageOnHoverMode["SUCCESS"] = "success";
})(MessageOnHoverMode || (MessageOnHoverMode = {}));
var DEFAULT_MODE = MessageOnHoverMode.INFO;
export function MessageOnHover(_a) {
    var targetRef = _a.targetRef, hoverOnParentLevel = _a.hoverOnParentLevel, mode = _a.mode, message = _a.message, delay = _a.delay, enableTouch = _a.enableTouch, children = _a.children, className = _a.className, style = _a.style;
    var messageClasses = ['message_on_hover-message-container'];
    if (mode === MessageOnHoverMode.INFO)
        messageClasses.push('message_on_hover-info');
    if (mode === MessageOnHoverMode.WARNING)
        messageClasses.push('message_on_hover-warning');
    if (mode === MessageOnHoverMode.ERROR)
        messageClasses.push('message_on_hover-error');
    if (mode === MessageOnHoverMode.SUCCESS)
        messageClasses.push('message_on_hover-success');
    var renderMessage = function () {
        var _a;
        var renderImage = function () {
            var src = null;
            var alt = '';
            if (mode === MessageOnHoverMode.INFO) {
                src = InfoIcon;
                alt = 'info';
            }
            else if (mode === MessageOnHoverMode.WARNING) {
                src = WarningIcon;
                alt = 'warning';
            }
            else if (mode === MessageOnHoverMode.ERROR) {
                src = ErrorIcon;
                alt = 'error';
            }
            else if (mode === MessageOnHoverMode.SUCCESS) {
                src = IconSuccess;
                alt = 'success';
            }
            if (!src)
                return null;
            return (React.createElement("img", { className: "message_on_hover-image", src: src, alt: alt }));
        };
        return (React.createElement("div", { style: style, className: messageClasses.join(' ') },
            renderImage(),
            React.createElement("div", { style: { whiteSpace: 'pre-wrap' } }, (_a = children !== null && children !== void 0 ? children : message) !== null && _a !== void 0 ? _a : '')));
    };
    var classes = ['message_on_hover-container'];
    if (className)
        classes.push(className);
    return (React.createElement(ElementOnHover, { targetRef: targetRef, hoverOnParentLevel: hoverOnParentLevel, delay: delay, enableTouch: enableTouch, className: classes.join(' ') }, renderMessage()));
}
MessageOnHover.defaultProps = {
    message: undefined,
    hoverOnParentLevel: 0,
    mode: DEFAULT_MODE,
    enableTouch: true,
    delay: 500,
    children: undefined,
    className: undefined,
    style: undefined,
};
export default MessageOnHover;
