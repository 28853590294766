import React from 'react';
import { TouchableSwitch } from './TouchableSwitch';
import './TouchableImageSwitch.css';
export var TouchableImageSwitch = function (_a) {
    var srcEnabled = _a.srcEnabled, srcDisabled = _a.srcDisabled, enable = _a.enable, onEnable = _a.onEnable, alt = _a.alt, imageStyle = _a.imageStyle, imageClass = _a.imageClass, trigger = _a.trigger, className = _a.className, style = _a.style;
    var imageClasses = ['touchable_image_switch-button'];
    if (imageClass)
        imageClasses.push(imageClass);
    var render = function () { return (React.createElement(TouchableSwitch, { enable: enable, className: className, style: style, onEnable: onEnable, trigger: trigger }, function (enabled) { return (React.createElement("img", { src: enabled ? srcEnabled : srcDisabled, style: imageStyle, alt: alt || (enabled ? 'on' : 'off'), className: imageClasses.join(' ') })); })); };
    return render();
};
TouchableImageSwitch.defaultProps = {
    enable: false,
    onEnable: undefined,
    alt: undefined,
    imageStyle: undefined,
    imageClass: undefined,
    className: undefined,
    style: undefined,
};
export default TouchableImageSwitch;
