import { __awaiter, __extends, __generator, __read } from "tslib";
import 'webrtc-adapter';
import { Session } from './session';
var RTCSession = /** @class */ (function (_super) {
    __extends(RTCSession, _super);
    function RTCSession() {
        var _this = _super.call(this) || this;
        _this.rtcConnectionState = 'new';
        return _this;
    }
    RTCSession.prototype.start = function (iceServers, offer, candidates) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'start');
                        this.started = true;
                        this.closed = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.connect(iceServers, offer, candidates)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.started = false;
                        this.triggersErrorListeners(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RTCSession.prototype.close = function () {
        var _a;
        if (this.closed)
            return;
        this.started = false;
        this.closed = true;
        (_a = this.rtcPeerConnection) === null || _a === void 0 ? void 0 : _a.close();
        this.triggersCloseListeners();
        this.rtcPeerConnection = undefined;
    };
    RTCSession.prototype.stop = function () {
        this.close();
    };
    RTCSession.prototype.destroy = function () {
        this.close();
        this.removeAllEventListeners();
    };
    RTCSession.prototype.getRTCPeerConnection = function () {
        return this.rtcPeerConnection;
    };
    // ---------------- Listener ------------------ //
    RTCSession.prototype.triggersConnectedListeners = function () {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'connected');
        (_b = this.listeners.connect) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(); });
    };
    RTCSession.prototype.triggersStreamListeners = function (stream) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'streamChange : ', stream);
        (_b = this.listeners.stream) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(stream); });
    };
    RTCSession.prototype.triggersErrorListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'error', error);
        (_b = this.listeners.error) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(error); });
    };
    RTCSession.prototype.triggersCloseListeners = function () {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'close');
        (_b = this.listeners.close) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(); });
    };
    RTCSession.prototype.triggersIceCandidateListeners = function (candidate) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'iceCandidateCB : ', candidate);
        (_b = this.listeners.iceCandidate) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(candidate); });
    };
    RTCSession.prototype.triggersSdpOfferListeners = function (offer) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'sdpOfferCB : ', offer);
        (_b = this.listeners.sdpOffer) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(offer); });
    };
    // ---------------- RTC ------------------ //
    RTCSession.prototype.connect = function (iceServers, offer, candidates) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var configuration, peerConnection, promises_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'Connect - iceservers : ', iceServers, offer, candidates);
                        configuration = {
                            iceServers: iceServers,
                        };
                        peerConnection = new RTCPeerConnection(configuration);
                        this.rtcPeerConnection = peerConnection;
                        peerConnection.onicecandidate = this.rtcEventOnIceCandidate.bind(this);
                        peerConnection.ontrack = this.rtcEventOnTrack.bind(this);
                        peerConnection.onconnectionstatechange = this.rtcEventOnConnectState.bind(this);
                        if (this.log) {
                            peerConnection.oniceconnectionstatechange = function (ev) {
                                var _a;
                                (_a = _this.log) === null || _a === void 0 ? void 0 : _a.call(_this, 'oniceconnectionstatechange : ', ev, peerConnection);
                            };
                            peerConnection.onicegatheringstatechange = function (ev) {
                                var _a;
                                (_a = _this.log) === null || _a === void 0 ? void 0 : _a.call(_this, 'onicegatheringstatechange : ', ev, peerConnection);
                            };
                            peerConnection.onsignalingstatechange = function (ev) {
                                var _a;
                                (_a = _this.log) === null || _a === void 0 ? void 0 : _a.call(_this, 'onsignalingstatechange : ', ev, peerConnection);
                            };
                        }
                        if (!!offer) return [3 /*break*/, 1];
                        peerConnection.onnegotiationneeded = this.rtcEventOnNegotiationNeeded.bind(this);
                        return [3 /*break*/, 3];
                    case 1:
                        if (this.localStream)
                            this.addStream(this.localStream);
                        if (!(offer.type === 'offer')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.processSdp(offer)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        if (!candidates) return [3 /*break*/, 5];
                        promises_1 = [];
                        candidates.forEach(function (candidate) { return promises_1.push(_this.processIceCandidate(candidate)); });
                        return [4 /*yield*/, Promise.all(promises_1)];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5: return [2 /*return*/, peerConnection];
                }
            });
        });
    };
    RTCSession.prototype.processOfferCreated = function (description) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.rtcPeerConnection)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.rtcPeerConnection.setLocalDescription(description)];
                    case 1:
                        _a.sent();
                        this.rtcEventOnSdpOffer(this.rtcPeerConnection.localDescription);
                        return [2 /*return*/];
                }
            });
        });
    };
    // ---------------- RTC Actions ------------------ //
    RTCSession.prototype.processSdp = function (data) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var description, err_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'processSdp : ', data, this.rtcPeerConnection);
                        if (!this.rtcPeerConnection)
                            return [2 /*return*/];
                        if (!data)
                            return [2 /*return*/];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.rtcPeerConnection.setRemoteDescription(data)];
                    case 2:
                        _d.sent();
                        if (!(((_c = (_b = this.rtcPeerConnection) === null || _b === void 0 ? void 0 : _b.remoteDescription) === null || _c === void 0 ? void 0 : _c.type) === 'offer')) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.rtcPeerConnection.createAnswer()];
                    case 3:
                        description = _d.sent();
                        return [4 /*yield*/, this.processOfferCreated(description)];
                    case 4:
                        _d.sent();
                        _d.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_2 = _d.sent();
                        this.rtcEventOnError(err_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    RTCSession.prototype.processIceCandidate = function (data) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'processIceCandidate : ', data, this.rtcPeerConnection);
                        if (!this.rtcPeerConnection)
                            return [2 /*return*/];
                        if (!data)
                            return [2 /*return*/];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.rtcPeerConnection.addIceCandidate(data)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _b.sent();
                        this.rtcEventOnError(err_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RTCSession.prototype.addStream = function (stream) {
        var _this = this;
        var tracks = stream.getTracks();
        tracks.forEach(function (track) {
            var _a;
            // this.rtcPeerConnection?.addTransceiver(track, { streams: [stream] });
            (_a = _this.rtcPeerConnection) === null || _a === void 0 ? void 0 : _a.addTrack(track, stream);
        });
    };
    // ---------------- RTC Events ------------------ //
    RTCSession.prototype.rtcEventOnConnectState = function (ev) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'rtcEventOnConnectState : ', ev, this.rtcPeerConnection, (_b = this.rtcPeerConnection) === null || _b === void 0 ? void 0 : _b.connectionState);
        if (!this.rtcPeerConnection || this.closed)
            return;
        var newState = this.rtcPeerConnection.connectionState;
        var lastState = this.rtcConnectionState;
        this.rtcConnectionState = newState;
        if ((newState !== 'connected' && newState !== 'connecting' && lastState === 'connected')
            || newState === 'closed' || newState === 'disconnected' || newState === 'failed') {
            this.close();
        }
        else if (newState === 'connected' && lastState !== 'connected') {
            this.triggersConnectedListeners();
        }
    };
    RTCSession.prototype.rtcEventOnIceCandidate = function (ev) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'rtcEventOnIceCandidate : ', ev);
        this.triggersIceCandidateListeners((_b = ev.candidate) !== null && _b !== void 0 ? _b : null);
    };
    RTCSession.prototype.rtcEventOnSdpOffer = function (offer) {
        var _a;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'rtcEventOnSdpOffer : ', offer);
        this.triggersSdpOfferListeners(offer);
    };
    RTCSession.prototype.rtcEventOnNegotiationNeeded = function (ev) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var description, err_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'rtcEventOnNegotiationNeeded : ', ev);
                        if (!this.rtcPeerConnection)
                            return [2 /*return*/];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.rtcPeerConnection.createOffer()];
                    case 2:
                        description = _b.sent();
                        return [4 /*yield*/, this.processOfferCreated(description)];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_4 = _b.sent();
                        this.rtcEventOnError(err_4);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    RTCSession.prototype.rtcEventOnTrack = function (ev) {
        var _a;
        var _b, _c;
        (_b = this.log) === null || _b === void 0 ? void 0 : _b.call(this, 'rtcEventOnTrack : ', ev);
        if ((_c = ev.streams) === null || _c === void 0 ? void 0 : _c.length) {
            _a = __read(ev.streams, 1), this.stream = _a[0];
            this.triggersStreamListeners(this.stream);
        }
    };
    RTCSession.prototype.rtcEventOnError = function (error) {
        var _a;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'rtcEventOnError : ', error);
        this.triggersErrorListeners(error);
    };
    return RTCSession;
}(Session));
export { RTCSession };
