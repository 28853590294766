var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { useStateFullScreen } from '../../hooks/fullscreen';
import { VideoControls } from './VideoControls';
import { CameraControls } from './CameraControls';
import { useElementHovered, useElementSize } from '../../hooks/window';
import CloseButton from '../../../assets/icons/close.svg';
import { PlayerControlsDefaultCommonProps } from '../../constants/player';
import { TouchableImage } from '../util/TouchableImage';
import { Touchable } from '../util/Touchable';
import { useOuterClick } from '../../hooks/click';
import { IconInfo } from '../svg/IconInfo';
import './PlayerControls.css';
var DEFAULT_ENABLED = true;
export var PlayerControls = React.forwardRef(function (_a, forwardRef) {
    var enabled = _a.enabled, fullscreenTargetRef = _a.fullscreenTargetRef, enablePlay = _a.enablePlay, startsAt = _a.startsAt, duration = _a.duration, displayInfo = _a.displayInfo, additionnalDetailNodes = _a.additionnalDetailNodes, 
    // Controls
    onDisplayVideoControls = _a.onDisplayVideoControls, onDisplayCameraControls = _a.onDisplayCameraControls, enableVideoControls = _a.enableVideoControls, enableCameraControls = _a.enableCameraControls, alwaysDisplayVideoControls = _a.alwaysDisplayVideoControls, alwaysDisplayCameraControls = _a.alwaysDisplayCameraControls, delayAfterHideControls = _a.delayAfterHideControls, enableVolume = _a.enableVolume, enableFullscreen = _a.enableFullscreen, enableMove = _a.enableMove, enableScene = _a.enableScene, enableView = _a.enableView, enableZoom = _a.enableZoom, enableVideoProgress = _a.enableVideoProgress, displayTime = _a.displayTime, 
    // Controls by keyboard
    enableZoomKeyboardEvent = _a.enableZoomKeyboardEvent, enableViewKeyboardEvent = _a.enableViewKeyboardEvent, enableAssignViewKeyboardEvent = _a.enableAssignViewKeyboardEvent, enableSceneKeyboardEvent = _a.enableSceneKeyboardEvent, enableMoveKeyboardEvent = _a.enableMoveKeyboardEvent, zoomMappingKeyboardKeys = _a.zoomMappingKeyboardKeys, viewMappingKeyboardKeys = _a.viewMappingKeyboardKeys, assignViewMappingKeyboardKeys = _a.assignViewMappingKeyboardKeys, sceneMappingKeyboardKeys = _a.sceneMappingKeyboardKeys, moveMappingKeyboardKeys = _a.moveMappingKeyboardKeys, 
    // Video
    mute = _a.mute, volume = _a.volume, play = _a.play, currentTime = _a.currentTime, onMute = _a.onMute, onVolume = _a.onVolume, onPlay = _a.onPlay, onTime = _a.onTime, 
    // Camera
    scene = _a.scene, view = _a.view, onMove = _a.onMove, onStopMove = _a.onStopMove, onZoom = _a.onZoom, onStopZoom = _a.onStopZoom, onScene = _a.onScene, onView = _a.onView, onAssignView = _a.onAssignView, color = _a.color, className = _a.className, style = _a.style;
    var _b = useStateFullScreen(false, fullscreenTargetRef), fullscreen = _b[0], setFullscreen = _b[1];
    var ref = useRef(null);
    var elementSize = useElementSize(ref);
    var cameraControlsRef = useRef();
    var videoControlsRef = useRef();
    var displayControlsFromTouch = useRef(false);
    var displayControls = useRef({
        controls: false,
        camera: alwaysDisplayCameraControls !== null && alwaysDisplayCameraControls !== void 0 ? alwaysDisplayCameraControls : false,
        video: (alwaysDisplayVideoControls !== null && alwaysDisplayVideoControls !== void 0 ? alwaysDisplayVideoControls : false) || !play,
        info: false,
    });
    var topRightContainerRef = useRef(null);
    var closeButtonRef = useRef(null);
    var infoRef = useRef(null);
    var additionnalContainerRef = useRef(null);
    var supportContentInfoRef = useRef(null);
    var hideControlsTimeout = useRef();
    var showInfo = function (show) {
        var supportDiv = supportContentInfoRef.current;
        if (!supportDiv)
            return;
        if (show) {
            displayControls.current.info = true;
            supportDiv.classList.remove('player_controls-display-none');
        }
        else {
            displayControls.current.info = false;
            supportDiv.classList.add('player_controls-display-none');
            if (topRightContainerRef.current && infoRef.current && !displayControls.current.controls) {
                infoRef.current.classList.add('player_controls-display-none');
                if (!displayControls.current.controls) {
                    topRightContainerRef.current.classList.add('player_controls-display-none');
                }
            }
        }
    };
    var setDisplayControlsValue = function (value) {
        var _a, _b;
        displayControls.current.controls = value;
        var displayCameraControls = (alwaysDisplayCameraControls !== null && alwaysDisplayCameraControls !== void 0 ? alwaysDisplayCameraControls : false) || value;
        var displayVideoControls = (alwaysDisplayVideoControls !== null && alwaysDisplayVideoControls !== void 0 ? alwaysDisplayVideoControls : false) || !play || value;
        var displayCameraControlsChange = displayControls.current.camera !== displayCameraControls;
        var displayVideoControlsChange = displayControls.current.video !== displayVideoControls;
        displayControls.current.camera = displayCameraControls;
        displayControls.current.video = displayVideoControls;
        (_a = cameraControlsRef === null || cameraControlsRef === void 0 ? void 0 : cameraControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDisplay(displayCameraControls);
        (_b = videoControlsRef === null || videoControlsRef === void 0 ? void 0 : videoControlsRef.current) === null || _b === void 0 ? void 0 : _b.setDisplay(displayVideoControls);
        if (topRightContainerRef.current) {
            if (infoRef.current && !displayControls.current.info) {
                if (value)
                    infoRef.current.classList.remove('player_controls-display-none');
                else
                    infoRef.current.classList.add('player_controls-display-none');
            }
            if (additionnalContainerRef.current) {
                if (value)
                    additionnalContainerRef.current.classList.remove('player_controls-display-none');
                else
                    additionnalContainerRef.current.classList.add('player_controls-display-none');
            }
            if (closeButtonRef.current) {
                if (value && displayControlsFromTouch.current)
                    closeButtonRef.current.classList.remove('player_controls-display-none');
                else
                    closeButtonRef.current.classList.add('player_controls-display-none');
            }
            if (value)
                topRightContainerRef.current.classList.remove('player_controls-display-none');
            else
                topRightContainerRef.current.classList.add('player_controls-display-none');
        }
        if (!value) {
            showInfo(false);
            displayControlsFromTouch.current = false;
        }
        if (displayCameraControlsChange)
            onDisplayCameraControls === null || onDisplayCameraControls === void 0 ? void 0 : onDisplayCameraControls(displayCameraControls);
        if (displayVideoControlsChange)
            onDisplayVideoControls === null || onDisplayVideoControls === void 0 ? void 0 : onDisplayVideoControls(displayVideoControls);
    };
    useElementHovered(ref, function (details) {
        var _a, _b;
        if (details.isHovered) {
            displayControlsFromTouch.current = false;
            setDisplayControlsValue(true);
            if (!details.mouseEvent || details.mouseEvent.buttons === 0) {
                var delay = delayAfterHideControls === undefined
                    ? PlayerControlsDefaultCommonProps.delayAfterHideControls : delayAfterHideControls;
                if (delay && delay >= 0) {
                    if (hideControlsTimeout.current)
                        clearTimeout(hideControlsTimeout.current);
                    hideControlsTimeout.current = setTimeout(function () {
                        setDisplayControlsValue(false);
                    }, delay);
                }
            }
            else if (hideControlsTimeout.current) {
                clearTimeout(hideControlsTimeout.current);
            }
        }
        else if (details.isTouching) {
            if (!displayControlsFromTouch.current) {
                (_a = details.event) === null || _a === void 0 ? void 0 : _a.stopPropagation();
                (_b = details.event) === null || _b === void 0 ? void 0 : _b.preventDefault();
            }
            displayControlsFromTouch.current = true;
            setDisplayControlsValue(true);
        }
        else if (!displayControlsFromTouch.current) {
            if (hideControlsTimeout.current)
                clearTimeout(hideControlsTimeout.current);
            hideControlsTimeout.current = setTimeout(function () {
                setDisplayControlsValue(false);
            }, 200);
        }
    }, { enableTouch: true });
    useOuterClick(supportContentInfoRef, function () {
        showInfo(false);
    });
    useEffect(function () {
        setDisplayControlsValue(false); // default
        showInfo(false); // default
    }, []);
    useImperativeHandle(forwardRef, function () { return ({
        isDisplayingCameraControls: function () { return displayControls.current.camera; },
        isDisplayingVideoControls: function () { return displayControls.current.video; },
        // Camera controls
        setDisplayCameraControls: function (value) { var _a; return (_a = cameraControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDisplay(value); },
        setScene: function (value) { var _a; return (_a = cameraControlsRef.current) === null || _a === void 0 ? void 0 : _a.setScene(value); },
        setView: function (value) { var _a; return (_a = cameraControlsRef.current) === null || _a === void 0 ? void 0 : _a.setView(value); },
        setEnableMove: function (value) { var _a; return (_a = cameraControlsRef.current) === null || _a === void 0 ? void 0 : _a.setEnableMove(value); },
        setEnableScene: function (value) { var _a; return (_a = cameraControlsRef.current) === null || _a === void 0 ? void 0 : _a.setEnableScene(value); },
        setEnableView: function (value) { var _a; return (_a = cameraControlsRef.current) === null || _a === void 0 ? void 0 : _a.setEnableView(value); },
        setEnableZoom: function (value) { var _a; return (_a = cameraControlsRef.current) === null || _a === void 0 ? void 0 : _a.setEnableZoom(value); },
        // Video controls
        setMute: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setMute(value); },
        setVolume: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setVolume(value); },
        setFullscreen: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setFullscreen(value); },
        setPlay: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setPlay(value); },
        setCurrentTime: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setCurrentTime(value); },
        setStartsAt: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setStartsAt(value); },
        setDuration: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDuration(value); },
        setDisplayVideoControls: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDisplay(value); },
        setDisplayVolume: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDisplayVolume(value); },
        setDisplayFullscreen: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDisplayFullscreen(value); },
        setDisplayPlay: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDisplayPlay(value); },
        setDisplayProgressBar: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDisplayProgressBar(value); },
        setDisplayTime: function (value) { var _a; return (_a = videoControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDisplayTime(value); },
    }); }, [play, alwaysDisplayCameraControls, alwaysDisplayVideoControls]);
    var renderCameraControls = function () { return (React.createElement("div", { className: "player_controls-camera-controls-container" },
        React.createElement(CameraControls, { ref: cameraControlsRef, className: "player_controls-camera-controls", display: displayControls.current.camera, scene: scene, view: view, onMove: onMove, onStopMove: onStopMove, onZoom: onZoom, onStopZoom: onStopZoom, onScene: onScene, onView: onView, onAssignView: onAssignView, enableMove: enableMove, enableScene: enableScene, enableView: enableView, enableZoom: enableZoom, 
            // Controls by keyboard
            enableZoomKeyboardEvent: enableZoomKeyboardEvent, enableViewKeyboardEvent: enableViewKeyboardEvent, enableAssignViewKeyboardEvent: enableAssignViewKeyboardEvent, enableSceneKeyboardEvent: enableSceneKeyboardEvent, enableMoveKeyboardEvent: enableMoveKeyboardEvent, zoomMappingKeyboardKeys: zoomMappingKeyboardKeys, viewMappingKeyboardKeys: viewMappingKeyboardKeys, assignViewMappingKeyboardKeys: assignViewMappingKeyboardKeys, sceneMappingKeyboardKeys: sceneMappingKeyboardKeys, moveMappingKeyboardKeys: moveMappingKeyboardKeys, color: color }))); };
    var renderVideoControls = function () { return (React.createElement(VideoControls, { ref: videoControlsRef, display: displayControls.current.video, fullscreen: fullscreen, mute: mute, volume: volume, play: play, currentTime: currentTime, startsAt: startsAt, duration: duration, onMute: onMute, onVolume: onVolume, onPlay: onPlay, onFullscreen: function (e) { return setFullscreen(e); }, onTime: onTime, displayPlay: enablePlay, displayFullscreen: enableFullscreen, displayVolume: enableVolume, displayProgressBar: enableVideoProgress, displayTime: displayTime, color: color })); };
    var getIconSizeAtTopRight = function () {
        var _a, _b;
        var size = 20;
        var elementWidth = (_a = elementSize.width) !== null && _a !== void 0 ? _a : 0;
        var elementHeight = (_b = elementSize.height) !== null && _b !== void 0 ? _b : 0;
        if (elementWidth <= 300 || elementHeight <= 200)
            size = 15;
        else if (elementWidth <= 450 || elementHeight <= 300)
            size = 16;
        else if (elementWidth <= 600 || elementHeight <= 400)
            size = 18;
        return size;
    };
    var renderClearControls = function () {
        var currentSupportClasses = [];
        if ((alwaysDisplayCameraControls || !enableCameraControls)
            && (alwaysDisplayVideoControls || !enableVideoControls)
            && !additionnalDetailNodes && !displayInfo)
            return null;
        if (!displayControlsFromTouch.current) {
            currentSupportClasses.push('player_controls-display-none');
        }
        var size = getIconSizeAtTopRight();
        return (React.createElement("div", { className: currentSupportClasses.join(' '), ref: closeButtonRef },
            React.createElement(TouchableImage, { src: CloseButton, alt: "close", style: { width: "".concat(size, "px"), height: "".concat(size, "px") }, className: "player_controls-close-button", imageStyle: { width: '100%', height: '100%' }, onPress: function (event) {
                    event.stopPropagation();
                    setDisplayControlsValue(false);
                } })));
    };
    var renderInfo = function () {
        var content = displayInfo;
        if (!content)
            return null;
        var currentClasses = [];
        var currentSupportClasses = ['player_controls-info-support'];
        if (!displayControls.current.controls)
            currentClasses.push('player_controls-display-none');
        if (!displayControls.current.info)
            currentSupportClasses.push('player_controls-display-none');
        var size = getIconSizeAtTopRight();
        return (React.createElement("div", { style: { position: 'relative' }, className: currentClasses.join(' '), ref: infoRef },
            React.createElement(Touchable, { style: { width: "".concat(size, "px"), height: "".concat(size, "px") }, className: "player_controls-info-button", onPress: function (event) {
                    event.stopPropagation();
                    showInfo(!displayControls.current.info);
                } },
                React.createElement(IconInfo, { height: "100%", width: "100%", style: { width: '100%', height: '100%' }, fill: "white" })),
            React.createElement("div", { style: { backgroundColor: color }, className: currentSupportClasses.join(' '), ref: supportContentInfoRef }, content)));
    };
    var renderAdditionnalDetailsNode = function () {
        if (!additionnalDetailNodes)
            return null;
        var currentClasses = ['player_controls-additionnal-detail-container'];
        if (!displayControls.current.controls)
            currentClasses.push('player_controls-display-none');
        return (React.createElement("div", { className: currentClasses.join(' '), ref: additionnalContainerRef }, additionnalDetailNodes(getIconSizeAtTopRight())));
    };
    var renderTopRightButtons = function () {
        var _a, _b, _c, _d;
        var currentClasses = ['player_controls-buttons-support', 'player_controls-display-none'];
        var horizontalMode = false;
        if (((_a = elementSize.width) !== null && _a !== void 0 ? _a : 0) >= ((_b = elementSize.height) !== null && _b !== void 0 ? _b : 0)) {
            horizontalMode = true;
        }
        var currentStyle = { backgroundColor: color };
        var elementWidth = (_c = elementSize.width) !== null && _c !== void 0 ? _c : 0;
        var elementHeight = (_d = elementSize.height) !== null && _d !== void 0 ? _d : 0;
        if (horizontalMode) {
            if (elementWidth <= 300 || elementHeight <= 200) {
                currentStyle.padding = '3px 4px';
                currentStyle.gap = '4px';
            }
            else if (elementWidth <= 450 || elementHeight <= 300) {
                currentStyle.padding = '4px 5px';
                currentStyle.gap = '6px';
            }
            else if (elementWidth <= 600 || elementHeight <= 400) {
                currentStyle.padding = '5px 6px';
                currentStyle.gap = '8px';
            }
        }
        else {
            // eslint-disable-next-line no-lonely-if
            if (elementWidth <= 300 || elementHeight <= 200) {
                currentStyle.padding = '3px 3px';
                currentStyle.gap = '3px';
            }
            else if (elementWidth <= 450 || elementHeight <= 300) {
                currentStyle.padding = '6px 4px';
                currentStyle.gap = '6px';
            }
            else if (elementWidth <= 600 || elementHeight <= 400) {
                currentStyle.padding = '8px 5px';
                currentStyle.gap = '8px';
            }
        }
        if (horizontalMode)
            currentClasses.push('player_controls-buttons-support-horizontal');
        return (React.createElement("div", { ref: topRightContainerRef, style: currentStyle, className: currentClasses.join(' ') },
            renderClearControls(),
            renderInfo(),
            renderAdditionnalDetailsNode()));
    };
    var render = function () {
        if (enabled) {
            return (React.createElement("div", { className: className, style: style, ref: ref },
                enableCameraControls ? renderCameraControls() : null,
                enableVideoControls ? renderVideoControls() : null,
                renderTopRightButtons()));
        }
        return null;
    };
    return render();
});
PlayerControls.defaultProps = __assign(__assign({}, PlayerControlsDefaultCommonProps), { enabled: DEFAULT_ENABLED, enablePlay: true, fullscreenTargetRef: undefined, className: undefined, style: undefined });
export default PlayerControls;
