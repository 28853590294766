import { useState, useEffect, useRef } from 'react';
var styleId = 'react_player_module_fullscreen';
var classId = "class_".concat(styleId);
var addStyleFullscreenIfNeeded = function () {
    var id = 'react_player_module_fullscreen';
    if (document.getElementById(id))
        return;
    var styleElement = document.createElement('style');
    styleElement.id = id;
    styleElement.textContent = "\n    .".concat(classId, " {\n      position: fixed !important;\n      z-index: 999999 !important;\n      top: 0 !important;\n      left: 0 !important;\n      width: 100% !important;\n      height: 100% !important;\n      background: black !important;\n    }\n  ");
    document.body.appendChild(styleElement);
};
export var useStateFullScreen = function (defaultValue, ref) {
    var fullscreenNativeModeRef = useRef(false);
    var parentElementRef = useRef();
    var previousElementRef = useRef();
    var _a = useState(defaultValue), state = _a[0], setState = _a[1];
    var _b = useState(defaultValue), fullscreen = _b[0], setFullscreen = _b[1];
    var enterFullscreen = function (element) {
        var target = element;
        var result = false;
        try {
            if (target.requestFullscreen) {
                target.requestFullscreen();
                result = true;
            } /* else if (target.mozRequestFullScreen) {
              target.mozRequestFullScreen();
              result = true;
            } else if (target.webkitRequestFullscreen) {
              target.webkitRequestFullscreen();
              result = true;
            } else if (target.msRequestFullscreen) {
              target.msRequestFullscreen();
              result = true;
            } */
            if (result) {
                fullscreenNativeModeRef.current = true;
            }
        }
        catch (err) {
            console.log('Impossible to enter in fullscreen');
        }
        if (!result && element.parentElement) {
            // native fullscreen doesn't work
            addStyleFullscreenIfNeeded();
            element.classList.add(classId);
            result = true;
            fullscreenNativeModeRef.current = false;
            parentElementRef.current = element.parentElement;
            previousElementRef.current = element.previousElementSibling;
            document.body.appendChild(element);
        }
        return result;
    };
    var exitFullscreen = function (element) {
        var target = element;
        if (!target)
            return false;
        var result = false;
        if (fullscreenNativeModeRef.current) {
            try {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                    result = true;
                }
            }
            catch (err) {
                console.log('Impossible to exit in fullscreen');
            }
        }
        else if (parentElementRef.current) {
            element.classList.remove(classId);
            if (previousElementRef.current)
                parentElementRef.current.insertBefore(element, previousElementRef.current.nextSibling);
            else
                parentElementRef.current.prepend(element);
            result = true;
        }
        return result;
    };
    useEffect(function () {
        var doc = document;
        var exitHandler = function () {
            if (!doc.webkitIsFullScreen && !doc.mozFullScreen && !doc.msFullscreenElement) {
                setState(false);
                setFullscreen(false);
            }
        };
        doc.addEventListener('fullscreenchange', exitHandler, false);
        doc.addEventListener('mozfullscreenchange', exitHandler, false);
        doc.addEventListener('MSFullscreenChange', exitHandler, false);
        doc.addEventListener('webkitfullscreenchange', exitHandler, false);
        return function () {
            doc.addEventListener('fullscreenchange', exitHandler, false);
            doc.addEventListener('mozfullscreenchange', exitHandler, false);
            doc.addEventListener('MSFullscreenChange', exitHandler, false);
            doc.addEventListener('webkitfullscreenchange', exitHandler, false);
        };
    }, []);
    useEffect(function () {
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            if (state !== fullscreen) {
                if (state) {
                    if (enterFullscreen(ref.current))
                        setFullscreen(true);
                }
                else if (exitFullscreen(ref.current))
                    setFullscreen(false);
            }
        }
    });
    return [fullscreen, setState];
};
export default {
    useStateFullScreen: useStateFullScreen,
};
