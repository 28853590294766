import React, { useImperativeHandle, useRef, useState } from 'react';
import { useOnPress, UseOnPressDirecion } from '../../hooks/click';
import './Touchable.css';
var defaultAdditionalStylePressed = {
    opacity: 0.5,
};
export var Touchable = React.forwardRef(function (_a, forwardRef) {
    var onPress = _a.onPress, onPressIn = _a.onPressIn, onPressOut = _a.onPressOut, additionalStylePressed = _a.additionalStylePressed, children = _a.children, className = _a.className, style = _a.style;
    var _b = useState(false), touched = _b[0], setTouched = _b[1];
    var innerRef = useRef();
    var getHTMLElement = function () { return innerRef.current; };
    useImperativeHandle(forwardRef, function () { return ({
        getHTMLElement: getHTMLElement,
    }); }, [innerRef]);
    useOnPress(innerRef, function (e, event) {
        if (e === UseOnPressDirecion.PRESS_IN) {
            setTouched(true);
            onPressIn === null || onPressIn === void 0 ? void 0 : onPressIn(event);
            onPress === null || onPress === void 0 ? void 0 : onPress(event);
        }
        else if (e === UseOnPressDirecion.PRESS_OUT) {
            setTouched(false);
            onPressOut === null || onPressOut === void 0 ? void 0 : onPressOut(event);
        }
    });
    var divStyle = {};
    if (style)
        Object.assign(divStyle, style);
    if (touched && additionalStylePressed)
        Object.assign(divStyle, additionalStylePressed);
    var classes = ['touchable-container'];
    if (className)
        classes.push(className);
    return (React.createElement("div", { draggable: false, ref: function (el) { innerRef.current = el || undefined; }, style: divStyle, className: classes.join(' ') }, children));
});
Touchable.defaultProps = {
    className: undefined,
    style: undefined,
    additionalStylePressed: defaultAdditionalStylePressed,
    onPress: undefined,
    onPressIn: undefined,
    onPressOut: undefined,
    children: undefined,
};
export default Touchable;
