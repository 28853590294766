import { __assign, __awaiter, __extends, __generator } from "tslib";
import { generateRandomId } from "../service/utils";
export var defaultSessionOptions = {};
var Session = /** @class */ (function () {
    function Session(options) {
        // STATE
        this.started = false;
        this.closed = false;
        this._options = __assign(__assign({}, defaultSessionOptions), (options !== null && options !== void 0 ? options : {}));
        this._id = generateRandomId();
        this.listeners = {};
    }
    Object.defineProperty(Session.prototype, "options", {
        get: function () { return this._options; },
        set: function (options) { this._options = options; },
        enumerable: false,
        configurable: true
    });
    ;
    ;
    Session.prototype.getId = function () {
        return this._id;
    };
    Session.prototype.setLogger = function (logger) {
        this.log = logger;
    };
    Session.prototype.isStarted = function () {
        return this.started;
    };
    Session.prototype.isClosed = function () {
        return this.closed;
    };
    // ---------------- Listener ------------------ //
    Session.prototype.addEventListener = function (event, listener) {
        if (!this.listeners[event])
            this.listeners[event] = [];
        this.listeners[event].push(listener);
    };
    Session.prototype.removeEventListener = function (event, listener) {
        if (!this.listeners[event])
            return;
        this.listeners[event] = this.listeners[event].filter(function (curr) { return curr === listener; });
    };
    Session.prototype.removeAllEventListeners = function () {
        this.listeners = {};
    };
    return Session;
}());
export { Session };
export var WebRTCSessionState;
(function (WebRTCSessionState) {
    WebRTCSessionState[WebRTCSessionState["WAITING"] = 0] = "WAITING";
    WebRTCSessionState[WebRTCSessionState["LOADING"] = 1] = "LOADING";
    WebRTCSessionState[WebRTCSessionState["RUNNING"] = 2] = "RUNNING";
    WebRTCSessionState[WebRTCSessionState["CLOSED"] = 3] = "CLOSED";
    WebRTCSessionState[WebRTCSessionState["ERROR"] = 4] = "ERROR";
    WebRTCSessionState[WebRTCSessionState["RETRYING"] = 5] = "RETRYING";
})(WebRTCSessionState || (WebRTCSessionState = {}));
export var defaultWebRTCSessionOptions = {
    enableRtcStats: true,
    rtcStatsInterval: 3000,
};
var BaseWebRTCSession = /** @class */ (function (_super) {
    __extends(BaseWebRTCSession, _super);
    function BaseWebRTCSession(options) {
        var _this = _super.call(this, __assign(__assign({}, defaultWebRTCSessionOptions), (options !== null && options !== void 0 ? options : {}))) || this;
        // STATS
        _this.runningStats = [];
        _this._state = WebRTCSessionState.WAITING;
        _this.addEventListener('close', function () {
            _this.stopAllStats();
        });
        return _this;
    }
    Object.defineProperty(BaseWebRTCSession.prototype, "state", {
        get: function () { return this._state; },
        set: function (state) {
            var _this = this;
            var _a, _b;
            (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'state : ', this.state);
            this._state = state;
            (_b = this.listeners.state) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(_this.state); });
        },
        enumerable: false,
        configurable: true
    });
    ;
    ;
    BaseWebRTCSession.getStats = function (connection, track) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!connection || !track)
                            return [2 /*return*/, undefined];
                        return [4 /*yield*/, connection.getStats(track)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BaseWebRTCSession.prototype.enableStats = function (connection, track) {
        var _this = this;
        var _a;
        if (this.runningStats.find(function (curr) { return curr.connection === connection && curr.track === track; }))
            return;
        this.runningStats.push({
            connection: connection,
            track: track,
            interval: setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
                var stats;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, BaseWebRTCSession.getStats(connection, track)];
                        case 1:
                            stats = _a.sent();
                            if (stats)
                                this.triggersStatsListeners(connection, track, stats);
                            return [2 /*return*/];
                    }
                });
            }); }, (_a = this.options.rtcStatsInterval) !== null && _a !== void 0 ? _a : defaultWebRTCSessionOptions.rtcStatsInterval),
        });
    };
    BaseWebRTCSession.prototype.stopStats = function (connection, track) {
        this.runningStats = this.runningStats.filter(function (curr) {
            if (curr.connection === connection) {
                if (!track || curr.track === track) {
                    clearInterval(curr.interval);
                    return false;
                }
            }
            return true;
        });
    };
    BaseWebRTCSession.prototype.stopAllStats = function () {
        this.runningStats.forEach(function (curr) {
            clearInterval(curr.interval);
        });
        this.runningStats = [];
    };
    // ------------------- Listeners ----------------- //
    BaseWebRTCSession.prototype.triggersInitializedListeners = function () {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'ready : ', this.state);
        (_b = this.listeners.initialized) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(); });
    };
    BaseWebRTCSession.prototype.triggersErrorListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'error', error);
        (_b = this.listeners.error) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(error); });
    };
    BaseWebRTCSession.prototype.triggersCloseListeners = function (reason) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'close');
        (_b = this.listeners.close) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(reason); });
    };
    BaseWebRTCSession.prototype.triggersStatsListeners = function (connection, track, stats) {
        var _a;
        // this.log?.('stats');
        (_a = this.listeners.stats) === null || _a === void 0 ? void 0 : _a.forEach(function (listener) { return listener(connection, track, stats); });
    };
    return BaseWebRTCSession;
}(Session));
export { BaseWebRTCSession };
