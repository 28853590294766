import { SocketActionType } from "../../constants";
// ------------------------------- //
// ------------ Device ------------ // 
// ------------------------------- //
function getDevices(payload, options) {
    return this.emitMessage(SocketActionType.ROOM_DEVICES, payload, options);
}
var roomCommands = {
    // Device
    getDevices: getDevices,
};
export default roomCommands;
