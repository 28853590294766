var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState, useEffect, useRef } from 'react';
// Hook
export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    var _a = useState({
        width: undefined,
        height: undefined,
    }), windowSize = _a[0], setWindowSize = _a[1];
    useEffect(function () {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}
export function useElementSize(ref) {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    var _a = useState(useWindowSize()), size = _a[0], setSize = _a[1];
    useEffect(function () {
        var observer;
        if (ref.current) {
            var onSize_1 = function () {
                var _a, _b, _c, _d;
                if (((_a = ref.current) === null || _a === void 0 ? void 0 : _a.clientWidth) !== size.width
                    || ((_b = ref.current) === null || _b === void 0 ? void 0 : _b.clientHeight) !== size.height) {
                    setSize({
                        width: (_c = ref.current) === null || _c === void 0 ? void 0 : _c.clientWidth,
                        height: (_d = ref.current) === null || _d === void 0 ? void 0 : _d.clientHeight,
                    });
                }
            };
            onSize_1();
            observer = new ResizeObserver(function () {
                onSize_1();
            });
            observer.observe(ref.current);
        }
        return function () { return observer === null || observer === void 0 ? void 0 : observer.disconnect(); };
    });
    return size;
}
export var useWindowUnfocused = function (callback) {
    // initialize mutable ref, which stores callback
    var callbackRef = useRef();
    // update cb on each render, so second useEffect has access to current value
    useEffect(function () {
        callbackRef.current = callback;
    });
    useEffect(function () {
        var handleWindowUnfocus = function () {
            if (callbackRef.current)
                callbackRef.current();
        };
        window.addEventListener('blur', handleWindowUnfocus);
        // cleanup this component
        return function () {
            window.removeEventListener('blur', handleWindowUnfocus);
        };
    }, []);
};
export var elementHoveredOptionsDefault = {
    enableTouch: false,
    hoverOnParentLevel: 0,
};
/**
   * Allow to detect hover on target or on parent of target.
   * @argument ref reference of target
   * @argument onChange callback trigger on every change
   * @argument opts
   */
export function useElementHovered(ref, onChange, opts) {
    var options = __assign(__assign({}, elementHoveredOptionsDefault), (opts !== null && opts !== void 0 ? opts : {}));
    var detailsRef = useRef({
        isHovered: false,
        isTouching: false,
        positions: {},
        mouseEvent: undefined,
        touchEvent: undefined,
        event: undefined,
    });
    useEffect(function () {
        var element = ref.current;
        for (var level = 0; level < options.hoverOnParentLevel; level += 1) {
            if (!element) {
                element = null;
                break;
            }
            element = element.parentElement;
        }
        if (element) {
            var getPositions_1 = function (e) { return ({
                relative: {
                    x: e === null || e === void 0 ? void 0 : e.clientX,
                    y: e === null || e === void 0 ? void 0 : e.clientY,
                },
                absolute: {
                    x: e === null || e === void 0 ? void 0 : e.pageX,
                    y: e === null || e === void 0 ? void 0 : e.pageY,
                },
            }); };
            var handleTouchStart_1 = function (e) {
                var _a;
                // console.log('[TOUCH] - Start', e.type);
                if (!options.enableTouch)
                    return;
                if (!detailsRef.current.isTouching) {
                    if ((_a = e.touches) === null || _a === void 0 ? void 0 : _a.length) {
                        var touch = e.touches[0];
                        detailsRef.current.positions = getPositions_1(touch);
                    }
                    detailsRef.current.isTouching = true;
                    detailsRef.current.mouseEvent = undefined;
                    detailsRef.current.touchEvent = e;
                    detailsRef.current.event = e;
                    onChange === null || onChange === void 0 ? void 0 : onChange(detailsRef.current);
                }
            };
            var handleTouchEnd_1 = function (e) {
                // console.log('[TOUCH] - End', e.type);
                if (!options.enableTouch)
                    return;
                if (detailsRef.current.isTouching) {
                    e.preventDefault();
                    setTimeout(function () {
                        detailsRef.current.mouseEvent = undefined;
                        detailsRef.current.isTouching = false;
                        detailsRef.current.touchEvent = e;
                        detailsRef.current.event = e;
                        onChange === null || onChange === void 0 ? void 0 : onChange(detailsRef.current);
                    }, 100); // hack to have touchEnd after click event
                }
            };
            element.addEventListener('touchstart', handleTouchStart_1, { passive: false });
            element.addEventListener('touchend', handleTouchEnd_1, { passive: true });
            var handleMouseEnter_1 = function (e) {
                var _a;
                // console.log('[MOUSE] - Enter', e.type, e);
                if ((_a = e === null || e === void 0 ? void 0 : e.sourceCapabilities) === null || _a === void 0 ? void 0 : _a.firesTouchEvents)
                    return;
                if (e === null || e === void 0 ? void 0 : e.webkitForce)
                    return; // IOS
                detailsRef.current.isHovered = true;
                detailsRef.current.positions = getPositions_1(e);
                detailsRef.current.mouseEvent = e;
                detailsRef.current.touchEvent = undefined;
                detailsRef.current.event = e;
                onChange === null || onChange === void 0 ? void 0 : onChange(detailsRef.current);
            };
            var handleMouseMove_1 = function (e) {
                var _a;
                // console.log('[MOUSE] - Move', e.type, e);
                if ((_a = e === null || e === void 0 ? void 0 : e.sourceCapabilities) === null || _a === void 0 ? void 0 : _a.firesTouchEvents)
                    return;
                if (e === null || e === void 0 ? void 0 : e.webkitForce)
                    return; // IOS
                detailsRef.current.positions = getPositions_1(e);
                detailsRef.current.mouseEvent = e;
                detailsRef.current.touchEvent = undefined;
                detailsRef.current.event = e;
                onChange === null || onChange === void 0 ? void 0 : onChange(detailsRef.current);
            };
            var handleMouseExit_1 = function (e) {
                var _a;
                // console.log('[MOUSE] - Exit', e.type, e);
                if ((_a = e === null || e === void 0 ? void 0 : e.sourceCapabilities) === null || _a === void 0 ? void 0 : _a.firesTouchEvents)
                    return;
                if (e === null || e === void 0 ? void 0 : e.webkitForce)
                    return; // IOS
                detailsRef.current.isHovered = false;
                detailsRef.current.positions = getPositions_1(e);
                detailsRef.current.mouseEvent = e;
                detailsRef.current.touchEvent = undefined;
                detailsRef.current.event = e;
                onChange === null || onChange === void 0 ? void 0 : onChange(detailsRef.current);
            };
            var handleMouseDown_1 = function (e) {
                var _a;
                // console.log('[MOUSE] - Down', e.type, e);
                if ((_a = e === null || e === void 0 ? void 0 : e.sourceCapabilities) === null || _a === void 0 ? void 0 : _a.firesTouchEvents)
                    return;
                if (e === null || e === void 0 ? void 0 : e.webkitForce)
                    return; // IOS
                if (e.buttons === 0)
                    return; // if no buttons return -> iOS double tap
                detailsRef.current.isHovered = true;
                detailsRef.current.positions = getPositions_1(e);
                detailsRef.current.mouseEvent = e;
                detailsRef.current.touchEvent = undefined;
                detailsRef.current.event = e;
                onChange === null || onChange === void 0 ? void 0 : onChange(detailsRef.current);
            };
            element.addEventListener('mousedown', handleMouseDown_1, { passive: true });
            element.addEventListener('mouseenter', handleMouseEnter_1, { passive: true });
            element.addEventListener('mousemove', handleMouseMove_1, { passive: true });
            element.addEventListener('mouseleave', handleMouseExit_1, { passive: true });
            return function () {
                element === null || element === void 0 ? void 0 : element.removeEventListener('mousedown', handleMouseDown_1);
                element === null || element === void 0 ? void 0 : element.removeEventListener('touchstart', handleTouchStart_1);
                element === null || element === void 0 ? void 0 : element.removeEventListener('touchend', handleTouchEnd_1);
                element === null || element === void 0 ? void 0 : element.removeEventListener('mouseenter', handleMouseEnter_1);
                element === null || element === void 0 ? void 0 : element.removeEventListener('mousemove', handleMouseMove_1);
                element === null || element === void 0 ? void 0 : element.removeEventListener('mouseleave', handleMouseExit_1);
            };
        }
        return function () { return null; };
    });
}
export var stopProgagationOptionsDefault = {
    click: true,
    touch: true,
};
export function useStopProgagation(ref, opts) {
    var options = __assign(__assign({}, stopProgagationOptionsDefault), (opts !== null && opts !== void 0 ? opts : {}));
    useEffect(function () {
        var element = ref.current;
        if (element) {
            var handleTouchStart_2 = function (e) {
                if (options.touch) {
                    e.stopPropagation();
                }
            };
            element.addEventListener('touchstart', handleTouchStart_2, { passive: false });
            var handleClick_1 = function (e) {
                if (options.click) {
                    e.stopPropagation();
                }
            };
            element.addEventListener('click', handleClick_1, { passive: true });
            return function () {
                element === null || element === void 0 ? void 0 : element.removeEventListener('touchstart', handleTouchStart_2);
                element === null || element === void 0 ? void 0 : element.removeEventListener('click', handleClick_1);
            };
        }
        return function () { return null; };
    });
}
export function useStopProgagationRef(initValue, opts) {
    var ref = useRef(initValue);
    useStopProgagation(ref, opts);
    return ref;
}
