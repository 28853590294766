export var SOCKET_RESPONSE_DEFAULT_TIMEOUT = 10 * 1000;
export var KalyzeeSessionType;
(function (KalyzeeSessionType) {
    KalyzeeSessionType["USER"] = "user";
    KalyzeeSessionType["DEVICE"] = "device";
    KalyzeeSessionType["ANTMEDIA"] = "antmedia";
    KalyzeeSessionType["OVENMEDIA"] = "ovenmedia";
})(KalyzeeSessionType || (KalyzeeSessionType = {}));
;
export var KalyzeeSessionRole;
(function (KalyzeeSessionRole) {
    KalyzeeSessionRole["MASTER"] = "master";
    KalyzeeSessionRole["SLAVE"] = "slave";
})(KalyzeeSessionRole || (KalyzeeSessionRole = {}));
export var KalyzeeSessionMode;
(function (KalyzeeSessionMode) {
    KalyzeeSessionMode["SENDONLY"] = "sendonly";
    KalyzeeSessionMode["RECVONLY"] = "recvonly";
    KalyzeeSessionMode["SENDRECV"] = "sendrecv";
})(KalyzeeSessionMode || (KalyzeeSessionMode = {}));
