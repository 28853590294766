import React, { useEffect, useState } from 'react';
import { ComponentDirection } from '../../constants/global';
import { KeyboardAction, useKeyboardTouched } from '../../hooks/keyboard';
import { Touchable } from './Touchable';
import './IconSelector.css';
export var IconSelector = function (_a) {
    var data = _a.data, index = _a.index, onIndex = _a.onIndex, display = _a.display, direction = _a.direction, className = _a.className, style = _a.style;
    var _b = useState(index), selected = _b[0], setSelected = _b[1];
    var onPress = function (i) {
        setSelected(i);
        if (onIndex)
            onIndex(i);
    };
    useEffect(function () {
        setSelected(index);
    }, [index]);
    useKeyboardTouched(function (action, key, repeat) {
        if (!data)
            return;
        for (var i = 0; i < data.length; i += 1) {
            if (data[i].keyboard) {
                var array = Array.isArray(data[i].keyboard) ? data[i].keyboard : [data[i].keyboard];
                if (array.includes(key)) {
                    if (action === KeyboardAction.DOWN) {
                        if (!repeat) {
                            onPress(i);
                        }
                    }
                }
            }
        }
    });
    var renderIcon = function (i) {
        var buttonClasses = [];
        if (direction === ComponentDirection.HORIZONTAL) {
            buttonClasses.push('icon_selector-button-horizontal');
        }
        else if (direction === ComponentDirection.VERTICAL) {
            buttonClasses.push('icon_selector-button-vertical');
        }
        return (React.createElement(Touchable, { key: "icon_selector_".concat(i), onPressIn: function () { return onPress(i); }, className: buttonClasses.join(' ') }, i === selected ? data[i].active : data[i].inactive));
    };
    var classes = ['icon_selector-container'];
    if (direction === ComponentDirection.HORIZONTAL)
        classes.push('icon_selector-horizontal');
    if (direction === ComponentDirection.VERTICAL)
        classes.push('icon_selector-vertical');
    if (!display)
        classes.push('icon_selector-hide');
    if (className)
        classes.push(className);
    var render = function () {
        var icons = [];
        for (var i = 0; i < data.length; i += 1) {
            icons.push(renderIcon(i));
        }
        return React.createElement("div", { style: style, className: classes.join(' ') }, icons);
    };
    return render();
};
IconSelector.defaultProps = {
    index: 0,
    direction: ComponentDirection.VERTICAL,
    display: true,
    className: undefined,
    style: undefined,
};
export default IconSelector;
var IconSelectorAdapter = /** @class */ (function () {
    function IconSelectorAdapter(direction, defaultId) {
        this.data = this.initData(direction);
        this.defaultId = defaultId;
    }
    IconSelectorAdapter.prototype.getIndex = function (id) {
        var data = this.data;
        var index = -1;
        if (data) {
            for (var i = 0; i < data.length; i += 1) {
                if (data[i].id === id)
                    index = i;
            }
        }
        return index;
    };
    IconSelectorAdapter.prototype.getId = function (index) {
        var data = this.data;
        var id = this.defaultId;
        if (data && index < data.length) {
            if (data[index].id !== undefined)
                id = data[index].id;
        }
        return id;
    };
    IconSelectorAdapter.prototype.getData = function () {
        return this.data;
    };
    return IconSelectorAdapter;
}());
export { IconSelectorAdapter };
