import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import Hls from 'hls.js';
import { useVideoRef } from '../../hooks/video';
import { VideoDefaultParams } from '../../constants/video';
import { usePrevious } from '../../hooks/utils';
import './Video.css';
export var Video = React.forwardRef(function (_a, forwardRef) {
    var videoRef = _a.videoRef, mute = _a.mute, volume = _a.volume, play = _a.play, currentTime = _a.currentTime, autoPlay = _a.autoPlay, onMute = _a.onMute, onVolume = _a.onVolume, onPlay = _a.onPlay, onCurrentTime = _a.onCurrentTime, onDuration = _a.onDuration, onError = _a.onError, onEnd = _a.onEnd, loop = _a.loop, controls = _a.controls, src = _a.src, srcIsHls = _a.srcIsHls, media = _a.media, onReady = _a.onReady, onHlsStats = _a.onHlsStats, children = _a.children, className = _a.className, style = _a.style;
    var hlsRef = useRef();
    var currentTimeRef = useRef(0);
    var _b = useState(), video = _b[0], setVideo = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    var _d = useState(0), reloadCounter = _d[0], setReloadCounter = _d[1];
    var _e = useState(play !== null && play !== void 0 ? play : VideoDefaultParams.play), statePlay = _e[0], setStatePlay = _e[1];
    var _f = useState(mute !== null && mute !== void 0 ? mute : VideoDefaultParams.mute), stateMute = _f[0], setStateMute = _f[1];
    var _g = useState(volume !== null && volume !== void 0 ? volume : VideoDefaultParams.volume), stateVolume = _g[0], setStateVolume = _g[1];
    var _h = useState(autoPlay !== null && autoPlay !== void 0 ? autoPlay : VideoDefaultParams.autoPlay), stateAutoplay = _h[0], setStateAutoplay = _h[1];
    var _j = useState(loop !== null && loop !== void 0 ? loop : VideoDefaultParams.loop), stateLoop = _j[0], setStateLoop = _j[1];
    var _k = useState(controls !== null && controls !== void 0 ? controls : VideoDefaultParams.controls), stateControls = _k[0], setStateControls = _k[1];
    var _l = useState(src), stateSrc = _l[0], setStateSrc = _l[1];
    var _m = useState(srcIsHls !== null && srcIsHls !== void 0 ? srcIsHls : false), stateSrcIsHls = _m[0], setStateSrcIsHls = _m[1];
    var _o = useState(media), stateMedia = _o[0], setStateMedia = _o[1];
    useEffect(function () { return setStateMute(mute !== null && mute !== void 0 ? mute : VideoDefaultParams.mute); }, [mute]);
    useEffect(function () { return setStateVolume(volume !== null && volume !== void 0 ? volume : VideoDefaultParams.volume); }, [volume]);
    useEffect(function () { return setStatePlay(play !== null && play !== void 0 ? play : VideoDefaultParams.play); }, [play]);
    useEffect(function () { currentTimeRef.current = currentTime !== null && currentTime !== void 0 ? currentTime : 0; }, [currentTime]);
    useEffect(function () { return setStateAutoplay(autoPlay !== null && autoPlay !== void 0 ? autoPlay : VideoDefaultParams.autoPlay); }, [autoPlay]);
    useEffect(function () { return setStateLoop(loop !== null && loop !== void 0 ? loop : VideoDefaultParams.loop); }, [loop]);
    useEffect(function () { return setStateControls(controls !== null && controls !== void 0 ? controls : VideoDefaultParams.controls); }, [controls]);
    useEffect(function () { return setStateSrc(src); }, [src]);
    useEffect(function () { return setStateSrcIsHls(srcIsHls !== null && srcIsHls !== void 0 ? srcIsHls : false); }, [srcIsHls]);
    useEffect(function () { return setStateMedia(media); }, [media]);
    var lastMedia = usePrevious(stateMedia);
    var ref = useVideoRef({
        mute: stateMute,
        volume: stateVolume,
        play: statePlay,
        autoPlay: stateAutoplay,
        loop: stateLoop,
        controls: stateControls,
        currentTime: currentTimeRef.current,
    }, {
        onMute: onMute,
        onVolume: onVolume,
        onPlay: onPlay,
        onCurrentTime: function (time, duration) {
            currentTimeRef.current = time;
            onCurrentTime === null || onCurrentTime === void 0 ? void 0 : onCurrentTime(time, duration);
        },
        onDuration: onDuration,
        onError: onError,
        onEnd: onEnd,
    });
    var getHTMLVideoElement = function () { return video; };
    var initMediaStreamVideo = function (el) {
        if (!stateMedia)
            return false;
        if (lastMedia && lastMedia === el.srcObject && lastMedia !== stateMedia) {
            lastMedia.getTracks().forEach(function (track) {
                track.stop();
                lastMedia.removeTrack(track);
            });
            el.srcObject = null;
        }
        el.srcObject = stateMedia;
        if (error)
            setError(null);
        return true;
    };
    var useHls = function (el) {
        if (stateSrcIsHls) {
            if (!Hls.isSupported() && el.canPlayType('application/vnd.apple.mpegurl')) {
                return false;
            }
            return true;
        }
        return false;
    };
    var initSrcVideo = function (el) {
        if (!stateSrc)
            return false;
        el.srcObject = null;
        el.src = stateSrc;
        if (error)
            setError(null);
        return true;
    };
    var cleanHlsVideo = function () {
        try {
            if (hlsRef.current) {
                hlsRef.current.detachMedia();
                hlsRef.current.destroy();
                hlsRef.current = undefined;
            }
        }
        catch (err) {
            // console.error('Clean Hls error : ', err);
        }
    };
    var initHlsVideo = function (el) {
        if (!stateSrc)
            return false;
        el.srcObject = null;
        cleanHlsVideo();
        if (!Hls.isSupported()) {
            setError('Your browser isn\'t compatible.\nPlease use a modern browser to play the video');
            return false;
        }
        var hlsConfig = {
            lowLatencyMode: true,
        };
        var newHls = new Hls(hlsConfig);
        newHls.loadSource(stateSrc);
        newHls.attachMedia(el);
        newHls.on(Hls.Events.ERROR, function (_event, data) {
            if (data.type === Hls.ErrorTypes.NETWORK_ERROR || data.fatal) {
                onError === null || onError === void 0 ? void 0 : onError();
            }
            if (data.fatal) {
                if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
                    newHls === null || newHls === void 0 ? void 0 : newHls.startLoad();
                }
                else if (data.type === Hls.ErrorTypes.MEDIA_ERROR) {
                    newHls === null || newHls === void 0 ? void 0 : newHls.recoverMediaError();
                }
            }
        });
        newHls.on(Hls.Events.LEVEL_PTS_UPDATED, function (_event, data) {
            onHlsStats === null || onHlsStats === void 0 ? void 0 : onHlsStats(data.level);
        });
        hlsRef.current = newHls;
        if (error)
            setError(null);
        return true;
    };
    useImperativeHandle(forwardRef, function () { return ({
        getHTMLVideoElement: getHTMLVideoElement,
        getHls: function () { return hlsRef.current; },
        reload: function () {
            setReloadCounter(function (previous) { return previous + 1; });
        },
        getPlay: function () { return statePlay; },
        getMute: function () { return stateMute; },
        getVolume: function () { return stateVolume; },
        getCurrentTime: function () { return currentTimeRef.current; },
        getAutoplay: function () { return stateAutoplay; },
        getLoop: function () { return stateLoop; },
        getControls: function () { return stateControls; },
        getSrc: function () { return stateSrc; },
        getMedia: function () { return stateMedia; },
        setPlay: function (value) { return setStatePlay(value); },
        setMute: function (value) { return setStateMute(value); },
        setVolume: function (value) { return setStateVolume(value); },
        setCurrentTime: function (value) {
            currentTimeRef.current = value;
            if (video)
                video.currentTime = value;
        },
        setAutoplay: function (value) { return setStateAutoplay(value); },
        setLoop: function (value) { return setStateLoop(value); },
        setControls: function (value) { return setStateControls(value); },
        setSrc: function (value, isHls) {
            setStateSrc(value);
            setStateSrcIsHls(isHls);
        },
        setMedia: function (value) { return setStateMedia(value); },
    }); });
    useEffect(function () {
        ref.current = video;
        var listenerLoadedMetadata;
        // eslint-disable-next-line react/prop-types
        if (videoRef)
            videoRef.current = video;
        if (video) {
            cleanHlsVideo();
            var success = false;
            if (stateMedia) {
                success = initMediaStreamVideo(video);
            }
            else if (stateSrc) {
                if (!useHls(video)) {
                    success = initSrcVideo(video);
                }
                else {
                    success = initHlsVideo(video);
                }
            }
            if (success) {
                listenerLoadedMetadata = function () {
                    onReady === null || onReady === void 0 ? void 0 : onReady(true, video);
                };
                video.addEventListener('loadedmetadata', listenerLoadedMetadata);
            }
        }
        return function () {
            if (listenerLoadedMetadata)
                video === null || video === void 0 ? void 0 : video.removeEventListener('loadedmetadata', listenerLoadedMetadata);
        };
    }, [video, stateMedia, stateSrc, stateSrcIsHls, reloadCounter]);
    var render = function () {
        if (error) {
            return (React.createElement("div", { style: style, className: "video-error" }, error));
        }
        return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        React.createElement("video", { className: className, style: style, muted: stateMute, autoPlay: stateAutoplay && statePlay, loop: stateLoop, controls: stateControls, ref: function (el) {
                setVideo(el);
            } }, children));
    };
    return render();
});
Video.defaultProps = {
    mute: VideoDefaultParams.mute,
    volume: VideoDefaultParams.volume,
    play: VideoDefaultParams.play,
    autoPlay: VideoDefaultParams.autoPlay,
    loop: VideoDefaultParams.loop,
    currentTime: VideoDefaultParams.currentTime,
    controls: VideoDefaultParams.controls,
    onMute: undefined,
    onVolume: undefined,
    onPlay: undefined,
    onError: undefined,
    onEnd: undefined,
    src: undefined,
    srcIsHls: false,
    media: undefined,
    onReady: undefined,
    children: undefined,
    className: undefined,
    style: undefined,
};
export default Video;
