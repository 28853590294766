var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var Logger = /** @class */ (function () {
    function Logger(enabled) {
        if (enabled === void 0) { enabled = true; }
        this.enabled = enabled;
    }
    Logger.prototype.getHeader = function (withMs) {
        if (withMs === void 0) { withMs = true; }
        var now = new Date();
        var header = '[' + now.toLocaleTimeString();
        if (withMs) {
            var ms = '000' + now.getMilliseconds();
            header += '.' + ms.substring(ms.length - 3);
        }
        header += ']';
        return header;
    };
    Logger.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!this.enabled)
            return;
        console.log.apply(console, __spreadArray([this.getHeader()], args, false));
    };
    return Logger;
}());
export { Logger };
