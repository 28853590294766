import { __assign, __awaiter, __extends, __generator } from "tslib";
import { OvenMediaSocketSessionAction, OvenMediaSocketSessionNotificationType, } from "./socket";
import { defaultOvenMediaWebRTCSessionOptions, OvenMediaWebRTCSession } from "./webrtc";
import { WebRTCSessionState } from "../session/session";
import { OVENMEDIA_WEBRTC_RETRY_PLAYING_TIMEOUT } from "./constant";
;
export var defaultOvenMediaReceiverWebRTCSessionOptions = __assign(__assign({}, defaultOvenMediaWebRTCSessionOptions), { autoRetry: true, retryAfter: OVENMEDIA_WEBRTC_RETRY_PLAYING_TIMEOUT });
var OvenMediaReceiverWebRTCSession = /** @class */ (function (_super) {
    __extends(OvenMediaReceiverWebRTCSession, _super);
    function OvenMediaReceiverWebRTCSession(options) {
        var _this = _super.call(this, __assign(__assign({}, defaultOvenMediaReceiverWebRTCSessionOptions), (options !== null && options !== void 0 ? options : {}))) || this;
        _this._isPlaying = false;
        _this.init();
        return _this;
    }
    Object.defineProperty(OvenMediaReceiverWebRTCSession.prototype, "isPlaying", {
        get: function () { return this._isPlaying; },
        enumerable: false,
        configurable: true
    });
    ;
    Object.defineProperty(OvenMediaReceiverWebRTCSession.prototype, "currentRedition", {
        get: function () { return this._currentRedition; },
        enumerable: false,
        configurable: true
    });
    ;
    Object.defineProperty(OvenMediaReceiverWebRTCSession.prototype, "playlist", {
        get: function () { return this._playlist; },
        enumerable: false,
        configurable: true
    });
    ;
    OvenMediaReceiverWebRTCSession.prototype.init = function () {
        var _this = this;
        this.onStream = function (stream) {
            _this.triggersStreamListeners(stream);
        };
        this.socketSession.onMessage = function (message) {
            if (message.command === OvenMediaSocketSessionAction.NOTIFICATION) {
                _this.onReceivedNotification(message);
            }
        };
        this.addEventListener('close', function () {
            _this._isPlaying = false;
            _this.clearRetryTimeout();
            if (_this.options.autoRetry) {
                _this.state = WebRTCSessionState.RETRYING;
                _this.setRetryTimeout();
            }
        });
    };
    // ---------------- TIMEOUTS / INTERVALS -------------- //
    OvenMediaReceiverWebRTCSession.prototype.clearRetryTimeout = function () {
        if (this.retryTimeout)
            clearTimeout(this.retryTimeout);
        this.retryTimeout = undefined;
    };
    ;
    OvenMediaReceiverWebRTCSession.prototype.setRetryTimeout = function () {
        var _this = this;
        var _a, _b;
        this.clearRetryTimeout();
        this.retryTimeout = setTimeout(function () {
            _this.clearRetryTimeout();
            if (_this.currentSrc)
                _this.start(_this.currentSrc);
        }, (_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.retryAfter) !== null && _b !== void 0 ? _b : defaultOvenMediaReceiverWebRTCSessionOptions.retryAfter);
    };
    ;
    // ---------------- ACTIONS -------------- //
    // Override but no changes for the moment
    OvenMediaReceiverWebRTCSession.prototype.start = function (src) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.currentSrc = src;
                        this.clearRetryTimeout();
                        return [4 /*yield*/, _super.prototype.startSession.call(this, src)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OvenMediaReceiverWebRTCSession.prototype.destroy = function (reason) {
        this.close(reason);
        this.clearRetryTimeout();
        this.state = WebRTCSessionState.CLOSED;
        this.removeAllEventListeners();
    };
    // ------------------- Listeners ----------------- //
    OvenMediaReceiverWebRTCSession.prototype.onReceivedNotification = function (notification) {
        var _a, _b;
        this.triggersNotificationListeners(notification);
        if (notification.type === OvenMediaSocketSessionNotificationType.PLAYLIST) {
            if (!notification.message)
                return;
            this._playlist = notification.message;
            this.triggersPlaylistListeners(this._playlist);
        }
        else if (notification.type === OvenMediaSocketSessionNotificationType.RENDITION_CHANGED) {
            this._isPlaying = true;
            if (!notification.message)
                return;
            if (!((_b = (_a = this._playlist) === null || _a === void 0 ? void 0 : _a.renditions) === null || _b === void 0 ? void 0 : _b.length))
                return;
            var redition = this._playlist.renditions.find(function (curr) { return curr.name === notification.message.rendition_name; });
            if (!redition)
                return;
            this._currentRedition = redition;
            this.triggersRenditionListeners(redition);
        }
    };
    OvenMediaReceiverWebRTCSession.prototype.triggersStreamListeners = function (stream) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'streamChange : ', stream);
        (_b = this.listeners.stream) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(stream); });
    };
    OvenMediaReceiverWebRTCSession.prototype.triggersNotificationListeners = function (notification) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'notification : ', this.state);
        (_b = this.listeners.notification) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(notification); });
    };
    OvenMediaReceiverWebRTCSession.prototype.triggersPlaylistListeners = function (playlist) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'playlist : ', this.state);
        (_b = this.listeners.playlist) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(playlist); });
    };
    OvenMediaReceiverWebRTCSession.prototype.triggersRenditionListeners = function (rendition) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'rendition : ', this.state);
        (_b = this.listeners.rendition) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(rendition); });
    };
    return OvenMediaReceiverWebRTCSession;
}(OvenMediaWebRTCSession));
export { OvenMediaReceiverWebRTCSession };
