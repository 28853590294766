import { useEffect, useRef } from 'react';
export var KeyboardAction;
(function (KeyboardAction) {
    KeyboardAction[KeyboardAction["UP"] = 0] = "UP";
    KeyboardAction[KeyboardAction["DOWN"] = 1] = "DOWN";
})(KeyboardAction || (KeyboardAction = {}));
export var useKeyboardTouched = function (callback) {
    // initialize mutable ref, which stores callback
    var callbackRef = useRef();
    // update cb on each render, so second useEffect has access to current value
    useEffect(function () {
        callbackRef.current = callback;
    });
    useEffect(function () {
        var handleKeyAction = function (action) { return (function (event) {
            var key = event.key, repeat = event.repeat;
            if (callbackRef.current)
                callbackRef.current(action, key, repeat, event);
        }); };
        var handleKeyDown = handleKeyAction(KeyboardAction.DOWN);
        var handleKeyUp = handleKeyAction(KeyboardAction.UP);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        // cleanup this component
        return function () {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);
};
