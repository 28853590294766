import { __assign, __extends, __read, __spreadArray } from "tslib";
import { WebRTCSessionState } from "../session/session";
import { ANTMEDIA_WEBRTC_FPS_MIN, ANTMEDIA_WEBRTC_FPS_TARGET, ANTMEDIA_WEBRTC_FPS_TOLERANCE, ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_GOOD, ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_MEDIUM, ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_VERY_GOOD, ANTMEDIA_WEBRTC_PACKET_LOST_PERCENTAGE_DOWNGRADE, ANTMEDIA_WEBRTC_PACKET_LOST_PERCENTAGE_UPGRADE, ANTMEDIA_WEBRTC_PLAY_TIMEOUT, ANTMEDIA_WEBRTC_RETRY_PLAYING_TIMEOUT, AntmediaWebRTCNetworkLevel, AntmediaWebRTCTimeoutType, ANTMEDIA_WEBRTC_RTC_STATS_INTERVAL, } from "./constant";
import { AntmediaWebRTCSession, defaultAntmediaWebRTCSessionOptions, } from "./webrtc";
;
export var defaultAntmediaReceiverWebRTCSessionOptions = __assign(__assign({}, defaultAntmediaWebRTCSessionOptions), { rtcStatsInterval: ANTMEDIA_WEBRTC_RTC_STATS_INTERVAL, autoplay: true, autoRetry: true, retryAfter: ANTMEDIA_WEBRTC_RETRY_PLAYING_TIMEOUT, customAdaptiveBitratesStreaming: true });
export var REPORTS_SAMPLE_SIZE = 50;
var AntmediaReceiverWebRTCSession = /** @class */ (function (_super) {
    __extends(AntmediaReceiverWebRTCSession, _super);
    function AntmediaReceiverWebRTCSession(config) {
        var _this = _super.call(this, __assign(__assign({}, defaultAntmediaReceiverWebRTCSessionOptions), (config !== null && config !== void 0 ? config : {}))) || this;
        _this.resolutions = [];
        _this.currentResolutionIndex = -1;
        _this.updatingResolution = false;
        _this.networkLevel = AntmediaWebRTCNetworkLevel.UNKNOWN;
        _this.reports = [];
        _this._isPlaying = false;
        _this.init();
        return _this;
    }
    Object.defineProperty(AntmediaReceiverWebRTCSession.prototype, "isPlaying", {
        get: function () { return this._isPlaying; },
        enumerable: false,
        configurable: true
    });
    ;
    AntmediaReceiverWebRTCSession.prototype.init = function () {
        var _this = this;
        this.onTrack = function (event) {
            var _a;
            if (!(event === null || event === void 0 ? void 0 : event.stream) || (event === null || event === void 0 ? void 0 : event.streamId) !== _this.streamId)
                return;
            var rtcConnection = _this.getRTCPeerConnection();
            if (event.stream && rtcConnection && _this.options.enableRtcStats) {
                var tracks = event.stream.getTracks();
                tracks.forEach(function (track) { return _this.enableStats(rtcConnection, track); });
            }
            (_a = _this.listeners.stream) === null || _a === void 0 ? void 0 : _a.forEach(function (curr) { return curr(event.stream); });
        };
        this.addEventListener('streamExist', function (exist, description) {
            if (!exist) {
                _this.close();
                return;
            }
            if (_this.options.autoplay)
                _this.play();
            if (description) {
                _this.resolutions = __spreadArray([], __read(description.streamInfo), false).sort(function (a, b) { return (a.streamHeight * a.streamWidth - b.streamHeight * b.streamWidth); });
            }
        });
        this.addEventListener('play', function (playing) {
            _this._isPlaying = playing;
            if (playing) {
                _this.clearPlayTimeout();
            }
            else {
                _this.close();
            }
        });
        this.addEventListener('close', function () {
            _this._isPlaying = false;
            _this.clearPlayTimeout();
            _this.clearRetryTimeout();
            if (_this.options.autoRetry) {
                _this.state = WebRTCSessionState.RETRYING;
                _this.setRetryTimeout();
            }
        });
        this.addEventListener('stats', function (connection, track, stats) {
            if (!stats)
                return;
            stats.forEach(function (report) {
                if (report.type === 'inbound-rtp' && (track === null || track === void 0 ? void 0 : track.kind) === 'video') {
                    if (!_this.lastStatReport) {
                        _this.lastStatReport = report;
                        return;
                    }
                    _this.reports.push(report);
                    if (_this.reports.length > REPORTS_SAMPLE_SIZE)
                        _this.reports.splice(0, 1);
                    _this.processStats(report);
                }
            });
        });
        this.addEventListener('bitrate', function (bitrate) {
            var resolutionChanged = false;
            _this.resolutions.forEach(function (resolution, index) {
                if (resolution.audioBitrate === bitrate.audioBitrate && resolution.videoBitrate === bitrate.videoBitrate) {
                    if (_this.currentResolutionIndex !== index) {
                        resolutionChanged = true;
                    }
                    _this.currentResolutionIndex = index;
                }
            });
            if (resolutionChanged) {
                _this.cleanReports();
                _this.triggersResolutionListeners(_this.resolutions[_this.currentResolutionIndex]);
            }
        });
    };
    AntmediaReceiverWebRTCSession.prototype.play = function (withTimeout, force) {
        var _a;
        if (withTimeout === void 0) { withTimeout = true; }
        if (force === void 0) { force = false; }
        if (!force && this.playTimeout)
            return;
        if (withTimeout) {
            this.setPlayTimeout();
        }
        if (this.state !== WebRTCSessionState.RUNNING || !this.webRTCAdaptor || !this.streamId)
            return;
        (_a = this.webRTCAdaptor) === null || _a === void 0 ? void 0 : _a.play(this.streamId, '', '');
    };
    // ---------------- TIMEOUTS / INTERVALS -------------- //
    AntmediaReceiverWebRTCSession.prototype.clearPlayTimeout = function () {
        if (this.playTimeout)
            clearTimeout(this.playTimeout);
        this.playTimeout = undefined;
    };
    ;
    AntmediaReceiverWebRTCSession.prototype.setPlayTimeout = function () {
        var _this = this;
        this.clearPlayTimeout();
        this.playTimeout = setTimeout(function () {
            _this.clearPlayTimeout();
            _this.triggersTimeoutListeners(AntmediaWebRTCTimeoutType.PLAY);
            _this.close();
        }, ANTMEDIA_WEBRTC_PLAY_TIMEOUT);
    };
    ;
    AntmediaReceiverWebRTCSession.prototype.clearRetryTimeout = function () {
        if (this.retryTimeout)
            clearTimeout(this.retryTimeout);
        this.retryTimeout = undefined;
    };
    ;
    AntmediaReceiverWebRTCSession.prototype.setRetryTimeout = function () {
        var _this = this;
        var _a, _b;
        this.clearRetryTimeout();
        this.retryTimeout = setTimeout(function () {
            _this.clearRetryTimeout();
            if (_this.currentSrc)
                _this.start(_this.currentSrc);
        }, (_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.retryAfter) !== null && _b !== void 0 ? _b : defaultAntmediaReceiverWebRTCSessionOptions.retryAfter);
    };
    ;
    // ---------------- ACTIONS -------------- //
    // Override but no changes for the moment
    AntmediaReceiverWebRTCSession.prototype.start = function (src) {
        this.currentSrc = src;
        this.clearRetryTimeout();
        _super.prototype.startSession.call(this, src);
    };
    AntmediaReceiverWebRTCSession.prototype.close = function () {
        _super.prototype.close.call(this);
        this.cleanReports();
    };
    AntmediaReceiverWebRTCSession.prototype.destroy = function () {
        this.close();
        this.clearRetryTimeout();
        this.state = WebRTCSessionState.CLOSED;
        this.removeAllEventListeners();
    };
    // ---------------- Stats / Report -------------- //
    AntmediaReceiverWebRTCSession.prototype.cleanReports = function () {
        this.lastStatReport = undefined;
        this.reports = [];
    };
    AntmediaReceiverWebRTCSession.prototype.updateResolution = function (streamHeight) {
        var _a;
        if (this.updatingResolution)
            return false;
        var resolution = this.resolutions.find(function (res) { return res.streamHeight === streamHeight; });
        if (!resolution)
            return false;
        this.updatingResolution = true;
        if (!this.streamId)
            return false;
        (_a = this.webRTCAdaptor) === null || _a === void 0 ? void 0 : _a.forceStreamQuality(this.streamId, resolution.streamHeight);
        return true;
    };
    ;
    AntmediaReceiverWebRTCSession.prototype.downgradeResolution = function () {
        if (this.currentResolutionIndex <= 0)
            return false;
        this.currentResolutionIndex -= 1;
        var resolution = this.resolutions[this.currentResolutionIndex];
        return this.updateResolution(resolution.streamHeight);
    };
    ;
    AntmediaReceiverWebRTCSession.prototype.upgradeResolution = function () {
        if (this.currentResolutionIndex < 0 || this.currentResolutionIndex >= this.resolutions.length - 1)
            return false;
        this.currentResolutionIndex += 1;
        var resolution = this.resolutions[this.currentResolutionIndex];
        return this.updateResolution(resolution.streamHeight);
    };
    ;
    AntmediaReceiverWebRTCSession.prototype.processStats = function (report) {
        var fpsMin = ANTMEDIA_WEBRTC_FPS_MIN;
        var fpsTarget = ANTMEDIA_WEBRTC_FPS_TARGET;
        var fpsTolerance = ANTMEDIA_WEBRTC_FPS_TOLERANCE;
        var fpsAverage = this.reports.reduce(function (last, curr) {
            var _a;
            return (last + ((_a = curr.framesPerSecond) !== null && _a !== void 0 ? _a : 0));
        }, 0) / this.reports.length;
        var fpsVariance = Math.sqrt(this.reports.reduce(function (last, curr) {
            var _a, _b;
            return (last + (((_a = curr.framesPerSecond) !== null && _a !== void 0 ? _a : 0) - fpsAverage) * (((_b = curr.framesPerSecond) !== null && _b !== void 0 ? _b : 0) - fpsAverage));
        }, 0) / this.reports.length);
        var packetsLost = report.packetsLost - this.lastStatReport.packetsLost;
        var packetReceived = report.packetsReceived - this.lastStatReport.packetsReceived;
        var percentagePacketLost = (packetsLost + packetReceived) ? packetsLost / (packetsLost + packetReceived) : 0;
        report.currPacketLost = packetsLost;
        report.currPacketReceived = packetReceived;
        report.currPercentagePacketLost = percentagePacketLost;
        var packetsLostAverage = this.reports.reduce(function (last, curr) {
            var _a;
            return (last + ((_a = curr.currPacketLost) !== null && _a !== void 0 ? _a : 0));
        }, 0) / this.reports.length;
        var packetReceivedAverage = this.reports.reduce(function (last, curr) {
            var _a;
            return (last + ((_a = curr.currPacketReceived) !== null && _a !== void 0 ? _a : 0));
        }, 0) / this.reports.length;
        var percentagePacketLostAverage = (packetsLostAverage + packetReceivedAverage)
            ? packetsLostAverage / (packetsLostAverage + packetReceivedAverage) : 0;
        var networkLevel = AntmediaWebRTCNetworkLevel.UNKNOWN;
        if (this.currentResolutionIndex >= 0) {
            var resolution = this.resolutions[this.currentResolutionIndex];
            if (resolution.videoBitrate >= ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_VERY_GOOD) {
                networkLevel = AntmediaWebRTCNetworkLevel.VERY_GOOD;
            }
            else if (resolution.videoBitrate >= ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_GOOD) {
                networkLevel = AntmediaWebRTCNetworkLevel.GOOD;
            }
            else if (resolution.videoBitrate >= ANTMEDIA_WEBRTC_LIMIT_BITRATE_NETWORK_LEVEL_MEDIUM) {
                networkLevel = AntmediaWebRTCNetworkLevel.MEDIUM;
            }
            else {
                networkLevel = AntmediaWebRTCNetworkLevel.LOW;
            }
        }
        if (this.reports.length === REPORTS_SAMPLE_SIZE && this.options.customAdaptiveBitratesStreaming) {
            if (fpsAverage < fpsMin || percentagePacketLostAverage >= ANTMEDIA_WEBRTC_PACKET_LOST_PERCENTAGE_DOWNGRADE) {
                if (this.currentResolutionIndex === 0) {
                    // DOWNGRADE is not posssible
                    networkLevel = AntmediaWebRTCNetworkLevel.LOW;
                }
                this.downgradeResolution();
            }
            else if (fpsAverage > fpsTarget - fpsTolerance && fpsVariance < fpsTolerance
                && percentagePacketLostAverage <= ANTMEDIA_WEBRTC_PACKET_LOST_PERCENTAGE_UPGRADE) {
                if (this.currentResolutionIndex === this.resolutions.length - 1) {
                    // UPGRADE is not possible
                    networkLevel = AntmediaWebRTCNetworkLevel.VERY_GOOD;
                }
                this.upgradeResolution();
            }
            if (networkLevel !== this.networkLevel) {
                this.triggersNetworkLevelListeners(networkLevel);
            }
            this.networkLevel = networkLevel;
        }
        this.lastStatReport = report;
    };
    // ------------------- Listeners ----------------- //
    AntmediaReceiverWebRTCSession.prototype.triggersStreamListeners = function (stream) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'stream : ', this.state);
        (_b = this.listeners.stream) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(stream); });
    };
    AntmediaReceiverWebRTCSession.prototype.triggersResolutionListeners = function (info) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'resolution');
        (_b = this.listeners.resolution) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(info); });
    };
    AntmediaReceiverWebRTCSession.prototype.triggersNetworkLevelListeners = function (networkLevel) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'networkLevel');
        (_b = this.listeners.networkLevel) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(networkLevel); });
    };
    return AntmediaReceiverWebRTCSession;
}(AntmediaWebRTCSession));
export { AntmediaReceiverWebRTCSession };
