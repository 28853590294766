var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useImperativeHandle, useState } from 'react';
import IconVideo from '../../../assets/icons/icon-play.svg';
import IconResolution from '../../../assets/icons/screen-size.svg';
import { useRender } from '../../hooks/component';
import { getCurrentNpmModuleVersion } from '../../services/utils';
import './videoInfo.css';
export var VideoInfo = React.forwardRef(function (_a, forwardRef) {
    var type = _a.type, resolution = _a.resolution, getDetails = _a.getDetails, additionnalElement = _a.additionnalElement, className = _a.className, style = _a.style;
    var render = useRender();
    var _b = useState(type), stateType = _b[0], setStateType = _b[1];
    var _c = useState(resolution), stateResolution = _c[0], setStateResolution = _c[1];
    useEffect(function () { return setStateType(type); }, [type]);
    useEffect(function () { return setStateResolution(resolution); }, [resolution]);
    useImperativeHandle(forwardRef, function () { return ({
        render: render,
        setType: setStateType,
        setResolution: setStateResolution,
    }); });
    var renderNpmModuleVersion = function () {
        var version = getCurrentNpmModuleVersion();
        if (!version)
            return null;
        return (React.createElement("div", { className: "video_info-version" }, "version : ".concat(version)));
    };
    var renderType = function () {
        if (!stateType)
            return null;
        return (React.createElement("div", { className: "video_info-row" },
            React.createElement("img", { alt: "type", src: IconVideo, width: "15", height: "15" }),
            stateType));
    };
    var renderResolution = function () {
        if (!stateResolution)
            return null;
        return (React.createElement("div", { className: "video_info-row" },
            React.createElement("img", { alt: "type", src: IconResolution, width: "15", height: "15" }),
            stateResolution));
    };
    var renderAdditionnalElement = function () {
        if (!additionnalElement)
            return null;
        return (React.createElement("div", { className: "video_info-row" }, additionnalElement()));
    };
    var openDetailsInPopup = function () { return __awaiter(void 0, void 0, void 0, function () {
        var details, html, currWindow;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (getDetails === null || getDetails === void 0 ? void 0 : getDetails())];
                case 1:
                    details = _a.sent();
                    if (!details)
                        return [2 /*return*/];
                    html = "<p style=\"white-space: pre-wrap;\">".concat(details, "</p>");
                    currWindow = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                    if (!currWindow)
                        return [2 /*return*/];
                    currWindow.document.write(html);
                    currWindow.document.close();
                    currWindow.focus();
                    return [2 /*return*/];
            }
        });
    }); };
    if (!stateType && !stateResolution)
        return null;
    var classes = ['video_info-container'];
    if (className)
        classes.push(className);
    return (React.createElement("div", { style: style, className: classes.join(' '), role: "button", tabIndex: 0, onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
        }, onKeyDown: function (e) {
            if (e.key === 'i')
                openDetailsInPopup();
            e.preventDefault();
            e.stopPropagation();
        } },
        renderType(),
        renderResolution(),
        renderAdditionnalElement(),
        renderNpmModuleVersion()));
});
VideoInfo.defaultProps = {
    type: undefined,
    resolution: undefined,
    getDetails: undefined,
    additionnalElement: undefined,
    className: undefined,
    style: undefined,
};
