var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { AntmediaWebRTCNetworkLevel } from '@kalyzee/kast-webrtc-client-module';
import { Touchable } from '../util/Touchable';
import { useVideoInterface } from '../../hooks/video';
import { PlayerDefaultCommonProps } from '../../constants/player';
import { PlayerControls } from '../controls/PlayerControls';
// import { VideoAntmedia, VideoAntmediaRef, VideoAntmediaState, VideoAntmediaStats, VideoAntmediaMode } from '../video/VideoAntmedia';
import { VideoAntmedia, VideoAntmediaState, VideoAntmediaMode } from '../video/VideoAntmedia';
import { VideoInfo } from './videoInfo';
import iconNetworkLevel from '../../../assets/icons/speed-network.svg';
import IconNetworkLow from '../../../assets/icons/icon-network-low.svg';
import IconNetworkMedium from '../../../assets/icons/icon-network-medium.svg';
import IconNetworkGood from '../../../assets/icons/icon-network-good.svg';
import IconNetworkVeryGood from '../../../assets/icons/icon-network-very-good.svg';
import { IconRefresh } from '../svg/IconRefresh';
import './playerAntmedia.css';
export var PlayerAntmedia = React.forwardRef(function (_a, forwardRef) {
    var displayRefreshButton = _a.displayRefreshButton, mode = _a.mode, displayInfo = _a.displayInfo, customDisplayInfo = _a.customDisplayInfo, onMediaStream = _a.onMediaStream, 
    // Sources
    antmediaBaseUrl = _a.antmediaBaseUrl, antmediaApplication = _a.antmediaApplication, antmediaId = _a.antmediaId, adaptiveHls = _a.adaptiveHls, 
    // Stats
    onStats = _a.onStats, onNetwork = _a.onNetwork, onReady = _a.onReady, onState = _a.onState, 
    // Controls
    onDisplayVideoControls = _a.onDisplayVideoControls, onDisplayCameraControls = _a.onDisplayCameraControls, enableVideoControls = _a.enableVideoControls, enableCameraControls = _a.enableCameraControls, alwaysDisplayVideoControls = _a.alwaysDisplayVideoControls, alwaysDisplayCameraControls = _a.alwaysDisplayCameraControls, delayAfterHideControls = _a.delayAfterHideControls, enableVolume = _a.enableVolume, enableFullscreen = _a.enableFullscreen, enableMove = _a.enableMove, enableScene = _a.enableScene, enableView = _a.enableView, enableZoom = _a.enableZoom, enableVideoProgress = _a.enableVideoProgress, displayTime = _a.displayTime, 
    // Controls by keyboard
    enableZoomKeyboardEvent = _a.enableZoomKeyboardEvent, enableViewKeyboardEvent = _a.enableViewKeyboardEvent, enableAssignViewKeyboardEvent = _a.enableAssignViewKeyboardEvent, enableSceneKeyboardEvent = _a.enableSceneKeyboardEvent, enableMoveKeyboardEvent = _a.enableMoveKeyboardEvent, zoomMappingKeyboardKeys = _a.zoomMappingKeyboardKeys, viewMappingKeyboardKeys = _a.viewMappingKeyboardKeys, assignViewMappingKeyboardKeys = _a.assignViewMappingKeyboardKeys, sceneMappingKeyboardKeys = _a.sceneMappingKeyboardKeys, moveMappingKeyboardKeys = _a.moveMappingKeyboardKeys, 
    // Video
    mute = _a.mute, volume = _a.volume, loop = _a.loop, controls = _a.controls, play = _a.play, autoPlay = _a.autoPlay, live = _a.live, playAndPauseAtClick = _a.playAndPauseAtClick, displayPlay = _a.displayPlay, onMute = _a.onMute, onVolume = _a.onVolume, onPlay = _a.onPlay, onTime = _a.onTime, onError = _a.onError, onEnd = _a.onEnd, videoStyle = _a.videoStyle, 
    // Camera
    scene = _a.scene, view = _a.view, onMove = _a.onMove, onStopMove = _a.onStopMove, onZoom = _a.onZoom, onStopZoom = _a.onStopZoom, onScene = _a.onScene, onView = _a.onView, onAssignView = _a.onAssignView, color = _a.color, children = _a.children, className = _a.className, style = _a.style;
    var initialVideoState = {
        mute: mute === undefined ? PlayerDefaultCommonProps.mute : mute,
        volume: volume === undefined ? PlayerDefaultCommonProps.volume : volume,
        play: play === undefined ? PlayerDefaultCommonProps.play : play,
        currentTime: 0,
        duration: 0,
    };
    var videoControls = useVideoInterface(initialVideoState);
    var containerRef = useRef();
    var videoAntmediaRef = useRef();
    var controlsRef = useRef();
    var videoInfoRef = useRef();
    var networkLevelRef = useRef();
    var _b = useState(false), videoIsReady = _b[0], setVideoIsReady = _b[1];
    var getNetworkLevel = function () {
        var _a, _b;
        var stats = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getStats();
        return (_b = stats === null || stats === void 0 ? void 0 : stats.webrtc) === null || _b === void 0 ? void 0 : _b.networkLevel;
    };
    var getInfoType = function () {
        var _a;
        var stats = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getStats();
        if (!stats)
            return undefined;
        if (stats.state === VideoAntmediaState.HLS || stats.state === VideoAntmediaState.HLS_ADAPTIVE)
            return 'HLS';
        if (stats.state === VideoAntmediaState.WEBRTC)
            return 'WebRTC';
        if (stats.state === VideoAntmediaState.NO_STREAM)
            return 'NO STREAM';
        return undefined;
    };
    var getInfoResotion = function () {
        var _a, _b, _c;
        var stats = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getStats();
        if (!stats)
            return undefined;
        if (stats.state === VideoAntmediaState.WEBRTC) {
            if (!((_b = stats.webrtc) === null || _b === void 0 ? void 0 : _b.resolution))
                return undefined;
            return "".concat(stats.webrtc.resolution.streamWidth, "x").concat(stats.webrtc.resolution.streamHeight);
        }
        var videoElement = (_c = videoAntmediaRef.current) === null || _c === void 0 ? void 0 : _c.getHTMLVideoElement();
        var resolution;
        if ((videoElement === null || videoElement === void 0 ? void 0 : videoElement.videoWidth) && (videoElement === null || videoElement === void 0 ? void 0 : videoElement.videoHeight)) {
            resolution = "".concat(videoElement.videoWidth, "x").concat(videoElement.videoHeight);
        }
        return resolution;
    };
    var updateContentInfo = function () {
        if (!videoInfoRef.current)
            return;
        videoInfoRef.current.setType(getInfoType());
        videoInfoRef.current.setResolution(getInfoResotion());
        var currNetworkLevel = getNetworkLevel();
        if (networkLevelRef.current !== currNetworkLevel) {
            networkLevelRef.current = currNetworkLevel;
            videoInfoRef.current.render();
        }
    };
    var renderNetworkLevel = function () {
        var networkLevel = networkLevelRef.current;
        if (!networkLevel || networkLevel === AntmediaWebRTCNetworkLevel.UNKNOWN)
            return null;
        var iconSrc = IconNetworkLow;
        if (networkLevel === AntmediaWebRTCNetworkLevel.VERY_GOOD)
            iconSrc = IconNetworkVeryGood;
        if (networkLevel === AntmediaWebRTCNetworkLevel.GOOD)
            iconSrc = IconNetworkGood;
        if (networkLevel === AntmediaWebRTCNetworkLevel.MEDIUM)
            iconSrc = IconNetworkMedium;
        return (React.createElement("div", { className: "player_antmedia-network-level-container" },
            React.createElement("img", { alt: "type", src: iconNetworkLevel, width: "15", height: "15" }),
            React.createElement("img", { alt: "type", src: iconSrc, width: "15", height: "15" })));
    };
    var renderInfo = function () {
        if (!displayInfo)
            return null;
        if (customDisplayInfo)
            return customDisplayInfo;
        var getDetails = function () {
            var _a, _b;
            var stats = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getStats();
            var videoElement = (_b = videoAntmediaRef.current) === null || _b === void 0 ? void 0 : _b.getHTMLVideoElement();
            var formatAntmediaStats = function (data) {
                var _a, _b;
                if (!data)
                    return undefined;
                var curr = __assign({}, data);
                if ((_a = data.webrtc) === null || _a === void 0 ? void 0 : _a.rtcPeerConnection) {
                    curr.webrtc.rtcPeerConnectionStats = [];
                    (_b = data.webrtc) === null || _b === void 0 ? void 0 : _b.rtcPeerConnection.forEach(function (report) {
                        curr.webrtc.rtcPeerConnectionStats.push(report);
                    });
                }
                return curr;
            };
            var details = {
                player: 'antmedia',
                state: stats === null || stats === void 0 ? void 0 : stats.state,
                videoElement: {
                    width: videoElement === null || videoElement === void 0 ? void 0 : videoElement.videoWidth,
                    height: videoElement === null || videoElement === void 0 ? void 0 : videoElement.videoHeight,
                    readyState: videoElement === null || videoElement === void 0 ? void 0 : videoElement.readyState,
                    duration: videoElement === null || videoElement === void 0 ? void 0 : videoElement.duration,
                    error: videoElement === null || videoElement === void 0 ? void 0 : videoElement.error,
                    networkState: videoElement === null || videoElement === void 0 ? void 0 : videoElement.networkState,
                },
                stats: formatAntmediaStats(stats),
            };
            return JSON.stringify(details, null, 6);
        };
        return (React.createElement(VideoInfo, { ref: videoInfoRef, type: getInfoType(), resolution: getInfoResotion(), getDetails: getDetails, additionnalElement: renderNetworkLevel }));
    };
    var reconnect = function () { var _a; return (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.reconnect(); };
    useImperativeHandle(forwardRef, function () { return ({
        isReady: function () { return videoIsReady; },
        reconnect: reconnect,
        getState: function () { var _a; return (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getState(); },
        getHls: function () { var _a; return (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getHls(); },
        getStats: function () { var _a; return (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getStats(); },
        isDisplayingCameraControls: function () { var _a; return ((_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.isDisplayingCameraControls()) || false; },
        isDisplayingVideoControls: function () { var _a; return ((_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.isDisplayingVideoControls()) || false; },
        getControlsRef: function () { return controlsRef.current; },
        getVideoAntmediaRef: function () { return videoAntmediaRef.current; },
        getVideoRef: function () { var _a; return (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getVideoRef(); },
        getMediaStream: function () { var _a; return (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getMediaStream(); },
        getRTCPeerConnection: function () { var _a; return (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getRTCPeerConnection(); },
        getRTCPeerConnectionStats: function () { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
            return [2 /*return*/, (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getRTCPeerConnectionStats()];
        }); }); },
        getHTMLVideoElement: function () { var _a; return (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getHTMLVideoElement(); },
    }); }, [videoIsReady, videoAntmediaRef]);
    var onVideoIsReady = function (ready) {
        setVideoIsReady(ready);
    };
    useEffect(function () {
        updateContentInfo();
        onReady === null || onReady === void 0 ? void 0 : onReady(videoIsReady);
    }, [videoIsReady]);
    var renderControls = function () {
        var renderAdditionnalButton = function (size) {
            if (mode === VideoAntmediaMode.MIXED || mode === VideoAntmediaMode.WEBRTC) {
                if (!displayRefreshButton)
                    return null;
                return (React.createElement(Touchable, { onPress: reconnect, style: { display: 'flex' } },
                    React.createElement(IconRefresh, { height: size, width: size, fill: "white" })));
            }
            return null;
        };
        return (React.createElement(PlayerControls, { ref: controlsRef, onDisplayVideoControls: onDisplayVideoControls, onDisplayCameraControls: onDisplayCameraControls, enabled: videoIsReady, enableCameraControls: enableCameraControls, enableVideoControls: enableVideoControls, alwaysDisplayCameraControls: alwaysDisplayCameraControls, alwaysDisplayVideoControls: alwaysDisplayVideoControls, delayAfterHideControls: delayAfterHideControls, className: "player_antmedia-controls-container", fullscreenTargetRef: containerRef, mute: videoControls.getMute(), volume: videoControls.getVolume(), play: videoControls.getPlay(), onMute: function (m) {
                var _a, _b;
                videoControls.setMute(m);
                (_b = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getVideoRef()) === null || _b === void 0 ? void 0 : _b.setMute(m);
                onMute === null || onMute === void 0 ? void 0 : onMute(m);
            }, onVolume: function (v) {
                var _a, _b;
                videoControls.setVolume(v);
                (_b = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getVideoRef()) === null || _b === void 0 ? void 0 : _b.setVolume(v);
                onVolume === null || onVolume === void 0 ? void 0 : onVolume(v);
            }, onPlay: function (p) {
                var _a, _b;
                videoControls.setPlay(p);
                (_b = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getVideoRef()) === null || _b === void 0 ? void 0 : _b.setPlay(p);
                onPlay === null || onPlay === void 0 ? void 0 : onPlay(p);
            }, onTime: function (t) {
                var _a, _b;
                videoControls.setCurrentTime(t);
                (_b = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getVideoRef()) === null || _b === void 0 ? void 0 : _b.setCurrentTime(t);
                onTime === null || onTime === void 0 ? void 0 : onTime(t, 0, videoControls.getDuration());
            }, startsAt: 0, currentTime: videoControls.getCurrentTime(), duration: videoControls.getDuration(), enablePlay: !videoControls.getPlay() || displayPlay, enableFullscreen: enableFullscreen, enableVolume: enableVolume, scene: scene, view: view, onMove: onMove, onStopMove: onStopMove, onZoom: onZoom, onStopZoom: onStopZoom, onScene: onScene, onView: onView, onAssignView: onAssignView, enableMove: enableMove, enableScene: enableScene, enableView: enableView, enableZoom: enableZoom, enableVideoProgress: enableVideoProgress, displayTime: displayTime, 
            // Controls by keyboard
            enableZoomKeyboardEvent: enableZoomKeyboardEvent, enableViewKeyboardEvent: enableViewKeyboardEvent, enableAssignViewKeyboardEvent: enableAssignViewKeyboardEvent, enableSceneKeyboardEvent: enableSceneKeyboardEvent, enableMoveKeyboardEvent: enableMoveKeyboardEvent, zoomMappingKeyboardKeys: zoomMappingKeyboardKeys, viewMappingKeyboardKeys: viewMappingKeyboardKeys, assignViewMappingKeyboardKeys: assignViewMappingKeyboardKeys, sceneMappingKeyboardKeys: sceneMappingKeyboardKeys, moveMappingKeyboardKeys: moveMappingKeyboardKeys, color: color, displayInfo: renderInfo(), additionnalDetailNodes: renderAdditionnalButton }));
    };
    var renderVideo = function () { return (React.createElement(VideoAntmedia, { mode: mode, antmediaBaseUrl: antmediaBaseUrl, antmediaApplication: antmediaApplication, antmediaId: antmediaId, adaptiveHls: adaptiveHls, onMediaStream: onMediaStream, onState: function (state) {
            updateContentInfo();
            onState === null || onState === void 0 ? void 0 : onState(state);
        }, onStats: function (stats) {
            updateContentInfo();
            onStats === null || onStats === void 0 ? void 0 : onStats(stats);
        }, onNetwork: onNetwork, ref: videoAntmediaRef, style: videoStyle, className: "player_antmedia-video", controls: controls, loop: loop, autoPlay: autoPlay, mute: videoControls.getMute(), volume: videoControls.getVolume(), play: live === true || videoControls.getPlay(), onMute: function (value) {
            var _a;
            videoControls.setMute(value);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setMute(value);
        }, onVolume: function (value) {
            var _a;
            videoControls.setVolume(value);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setVolume(value);
        }, onPlay: function (value) {
            var _a, _b;
            if (!value && live) {
                setTimeout(function () {
                    var _a, _b;
                    // In live mode, don't pause the video
                    (_b = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getVideoRef()) === null || _b === void 0 ? void 0 : _b.setPlay(true);
                }, 10);
            }
            videoControls.setPlay(value);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setPlay(value);
            (_b = controlsRef.current) === null || _b === void 0 ? void 0 : _b.setDisplayPlay(!value || (displayPlay !== null && displayPlay !== void 0 ? displayPlay : false));
        }, onCurrentTime: function (time, duration) {
            var _a, _b;
            videoControls.setCurrentTime(time);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setCurrentTime(time);
            (_b = controlsRef.current) === null || _b === void 0 ? void 0 : _b.setDuration(duration);
        }, onDuration: function (time) {
            var _a;
            videoControls.setDuration(time);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setDuration(time);
        }, currentTime: videoControls.getCurrentTime(), onEnd: onEnd, onError: onError, onReady: onVideoIsReady })); };
    var renderChildren = function () {
        var result = children === null || children === void 0 ? void 0 : children();
        if (result) {
            return (React.createElement("div", { className: "player_antmedia-children-container" }, result));
        }
        return null;
    };
    var classes = ['player_antmedia-container'];
    if (className)
        classes.push(className);
    var render = function () { return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    React.createElement("div", { ref: function (el) {
            containerRef.current = el;
            if (!el)
                return;
            // onClick in div doesn't work in fullscreen.
            el.onclick = function () {
                var _a;
                if (!playAndPauseAtClick)
                    return;
                var videoRef = (_a = videoAntmediaRef.current) === null || _a === void 0 ? void 0 : _a.getVideoRef();
                if (videoRef)
                    videoRef.setPlay(!videoRef.getPlay());
            };
        }, style: style, className: classes.join(' ') },
        renderVideo(),
        renderChildren(),
        renderControls())); };
    return render();
});
PlayerAntmedia.defaultProps = __assign(__assign({ displayRefreshButton: true }, PlayerDefaultCommonProps), { 
    // Sources
    mode: VideoAntmediaMode.MIXED, children: undefined, className: undefined, style: undefined });
export default PlayerAntmedia;
