import { __awaiter, __extends, __generator } from "tslib";
import EventEmitter from 'events';
import { v4 as uuidv4 } from 'uuid';
import { convertSrcKalyzeeToWebsocketUrl } from "../service/utils";
import { SocketSession, SocketType } from "../session/socket";
import { SOCKET_RESPONSE_DEFAULT_TIMEOUT } from "./constant";
export var KalyzeeSocketSessionChannel;
(function (KalyzeeSocketSessionChannel) {
    KalyzeeSocketSessionChannel["COMMAND"] = "webrtc_message";
    KalyzeeSocketSessionChannel["WEBRTC"] = "webrtc_message";
    KalyzeeSocketSessionChannel["ERROR"] = "error";
})(KalyzeeSocketSessionChannel || (KalyzeeSocketSessionChannel = {}));
export var KalyzeeSocketSessionAction;
(function (KalyzeeSocketSessionAction) {
    // CHANNEL COMMAND
    KalyzeeSocketSessionAction["READY"] = "ready";
    KalyzeeSocketSessionAction["START"] = "start";
    KalyzeeSocketSessionAction["STOP"] = "stop";
    KalyzeeSocketSessionAction["GET_SESSION_DATA"] = "get_session_data";
    // CHANNEL WEBRTC
    KalyzeeSocketSessionAction["SDP"] = "sdp";
    KalyzeeSocketSessionAction["ICECANDIDATE"] = "iceCandidate";
})(KalyzeeSocketSessionAction || (KalyzeeSocketSessionAction = {}));
export var KalyzeeSocketSessionResponseType;
(function (KalyzeeSocketSessionResponseType) {
    KalyzeeSocketSessionResponseType["ERROR"] = "ERROR";
    KalyzeeSocketSessionResponseType["SUCCESS"] = "SUCCESS";
})(KalyzeeSocketSessionResponseType || (KalyzeeSocketSessionResponseType = {}));
var SOCKET_TYPE_VALUE = SocketType.SOCKETIO;
var KalyzeeSocketSessionEventEmitter = /** @class */ (function () {
    function KalyzeeSocketSessionEventEmitter(emitter) {
        this.emitter = emitter || new EventEmitter();
    }
    KalyzeeSocketSessionEventEmitter.prototype.emit = function (correlationId, response) {
        return this.emitter.emit(correlationId, response);
    };
    KalyzeeSocketSessionEventEmitter.prototype.add = function (correlationId, listener) {
        this.emitter.addListener(correlationId, listener);
    };
    KalyzeeSocketSessionEventEmitter.prototype.remove = function (correlationId, listener) {
        this.emitter.removeListener(correlationId, listener);
    };
    return KalyzeeSocketSessionEventEmitter;
}());
export { KalyzeeSocketSessionEventEmitter };
var KalyzeeSocketSession = /** @class */ (function (_super) {
    __extends(KalyzeeSocketSession, _super);
    function KalyzeeSocketSession() {
        var _this = _super.call(this, SOCKET_TYPE_VALUE) || this;
        _this.subscribedMessages = [];
        _this.reponseCommandEmitter = new KalyzeeSocketSessionEventEmitter();
        return _this;
    }
    KalyzeeSocketSession.prototype.start = function (src) {
        return __awaiter(this, void 0, void 0, function () {
            var formatedUrl;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, convertSrcKalyzeeToWebsocketUrl(src, function (status, error) {
                            if (status === 'unauthorized')
                                _this.triggersGenerateTokenUnauthorizedListeners(error);
                            else if (status === 'not_found')
                                _this.triggersGenerateTokenUnauthorizedListeners(error);
                            else if (status === 'error')
                                _this.triggersGenerateTokenUnauthorizedListeners(error);
                        })];
                    case 1:
                        formatedUrl = _a.sent();
                        if (!formatedUrl)
                            return [2 /*return*/, undefined];
                        return [2 /*return*/, _super.prototype.connect.call(this, formatedUrl)];
                }
            });
        });
    };
    KalyzeeSocketSession.prototype.destroy = function (reason) {
        this.emitStop(reason);
        this.close(reason);
        this.removeAllEventListeners();
    };
    // ---------------- Emit message ------------------ //
    KalyzeeSocketSession.prototype.listenResponseCommand = function (correlationId, listener) {
        this.reponseCommandEmitter.add(correlationId, listener);
    };
    KalyzeeSocketSession.prototype.unlistenResponseCommand = function (correlationId, listener) {
        this.reponseCommandEmitter.remove(correlationId, listener);
    };
    KalyzeeSocketSession.prototype.emitMessage = function (message, subscribe) {
        if (subscribe === void 0) { subscribe = false; }
        if (!message.correlation_id) {
            message.correlation_id = "webrtc_".concat(uuidv4());
        }
        if (subscribe) {
            this.subscribedMessages.push(message);
        }
        this.emit({
            event: KalyzeeSocketSessionChannel.COMMAND,
            data: [message],
        });
    };
    KalyzeeSocketSession.prototype.emitMessageAndSubscribeResponse = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var correlationId, promise;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Send command to device id and await response (timeout after delay)
                        if (!message.correlation_id) {
                            message.correlation_id = "webrtc_".concat(uuidv4());
                        }
                        correlationId = message.correlation_id;
                        promise = new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                            var listener, responseTimeout;
                            var _this = this;
                            return __generator(this, function (_a) {
                                listener = function (response) {
                                    _this.unlistenResponseCommand(correlationId, listener);
                                    if (response.type == KalyzeeSocketSessionResponseType.SUCCESS) {
                                        resolve(response);
                                    }
                                    else {
                                        reject({ timeout: false });
                                    }
                                };
                                responseTimeout = function () {
                                    _this.unlistenResponseCommand(correlationId, listener);
                                    reject({ timeout: true });
                                };
                                setTimeout(responseTimeout, SOCKET_RESPONSE_DEFAULT_TIMEOUT);
                                this.listenResponseCommand(correlationId, listener);
                                this.emitMessage(message, true);
                                return [2 /*return*/];
                            });
                        }); });
                        return [4 /*yield*/, promise];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KalyzeeSocketSession.prototype.emitResponse = function (message) {
        this.emit({
            event: KalyzeeSocketSessionChannel.COMMAND,
            data: [message],
        });
    };
    KalyzeeSocketSession.prototype.emitSuccessResponse = function (message, content) {
        this.emitResponse({
            type: KalyzeeSocketSessionResponseType.SUCCESS,
            correlation_id: message.correlation_id,
            content: content,
        });
    };
    KalyzeeSocketSession.prototype.emitErrorResponse = function (message, content) {
        this.emitResponse({
            type: KalyzeeSocketSessionResponseType.ERROR,
            correlation_id: message.correlation_id,
            content: content,
        });
    };
    KalyzeeSocketSession.prototype.getSessionData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, message, response, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.socket)
                            return [2 /*return*/, undefined];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        message = {
                            action: KalyzeeSocketSessionAction.GET_SESSION_DATA,
                            content: undefined,
                        };
                        return [4 /*yield*/, this.emitMessageAndSubscribeResponse(message)];
                    case 2:
                        response = _a.sent();
                        result = response.content;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, result];
                }
            });
        });
    };
    KalyzeeSocketSession.prototype.emitStart = function (iceServers) {
        if (!this.socket)
            return;
        var message = {
            action: KalyzeeSocketSessionAction.START,
            content: {
                servers: iceServers,
            },
        };
        this.emitMessage(message, true);
    };
    KalyzeeSocketSession.prototype.emitStop = function (reason) {
        if (!this.socket)
            return;
        var message = {
            action: KalyzeeSocketSessionAction.STOP,
            content: {
                reason: reason
            },
        };
        this.emitMessage(message);
    };
    KalyzeeSocketSession.prototype.emitSpd = function (data) {
        if (!this.socket)
            return;
        var message = {
            action: KalyzeeSocketSessionAction.SDP,
            content: data,
        };
        this.emitMessage(message);
    };
    KalyzeeSocketSession.prototype.emitIceCandidate = function (data) {
        if (!this.socket)
            return;
        var message = {
            action: KalyzeeSocketSessionAction.ICECANDIDATE,
            content: data,
        };
        this.emitMessage(message);
    };
    // ---------------- Receive message ------------------ //
    KalyzeeSocketSession.prototype.getMessageContent = function (content) {
        var result;
        if (typeof content === 'string') {
            try {
                result = JSON.parse(content);
            }
            catch (err) { }
            ;
        }
        else {
            result = content;
        }
        return result;
    };
    KalyzeeSocketSession.prototype.onReceivedMessage = function (message) {
        var _a, _b, _c, _d;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'socketOnMessage : ', message.event, ' - ', message.data);
        if (!((_b = message.data) === null || _b === void 0 ? void 0 : _b.length))
            return;
        var channel = message.event;
        var socketData = message.data[0];
        if (channel === KalyzeeSocketSessionChannel.COMMAND && socketData) {
            if (socketData.action) {
                var data = socketData;
                if (data.action === KalyzeeSocketSessionAction.READY) {
                    if (!data.content)
                        return;
                    var content = this.getMessageContent(data.content);
                    if (!content)
                        return;
                    var metadata = {
                        type: content.type,
                        mode: content.mode,
                        role: content.role,
                        pairType: content.pairType,
                        pairMode: content.pairMode,
                        pairRole: content.pairRole,
                    };
                    var sessionId = content.sessionId;
                    var iceServers = content.servers;
                    this.triggersReadyListeners(sessionId, metadata, iceServers);
                }
                else if (data.action === KalyzeeSocketSessionAction.START) {
                    if (!data.content)
                        return;
                    var content = this.getMessageContent(data.content);
                    if (!content)
                        return;
                    this.triggersStartListeners(content.servers, content.offer, content.candidates);
                    this.emitSuccessResponse(data);
                }
            }
            else {
                // RESPONSE
                var data_1 = socketData;
                if (data_1.correlation_id) {
                    var origin_1;
                    this.subscribedMessages = this.subscribedMessages.filter(function (curr) {
                        if (curr.correlation_id === data_1.correlation_id) {
                            origin_1 = curr;
                            return false;
                        }
                        return true;
                    });
                    if (!origin_1)
                        return;
                    if ((origin_1 === null || origin_1 === void 0 ? void 0 : origin_1.action) === 'start') {
                        var content = this.getMessageContent(data_1.content);
                        if (!content)
                            return;
                        if (data_1.type !== KalyzeeSocketSessionResponseType.SUCCESS) {
                            this.triggersErrorListeners('Error received for start command');
                            this.close('Socket - Error received for start command');
                            return;
                        }
                        this.triggersStartListeners(content.servers, content.offer, content.candidates);
                    }
                    this.reponseCommandEmitter.emit(data_1.correlation_id, data_1);
                }
            }
        }
        if (channel === KalyzeeSocketSessionChannel.WEBRTC && socketData) {
            if (socketData.action) {
                var data = socketData;
                if (data.action === KalyzeeSocketSessionAction.SDP) {
                    // this.sdpCB(params.content);
                    // Backend send content in string format for device
                    this.triggersSdpListeners(typeof data.content === 'string' ? JSON.parse(data.content) : data.content);
                }
                else if (data.action === KalyzeeSocketSessionAction.ICECANDIDATE) {
                    // this.iceCandidateCB(params.content);
                    // Backend send content in string format for device
                    this.triggersIceCandidateListeners(typeof data.content === 'string' ? JSON.parse(data.content) : data.content);
                }
            }
        }
        if (channel === KalyzeeSocketSessionChannel.ERROR) {
            (_c = this.log) === null || _c === void 0 ? void 0 : _c.call(this, 'socketOnMessageError : ', socketData);
        }
        if (Object.values(KalyzeeSocketSessionChannel).includes(channel)) {
            (_d = this.onMessage) === null || _d === void 0 ? void 0 : _d.call(this, channel, socketData);
        }
    };
    // ------------------- Listeners ----------------- //
    KalyzeeSocketSession.prototype.triggersReadyListeners = function (sessionId, metadata, iceServers) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'ready');
        (_b = this.listeners.ready) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(sessionId, metadata, iceServers); });
    };
    KalyzeeSocketSession.prototype.triggersStreamWaitingListeners = function () {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'streamWaiting');
        (_b = this.listeners.streamWaiting) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(); });
    };
    KalyzeeSocketSession.prototype.triggersGenerateTokenUnauthorizedListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'generateTokenUnauthorized');
        (_b = this.listeners.generateTokenUnauthorized) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(error); });
    };
    KalyzeeSocketSession.prototype.triggersGenerateTokenNotFoundListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'generateTokenNotFound');
        (_b = this.listeners.generateTokenNotFound) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(error); });
    };
    KalyzeeSocketSession.prototype.triggersGenerateTokenErrorListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'generateTokenError');
        (_b = this.listeners.generateTokenError) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(error); });
    };
    return KalyzeeSocketSession;
}(SocketSession));
export { KalyzeeSocketSession };
