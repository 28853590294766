import React from 'react';
import 'webrtc-adapter';
import { install } from 'resize-observer';
import './index.css';
install();
export var HelloWorld = function (_a) {
    var style = _a.style;
    return (React.createElement("div", { style: style }, "\uD83D\uDE03 Hello world by Kalyz\u00E9e \uD83D\uDE03"));
};
HelloWorld.defaultProps = { style: undefined };
// ------------------------
// -- COMPONENTS
// ------------------------
// Controls
export { VideoDisplayTimeControls, VideoDisplayTimeMode, } from './components/controls/video/DisplayTimeControls';
export { VideoProgressControls, } from './components/controls/video/ProgressControls';
export { VideoVolumeControls, VideoVolumeControlsMode, } from './components/controls/video/VolumeControls';
export { CameraControls, DEFAULT_MOVE_MAPPING_KEYBOAD_KEYS, } from './components/controls/CameraControls';
export { PlayerControls, } from './components/controls/PlayerControls';
export { SceneControls, DEFAULT_SCENE_MAPPING_KEYBOAD_KEYS, } from './components/controls/SceneControls';
export { VideoControls, } from './components/controls/VideoControls';
export { ViewControls, ViewControlsMode, DEFAULT_VIEW_MAPPING_KEYBOAD_KEYS, DEFAULT_ASSIGN_VIEW_MAPPING_KEYBOAD_KEYS, } from './components/controls/ViewControls';
export { ZoomControls, DEFAULT_ZOOM_MAPPING_KEYBOAD_KEYS, } from './components/controls/ZoomControls';
// Player
export { Player, PlayerMode, } from './components/player/player';
export { PlayerAntmedia, } from './components/player/playerAntmedia';
export { PlayerOvenMedia, } from './components/player/playerOvenMedia';
export { PlayerWebRTC, } from './components/player/playerWebRTC';
export { VideoInfo, } from './components/player/videoInfo';
// SVG
export { IconInfo, } from './components/svg/IconInfo';
// Utils
export { ElementOnHover, } from './components/util/ElementOnHover';
export { IconSelector, IconSelectorAdapter, } from './components/util/IconSelector';
export { Slider, SliderThumbDisplay, SliderMode, } from './components/util/Slider';
export { MessageOnHover, MessageOnHoverMode, } from './components/util/MessageOnHover';
export { Touchable, } from './components/util/Touchable';
export { TouchableImage, } from './components/util/TouchableImage';
export { TouchableImageSwitch, } from './components/util/TouchableImageSwitch';
export { TouchableSwitch, } from './components/util/TouchableSwitch';
// Video
export { Video, } from './components/video/Video';
export { VideoAntmedia, VideoAntmediaMode, VideoAntmediaState, } from './components/video/VideoAntmedia';
export { VideoKalyzeeWebRTCState, VideoKalyzeeWebRTC, } from './components/video/VideoKalyzeeWebRTC';
export { VideoOvenMediaState, VideoOvenMedia, } from './components/video/VideoOvenMedia';
export { VideoOverlay, } from './components/video/VideoOverlay';
// ------------------------
// -- CONSTANTS
// ------------------------
export { DeviceMoveDirection, DeviceScene, DeviceView, DeviceZoomDirection, } from './constants/camera';
export { ComponentDirection, } from './constants/global';
export { PlayerControlsDefaultCommonProps, PlayerDefaultCommonProps, } from './constants/player';
export { VideoState, VideoDefaultParams, } from './constants/video';
// ------------------------
// -- HOOKS
// ------------------------
export { useOuterClickRef, useOuterClick, useOnPressRef, useOnPress, UseOnPressDirecion, onPressOptionsDefault, } from './hooks/click';
export { useStateFullScreen, } from './hooks/fullscreen';
export { useKeyboardTouched, KeyboardAction, } from './hooks/keyboard';
export { usePrevious, } from './hooks/utils';
export { useVideoInterface, useVideoRef, } from './hooks/video';
export { useWindowSize, useElementSize, useWindowUnfocused, useElementHovered, useStopProgagation, useStopProgagationRef, stopProgagationOptionsDefault, } from './hooks/window';
// ------------------------
// -- Service
// ------------------------
export { generateRandomId, cssPropertiesToStyledComponent, addToPathNameUrl, isMobilePlatform, } from './services/utils';
