var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DeviceMoveDirection } from '../../constants/camera';
import Colors from '../../constants/colors';
import { Touchable } from '../util/Touchable';
import { TouchableImageSwitch } from '../util/TouchableImageSwitch';
import { KeyboardAction, useKeyboardTouched } from '../../hooks/keyboard';
import { useElementSize, useWindowUnfocused } from '../../hooks/window';
import { ZoomControls } from './ZoomControls';
import { SceneControls } from './SceneControls';
import { ViewControls, ViewControlsMode } from './ViewControls';
import Chevron from '../../../assets/icons/chevron.svg';
import ArrowUp from '../../../assets/icons/arrow-up.svg';
import ArrowDown from '../../../assets/icons/arrow-down.svg';
import ArrowLeft from '../../../assets/icons/arrow-left.svg';
import ArrowRight from '../../../assets/icons/arrow-right.svg';
import { ComponentDirection } from '../../constants/global';
import './CameraControls.css';
var DEFAULT_DISPLAY = true;
var DEFAULT_ENABLE_MOVE = true;
var DEFAULT_ENABLE_SCENE = true;
var DEFAULT_ENABLE_VIEW = true;
var DEFAULT_ENABLE_ZOOM = true;
var DEFAULT_BACKGROUND_COLOR = Colors.getGrayLevel(0.6, 0.8);
var DEFAULT_BACKGROUND_COLOR_ON_SELECT = Colors.getBlueCornflower();
var ResponsiveMode;
(function (ResponsiveMode) {
    ResponsiveMode[ResponsiveMode["XXXSMALL"] = 0] = "XXXSMALL";
    ResponsiveMode[ResponsiveMode["XXSMALL"] = 1] = "XXSMALL";
    ResponsiveMode[ResponsiveMode["XSMALL"] = 2] = "XSMALL";
    ResponsiveMode[ResponsiveMode["SMALL"] = 3] = "SMALL";
    ResponsiveMode[ResponsiveMode["MEDIUM"] = 4] = "MEDIUM";
    ResponsiveMode[ResponsiveMode["LARGE"] = 5] = "LARGE";
})(ResponsiveMode || (ResponsiveMode = {}));
var getResponsiveMode = function (width, height) {
    var result = ResponsiveMode.LARGE;
    if (height < 300 || width < 150)
        result = ResponsiveMode.XXXSMALL;
    else if (height < 400 || width < 175)
        result = ResponsiveMode.XXSMALL;
    else if (height < 450 || width < 200)
        result = ResponsiveMode.XSMALL;
    else if (height < 500 || width < 250)
        result = ResponsiveMode.SMALL;
    else if (height < 700 || width < 300)
        result = ResponsiveMode.MEDIUM;
    return result;
};
var getIconSize = function (mode) {
    var result = 40;
    if (mode === ResponsiveMode.XXXSMALL)
        result = 20;
    else if (mode === ResponsiveMode.XXSMALL)
        result = 25;
    else if (mode === ResponsiveMode.XSMALL)
        result = 30;
    else if (mode === ResponsiveMode.SMALL)
        result = 35;
    else if (mode === ResponsiveMode.MEDIUM)
        result = 40;
    return result;
};
var LIMIT_WIDTH = 180;
var LIMIT_HEIGHT = 240;
var responsiveStyles = {
    buttonMove: function (containerSize) {
        var containerWidth = containerSize.width || 0;
        var containerHeight = containerSize.height || 0;
        var responsiveMode = getResponsiveMode(containerWidth, containerHeight);
        var size = '30px';
        var padding = '7px';
        if (containerWidth < 150 || containerHeight < 100) {
            size = '15px';
            padding = '4px';
        }
        else if (containerWidth < 300 || containerHeight < 200) {
            size = '20px';
            padding = '5px';
        }
        else if (responsiveMode <= ResponsiveMode.SMALL) {
            size = '25px';
            padding = '6px';
        }
        return {
            padding: padding,
            width: size,
            height: size,
        };
    },
    zoomSelector: function (containerSize) {
        var containerWidth = containerSize.width || 0;
        var containerHeight = containerSize.height || 0;
        var style = { position: 'absolute' };
        var direction = ComponentDirection.HORIZONTAL;
        Object.assign(style, {
            bottom: '0px',
            right: '0px',
        });
        if (containerWidth < containerHeight) {
            direction = ComponentDirection.VERTICAL;
        }
        if (containerHeight < 100 || containerWidth < 150) {
            style.visibility = 'hidden';
        }
        return [style, direction];
    },
    buttonExtendView: function (containerSize) {
        var containerWidth = containerSize.width || 0;
        var containerHeight = containerSize.height || 0;
        var responsiveMode = getResponsiveMode(containerWidth, containerHeight);
        var direction = ComponentDirection.VERTICAL;
        var style = {};
        var classes = [];
        if (containerHeight < LIMIT_HEIGHT && containerWidth >= LIMIT_WIDTH) {
            direction = ComponentDirection.HORIZONTAL;
        }
        var radius = '35px';
        var width = '15px';
        var height = '30px';
        var margin = '10px';
        if (responsiveMode <= ResponsiveMode.SMALL) {
            radius = '25px';
            width = '10px';
            height = '20px';
            margin = '5px';
        }
        if (direction === ComponentDirection.HORIZONTAL) {
            classes.push('camera_controls-button-extended-horizontal');
            Object.assign(style, {
                borderTopRightRadius: radius,
                borderTopLeftRadius: radius,
                height: width,
                width: height,
                marginTop: margin,
            });
        }
        else if (direction === ComponentDirection.VERTICAL) {
            classes.push('camera_controls-button-extended-vertical');
            Object.assign(style, {
                borderTopRightRadius: radius,
                borderBottomRightRadius: radius,
                width: width,
                height: height,
                marginRight: margin,
            });
        }
        return [style, classes, direction];
    },
    supportView: function (containerSize) {
        var containerWidth = containerSize.width || 0;
        var containerHeight = containerSize.height || 0;
        var responsiveMode = getResponsiveMode(containerWidth, containerHeight);
        var style = {};
        if (responsiveMode <= ResponsiveMode.XXXSMALL)
            style.bottom = '0px';
        else
            style.top = '60%';
        if (containerHeight < LIMIT_HEIGHT && containerWidth >= LIMIT_WIDTH) {
            style.flexDirection = 'column-reverse';
        }
        if ((containerHeight < LIMIT_HEIGHT && containerWidth < LIMIT_WIDTH)
            || containerHeight < 100 || containerWidth < 100) {
            style.visibility = 'hidden';
        }
        return style;
    },
    viewSelector: function (containerSize) {
        var containerWidth = containerSize.width || 0;
        var containerHeight = containerSize.height || 0;
        var direction = ComponentDirection.VERTICAL;
        if (containerHeight < LIMIT_HEIGHT && containerWidth >= LIMIT_WIDTH) {
            direction = ComponentDirection.HORIZONTAL;
        }
        return [{}, direction];
    },
    sceneSelector: function (containerSize) {
        var containerWidth = containerSize.width || 0;
        var containerHeight = containerSize.height || 0;
        var responsiveMode = getResponsiveMode(containerWidth, containerHeight);
        var style = {};
        var direction = ComponentDirection.VERTICAL;
        if (responsiveMode <= ResponsiveMode.XXXSMALL)
            style.top = '0px';
        else
            style.bottom = '60%';
        if (containerHeight < LIMIT_HEIGHT && containerWidth >= LIMIT_WIDTH) {
            direction = ComponentDirection.HORIZONTAL;
        }
        if ((containerHeight < LIMIT_HEIGHT && containerWidth < LIMIT_WIDTH)
            || containerHeight < 100 || containerWidth < 100) {
            style.visibility = 'hidden';
        }
        return [style, direction];
    },
};
export var DEFAULT_MOVE_MAPPING_KEYBOAD_KEYS = (_a = {},
    _a[DeviceMoveDirection.RIGHT] = ['ArrowRight', '6'],
    _a[DeviceMoveDirection.LEFT] = ['ArrowLeft', '4'],
    _a[DeviceMoveDirection.UP] = ['ArrowUp', '8'],
    _a[DeviceMoveDirection.DOWN] = ['ArrowDown', '2'],
    _a);
var CONTROLS;
(function (CONTROLS) {
    CONTROLS[CONTROLS["ZOOM"] = 0] = "ZOOM";
    CONTROLS[CONTROLS["VIEW"] = 1] = "VIEW";
    CONTROLS[CONTROLS["SCENE"] = 2] = "SCENE";
})(CONTROLS || (CONTROLS = {}));
var directions = [DeviceMoveDirection.LEFT, DeviceMoveDirection.RIGHT,
    DeviceMoveDirection.UP, DeviceMoveDirection.DOWN];
export var CameraControls = React.forwardRef(function (_a, forwardRef) {
    var _b;
    var _c, _d;
    var display = _a.display, scene = _a.scene, view = _a.view, onMove = _a.onMove, onStopMove = _a.onStopMove, onZoom = _a.onZoom, onStopZoom = _a.onStopZoom, onScene = _a.onScene, onView = _a.onView, onAssignView = _a.onAssignView, enableMove = _a.enableMove, enableScene = _a.enableScene, enableView = _a.enableView, enableZoom = _a.enableZoom, 
    // Controls by keyboard
    enableZoomKeyboardEvent = _a.enableZoomKeyboardEvent, enableViewKeyboardEvent = _a.enableViewKeyboardEvent, enableAssignViewKeyboardEvent = _a.enableAssignViewKeyboardEvent, enableSceneKeyboardEvent = _a.enableSceneKeyboardEvent, enableMoveKeyboardEvent = _a.enableMoveKeyboardEvent, zoomMappingKeyboardKeys = _a.zoomMappingKeyboardKeys, viewMappingKeyboardKeys = _a.viewMappingKeyboardKeys, assignViewMappingKeyboardKeys = _a.assignViewMappingKeyboardKeys, sceneMappingKeyboardKeys = _a.sceneMappingKeyboardKeys, moveMappingKeyboardKeys = _a.moveMappingKeyboardKeys, color = _a.color, colorOnSelect = _a.colorOnSelect, className = _a.className, style = _a.style;
    var ref = useRef(null);
    var _e = useState(), keysPressed = _e[0], setKeysPressed = _e[1];
    var _f = useState(false), forceDisplayZoom = _f[0], setForceDisplayZoom = _f[1];
    var _g = useState(false), forceDisplayScene = _g[0], setForceDisplayScene = _g[1];
    var _h = useState(false), forceDisplayView = _h[0], setForceDisplayView = _h[1];
    var _j = useState(false), savingView = _j[0], setSavingView = _j[1];
    var _k = useState(undefined), currentSavingView = _k[0], setCurrentSavingView = _k[1];
    var _l = useState([]), moveDirections = _l[0], setMoveDirections = _l[1];
    var moveQueue = useRef([]);
    var _m = useState(display !== null && display !== void 0 ? display : DEFAULT_DISPLAY), stateDisplay = _m[0], setStateDisplay = _m[1];
    var _o = useState(scene), stateScene = _o[0], setStateScene = _o[1];
    var _p = useState(view), stateView = _p[0], setStateView = _p[1];
    var _q = useState(enableMove !== null && enableMove !== void 0 ? enableMove : DEFAULT_ENABLE_MOVE), stateEnableMove = _q[0], setStateEnableMove = _q[1];
    var _r = useState(enableScene !== null && enableScene !== void 0 ? enableScene : DEFAULT_ENABLE_SCENE), stateEnableScene = _r[0], setStateEnableScene = _r[1];
    var _s = useState(enableView !== null && enableView !== void 0 ? enableView : DEFAULT_ENABLE_VIEW), stateEnableView = _s[0], setStateEnableView = _s[1];
    var _t = useState(enableZoom !== null && enableZoom !== void 0 ? enableZoom : DEFAULT_ENABLE_ZOOM), stateEnableZoom = _t[0], setStateEnableZoom = _t[1];
    useEffect(function () { return setStateDisplay(display !== null && display !== void 0 ? display : DEFAULT_DISPLAY); }, [display]);
    useEffect(function () { return setStateScene(scene); }, [scene]);
    useEffect(function () { return setStateView(view); }, [view]);
    useEffect(function () { return setStateEnableMove(enableMove !== null && enableMove !== void 0 ? enableMove : DEFAULT_ENABLE_MOVE); }, [enableMove]);
    useEffect(function () { return setStateEnableScene(enableScene !== null && enableScene !== void 0 ? enableScene : DEFAULT_ENABLE_SCENE); }, [enableScene]);
    useEffect(function () { return setStateEnableView(enableView !== null && enableView !== void 0 ? enableView : DEFAULT_ENABLE_VIEW); }, [enableView]);
    useEffect(function () { return setStateEnableZoom(enableZoom !== null && enableZoom !== void 0 ? enableZoom : DEFAULT_ENABLE_ZOOM); }, [enableZoom]);
    useImperativeHandle(forwardRef, function () { return ({
        setDisplay: function (value) { return setStateDisplay(value); },
        setScene: function (value) { return setStateScene(value); },
        setView: function (value) { return setStateView(value); },
        setEnableMove: function (value) { return setStateEnableMove(value); },
        setEnableScene: function (value) { return setStateEnableScene(value); },
        setEnableView: function (value) { return setStateEnableView(value); },
        setEnableZoom: function (value) { return setStateEnableZoom(value); },
    }); }, []);
    var elementSize = useElementSize(ref);
    var enableSavingView = (elementSize.width || 0) > 200 && (elementSize.height || 0) > 180;
    var responsivityMode = getResponsiveMode((_c = elementSize.width) !== null && _c !== void 0 ? _c : 0, (_d = elementSize.height) !== null && _d !== void 0 ? _d : 0);
    var timeOuts = (_b = {},
        _b[CONTROLS.ZOOM] = undefined,
        _b[CONTROLS.VIEW] = undefined,
        _b[CONTROLS.SCENE] = undefined,
        _b);
    useEffect(function () {
        if (stateView !== currentSavingView) {
            setCurrentSavingView(undefined);
        }
    }, [stateView]);
    useEffect(function () {
        var timeout;
        if (currentSavingView !== undefined) {
            timeout = setTimeout(function () {
                setCurrentSavingView(undefined);
            }, 500);
        }
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    }, [currentSavingView]);
    var pressKey = function (key) {
        if (!keysPressed) {
            setKeysPressed([key]);
            return [key];
        }
        var newValue = __spreadArray(__spreadArray([], keysPressed, true), [key], false);
        setKeysPressed(newValue);
        return newValue;
    };
    var unpressKey = function (key) {
        if (!keysPressed)
            return null;
        var newValue = __spreadArray([], keysPressed.filter(function (k) { return k !== key; }), true);
        setKeysPressed(newValue);
        return newValue;
    };
    var move = function (direction) {
        setMoveDirections([direction]);
        onMove === null || onMove === void 0 ? void 0 : onMove(direction);
    };
    var stopMove = function () {
        setMoveDirections([]);
        onStopMove === null || onStopMove === void 0 ? void 0 : onStopMove();
    };
    var addMoveCommand = function (direction) {
        moveQueue.current.push(direction);
        move(direction);
    };
    var removeMoveCommand = function (direction) {
        if (moveQueue.current.length === 0) {
            stopMove();
            return;
        }
        var lastElement = moveQueue.current[moveQueue.current.length - 1];
        var index = moveQueue.current.lastIndexOf(direction);
        if (index >= 0) {
            moveQueue.current.splice(index, 1);
        }
        var newLastElement = moveQueue.current[moveQueue.current.length - 1];
        if (moveQueue.current.length === 0)
            stopMove();
        else if (lastElement !== newLastElement)
            move(newLastElement);
    };
    var clearMoveCommands = function () {
        moveQueue.current = [];
        stopMove();
    };
    useWindowUnfocused(function () {
        if (keysPressed) {
            setKeysPressed(null);
            clearMoveCommands();
        }
    });
    useKeyboardTouched(function (action, key, repeat, event) {
        if (!enableMoveKeyboardEvent || !moveMappingKeyboardKeys)
            return;
        directions.forEach(function (direction) {
            if (moveMappingKeyboardKeys[direction]) {
                var keys = Array.isArray(moveMappingKeyboardKeys[direction])
                    ? moveMappingKeyboardKeys[direction] : [moveMappingKeyboardKeys[direction]];
                if (keys.includes(key)) {
                    if (action === KeyboardAction.DOWN) {
                        if (!(keysPressed === null || keysPressed === void 0 ? void 0 : keysPressed.includes(key))) {
                            pressKey(key);
                            if (!repeat) {
                                addMoveCommand(direction);
                            }
                            event.preventDefault();
                        }
                    }
                    else if (action === KeyboardAction.UP) {
                        unpressKey(key);
                        removeMoveCommand(direction);
                    }
                }
            }
        });
    });
    var forceDisplay = function (control, delay) {
        if (delay === void 0) { delay = 1000; }
        // Reset display to false after
        var setter;
        if (control === CONTROLS.ZOOM)
            setter = setForceDisplayZoom;
        else if (control === CONTROLS.SCENE)
            setter = setForceDisplayScene;
        else if (control === CONTROLS.VIEW)
            setter = setForceDisplayView;
        else
            return;
        if (timeOuts[control]) {
            clearTimeout(timeOuts[control]);
            timeOuts[control] = undefined;
        }
        setter(true);
        if (delay > 0) {
            var timeOut = setTimeout(function () {
                setter(false);
            }, delay);
            timeOuts[control] = timeOut;
        }
    };
    var renderSelectors = function () {
        var responsiveView = responsiveStyles.viewSelector(elementSize);
        var responsiveButton = responsiveStyles.buttonExtendView(elementSize);
        var buttonClasses = responsiveButton[1];
        var buttonStyle = responsiveButton[0];
        Object.assign(buttonStyle, { visibility: (stateDisplay || forceDisplayView ? 'visible' : 'hidden') });
        Object.assign(buttonStyle, { backgroundColor: color });
        var viewStyle = responsiveView[0];
        Object.assign(viewStyle, { backgroundColor: color }, responsiveView[0]);
        var assignViewStyle = responsiveView[0];
        Object.assign(assignViewStyle, { backgroundColor: color }, responsiveView[0]);
        var supportStyle = responsiveStyles.supportView(elementSize);
        var buttonVisibility = {
            visibility: stateEnableView && (stateDisplay || forceDisplayView) ? 'visible' : 'hidden',
        };
        if (supportStyle.visibility === 'hidden')
            buttonVisibility.visibility = 'hidden';
        var iconSize = getIconSize(responsivityMode);
        var showSavingView = savingView || currentSavingView !== undefined;
        var renderView = function () { return (React.createElement("div", { style: supportStyle, className: "camera_controls-view-support" },
            React.createElement(ViewControls, { mode: ViewControlsMode.SELECT, display: stateEnableView && (stateDisplay || forceDisplayView), style: viewStyle, direction: responsiveView[1], view: stateView, enableKeyboardEvent: enableViewKeyboardEvent, mappingKeyboardKeys: viewMappingKeyboardKeys, iconSize: iconSize, onView: function (v) {
                    forceDisplay(CONTROLS.VIEW);
                    onView === null || onView === void 0 ? void 0 : onView(v);
                    setStateView(v);
                    setSavingView(false);
                } }),
            enableSavingView ? (React.createElement(TouchableImageSwitch, { enable: showSavingView, srcEnabled: responsiveView[1] === ComponentDirection.VERTICAL ? ArrowLeft : ArrowDown, srcDisabled: responsiveView[1] === ComponentDirection.VERTICAL
                    ? ArrowRight : ArrowUp, style: __assign(__assign({}, buttonStyle), buttonVisibility), className: buttonClasses.join(' '), imageStyle: { height: '50%' }, onEnable: function (e) { return setSavingView(e); } })) : null,
            enableSavingView ? (React.createElement(ViewControls, { mode: ViewControlsMode.ASSIGN, display: stateEnableView && showSavingView && (stateDisplay || forceDisplayView), direction: responsiveView[1], view: currentSavingView, style: assignViewStyle, enableKeyboardEvent: enableAssignViewKeyboardEvent, mappingKeyboardKeys: assignViewMappingKeyboardKeys, iconSize: iconSize, onView: function (v) {
                    forceDisplay(CONTROLS.VIEW);
                    onAssignView === null || onAssignView === void 0 ? void 0 : onAssignView(v);
                    onView === null || onView === void 0 ? void 0 : onView(v);
                    setStateView(v);
                    setCurrentSavingView(v);
                    setSavingView(false);
                } })) : null)); };
        var _a = responsiveStyles.zoomSelector(elementSize), zoomResponseStyle = _a[0], zoomDirection = _a[1];
        var zoomStyle = __assign({}, zoomResponseStyle);
        Object.assign(zoomStyle, { backgroundColor: color });
        var responsiveScene = responsiveStyles.sceneSelector(elementSize);
        var sceneStyle = {};
        Object.assign(sceneStyle, responsiveScene[0]);
        Object.assign(sceneStyle, { backgroundColor: color });
        return (
        // Set visibility & keep component alive to keep value of elements selected in component
        React.createElement("div", { className: "camera_controls-selector-container" },
            React.createElement(ZoomControls, { display: stateEnableZoom && (stateDisplay || forceDisplayZoom), style: zoomStyle, direction: zoomDirection, enableKeyboardEvent: enableZoomKeyboardEvent, mappingKeyboardKeys: zoomMappingKeyboardKeys, iconSize: iconSize, onZoom: function (dir) {
                    forceDisplay(CONTROLS.ZOOM, -1); // Without hide after delay
                    setStateView(undefined); // View changed
                    onZoom === null || onZoom === void 0 ? void 0 : onZoom(dir);
                }, onStopZoom: function () {
                    forceDisplay(CONTROLS.ZOOM);
                    onStopZoom === null || onStopZoom === void 0 ? void 0 : onStopZoom();
                } }),
            React.createElement(SceneControls, { display: stateEnableScene && (stateDisplay || forceDisplayScene), style: sceneStyle, className: "camera_controls-view", direction: responsiveScene[1], scene: stateScene, enableKeyboardEvent: enableSceneKeyboardEvent, mappingKeyboardKeys: sceneMappingKeyboardKeys, iconSize: iconSize, onScene: function (s) {
                    forceDisplay(CONTROLS.SCENE);
                    onScene === null || onScene === void 0 ? void 0 : onScene(s);
                } }),
            renderView()));
    };
    var renderMoveButtons = function () {
        var _a;
        var buttonsClasses = (_a = {},
            _a[DeviceMoveDirection.LEFT] = 'camera_controls-button-move-left',
            _a[DeviceMoveDirection.RIGHT] = 'camera_controls-button-move-right',
            _a[DeviceMoveDirection.UP] = 'camera_controls-button-move-up',
            _a[DeviceMoveDirection.DOWN] = 'camera_controls-button-move-down',
            _a);
        var views = [];
        directions.forEach(function (direction) {
            var isMoving = moveDirections.includes(direction);
            if (stateDisplay || isMoving) {
                var buttonClasses = ['camera_controls-button-move'];
                buttonClasses.push(buttonsClasses[direction]);
                var buttonStyle = {};
                Object.assign(buttonStyle, responsiveStyles.buttonMove(elementSize));
                Object.assign(buttonStyle, { backgroundColor: isMoving ? colorOnSelect : color });
                views.push(React.createElement(Touchable, { key: direction, style: buttonStyle, className: buttonClasses.join(' '), onPressIn: function () {
                        setStateView(undefined); // View changed
                        addMoveCommand(direction);
                    }, onPressOut: function () { return removeMoveCommand(direction); } },
                    React.createElement("img", { src: Chevron, style: { width: '100%', height: '100%' }, draggable: false, alt: "move" })));
            }
        });
        return views;
    };
    var classes = ['camera_controls-container'];
    if (className)
        classes.push(className);
    var render = function () { return (React.createElement("div", { className: classes.join(' '), style: style, ref: ref },
        React.createElement("div", { className: "camera_controls-content" },
            renderSelectors(),
            stateEnableMove ? renderMoveButtons() : null))); };
    return render();
});
CameraControls.defaultProps = {
    display: DEFAULT_DISPLAY,
    enableMove: DEFAULT_ENABLE_MOVE,
    enableScene: DEFAULT_ENABLE_SCENE,
    enableView: DEFAULT_ENABLE_VIEW,
    enableZoom: DEFAULT_ENABLE_ZOOM,
    color: DEFAULT_BACKGROUND_COLOR,
    colorOnSelect: DEFAULT_BACKGROUND_COLOR_ON_SELECT,
    className: undefined,
    style: undefined,
};
export default CameraControls;
