import { __assign, __awaiter, __extends, __generator } from "tslib";
import { defaultAntmediaWebRTCSessionOptions } from "../antmedia/webrtc";
import { convertSrcOvenMediaToWebsocketUrl } from "../service/utils";
import { WebRTCSession } from "../session/webrtc";
import { OvenMediaSocketSession } from "./socket";
;
export var defaultOvenMediaWebRTCSessionOptions = __assign({}, defaultAntmediaWebRTCSessionOptions);
var OvenMediaWebRTCSession = /** @class */ (function (_super) {
    __extends(OvenMediaWebRTCSession, _super);
    function OvenMediaWebRTCSession(options) {
        return _super.call(this, new OvenMediaSocketSession(), __assign(__assign({}, defaultOvenMediaWebRTCSessionOptions), (options !== null && options !== void 0 ? options : {}))) || this;
    }
    OvenMediaWebRTCSession.prototype.startSession = function (src) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.startSession.call(this, convertSrcOvenMediaToWebsocketUrl(src))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return OvenMediaWebRTCSession;
}(WebRTCSession));
export { OvenMediaWebRTCSession };
