var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DEFAULT_MOVE_MAPPING_KEYBOAD_KEYS } from '../components/controls/CameraControls';
import { DEFAULT_SCENE_MAPPING_KEYBOAD_KEYS } from '../components/controls/SceneControls';
import { VideoDisplayTimeMode } from '../components/controls/video/DisplayTimeControls';
import { DEFAULT_ASSIGN_VIEW_MAPPING_KEYBOAD_KEYS, DEFAULT_VIEW_MAPPING_KEYBOAD_KEYS } from '../components/controls/ViewControls';
import { DEFAULT_ZOOM_MAPPING_KEYBOAD_KEYS } from '../components/controls/ZoomControls';
import Colors from './colors';
export var PlayerControlsDefaultCommonProps = {
    // Controls
    onDisplayVideoControls: undefined,
    onDisplayCameraControls: undefined,
    enableVideoControls: false,
    enableCameraControls: false,
    alwaysDisplayVideoControls: false,
    alwaysDisplayCameraControls: false,
    delayAfterHideControls: 2000,
    enableVolume: true,
    enableFullscreen: true,
    enableMove: true,
    enableScene: true,
    enableView: true,
    enableZoom: true,
    enableVideoProgress: false,
    displayTime: VideoDisplayTimeMode.NONE,
    displayPlay: false,
    // Controls by keyboard
    enableZoomKeyboardEvent: true,
    enableViewKeyboardEvent: true,
    enableAssignViewKeyboardEvent: false,
    enableSceneKeyboardEvent: true,
    enableMoveKeyboardEvent: true,
    zoomMappingKeyboardKeys: DEFAULT_ZOOM_MAPPING_KEYBOAD_KEYS,
    viewMappingKeyboardKeys: DEFAULT_VIEW_MAPPING_KEYBOAD_KEYS,
    assignViewMappingKeyboardKeys: DEFAULT_ASSIGN_VIEW_MAPPING_KEYBOAD_KEYS,
    sceneMappingKeyboardKeys: DEFAULT_SCENE_MAPPING_KEYBOAD_KEYS,
    moveMappingKeyboardKeys: DEFAULT_MOVE_MAPPING_KEYBOAD_KEYS,
    // Video
    mute: true,
    volume: 1,
    play: true,
    currentTime: 0,
    onMute: undefined,
    onVolume: undefined,
    onPlay: undefined,
    onTime: undefined,
    // Camera
    scene: undefined,
    view: undefined,
    onMove: undefined,
    onStopMove: undefined,
    onZoom: undefined,
    onStopZoom: undefined,
    onScene: undefined,
    onView: undefined,
    onAssignView: undefined,
    color: Colors.getGrayLevel(0.6, 0.8),
};
export var PlayerDefaultCommonProps = __assign(__assign({}, PlayerControlsDefaultCommonProps), { displayInfo: true, customDisplayInfo: undefined, loop: true, controls: false, autoPlay: true, live: false, playAndPauseAtClick: false, videoStyle: undefined });
