import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import Colors from '../../constants/colors';
import { TouchableImageSwitch } from '../util/TouchableImageSwitch';
import PlayButton from '../../../assets/icons/icon-play.svg';
import PauseButton from '../../../assets/icons/icon-pause-controls.svg';
import FullscreenEnable from '../../../assets/icons/icon-fullscreen-enable.svg';
import FullscreenDisable from '../../../assets/icons/icon-fullscreen-disable.svg';
import { VideoVolumeControls, VideoVolumeControlsMode } from './video/VolumeControls';
import { useElementHovered, useElementSize, useStopProgagation } from '../../hooks/window';
import { VideoDisplayTimeControls, VideoDisplayTimeMode } from './video/DisplayTimeControls';
import { VideoProgressControls } from './video/ProgressControls';
import { isMobilePlatform, isTouchEnabled } from '../../services/utils';
import { TouchableSwitchTrigger } from '../util/TouchableSwitch';
import './VideoControls.css';
var VOLUME_SIZE_NEEDED = 170;
var MIN_ELEMENT_SIZE = 100;
var DEFAULT_MUTE = true;
var DEFAULT_VOLUME = 1;
var DEFAULT_PLAY = false;
var DEFAULT_FULLSCREEN = false;
var DEFAULT_DURATION = 0;
var DEFAULT_STARTS_AT = 0;
var DEFAULT_CURRENT_TIME = 0;
var DEFAULT_DISPLAY = true;
var DEFAULT_DISPLAY_VOLUME = true;
var DEFAULT_DISPLAY_FULLSCREEN = true;
var DEFAULT_DISPLAY_PLAY = true;
var DEFAULT_DISPLAY_PROGRESS_BAR = true;
var DEFAULT_DISPLAY_TIME = VideoDisplayTimeMode.NORMAL;
var DEFAULT_BACKGROUND_COLOR = Colors.getGrayLevel(0.6, 0.8);
export var VideoControls = React.forwardRef(function (_a, forwardRef) {
    var _b;
    var mute = _a.mute, volume = _a.volume, fullscreen = _a.fullscreen, play = _a.play, currentTime = _a.currentTime, startsAt = _a.startsAt, duration = _a.duration, onMute = _a.onMute, onVolume = _a.onVolume, onFullscreen = _a.onFullscreen, onPlay = _a.onPlay, onTime = _a.onTime, display = _a.display, displayVolume = _a.displayVolume, displayFullscreen = _a.displayFullscreen, displayPlay = _a.displayPlay, displayProgressBar = _a.displayProgressBar, displayTime = _a.displayTime, color = _a.color, className = _a.className, style = _a.style;
    var containerRef = useRef(null);
    var rowButtonContainerRef = useRef(null);
    var playContainerRef = useRef(null);
    var displayTimeContainerRef = useRef(null);
    var volumeContainerRef = useRef(null);
    var fullscreenContainerRef = useRef(null);
    var progressControlsRef = useRef(null);
    var displayTimeControlsRef = useRef(null);
    var focusingProgressBar = useRef({ playing: false, focusing: false });
    var _c = useState(false), volumeIsHovered = _c[0], setVolumeIsHovered = _c[1];
    var elementSize = useElementSize(containerRef);
    var parentElement = (_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.parentElement;
    useStopProgagation(containerRef);
    var _d = useState(mute !== null && mute !== void 0 ? mute : DEFAULT_MUTE), stateMute = _d[0], setStateMute = _d[1];
    var _e = useState(volume !== null && volume !== void 0 ? volume : DEFAULT_VOLUME), stateVolume = _e[0], setStateVolume = _e[1];
    var _f = useState(fullscreen !== null && fullscreen !== void 0 ? fullscreen : DEFAULT_FULLSCREEN), stateFullscreen = _f[0], setStateFullscreen = _f[1];
    var _g = useState(play !== null && play !== void 0 ? play : DEFAULT_PLAY), statePlay = _g[0], setStatePlay = _g[1];
    var _h = useState(display !== null && display !== void 0 ? display : DEFAULT_DISPLAY), stateDisplay = _h[0], setStateDisplay = _h[1];
    var _j = useState(displayVolume !== null && displayVolume !== void 0 ? displayVolume : DEFAULT_DISPLAY_VOLUME), stateDisplayVolume = _j[0], setStateDisplayVolume = _j[1];
    var _k = useState(displayFullscreen !== null && displayFullscreen !== void 0 ? displayFullscreen : DEFAULT_DISPLAY_FULLSCREEN), stateDisplayFullscreen = _k[0], setStateDisplayFullscreen = _k[1];
    var _l = useState(displayPlay !== null && displayPlay !== void 0 ? displayPlay : DEFAULT_DISPLAY_PLAY), stateDisplayPlay = _l[0], setStateDisplayPlay = _l[1];
    var _m = useState(displayProgressBar !== null && displayProgressBar !== void 0 ? displayProgressBar : DEFAULT_DISPLAY_PROGRESS_BAR), stateDisplayProgressBar = _m[0], setStateDisplayProgressBar = _m[1];
    var _o = useState(displayTime !== null && displayTime !== void 0 ? displayTime : DEFAULT_DISPLAY_TIME), stateDisplayTime = _o[0], setStateDisplayTime = _o[1];
    useEffect(function () { return setStateMute(mute !== null && mute !== void 0 ? mute : DEFAULT_MUTE); }, [mute]);
    useEffect(function () { return setStateVolume(volume !== null && volume !== void 0 ? volume : DEFAULT_VOLUME); }, [volume]);
    useEffect(function () { return setStateFullscreen(fullscreen !== null && fullscreen !== void 0 ? fullscreen : DEFAULT_FULLSCREEN); }, [fullscreen]);
    useEffect(function () { return setStatePlay(play !== null && play !== void 0 ? play : DEFAULT_PLAY); }, [play]);
    useEffect(function () { return setStateDisplay(display !== null && display !== void 0 ? display : DEFAULT_DISPLAY); }, [display]);
    useEffect(function () { return setStateDisplayVolume(displayVolume !== null && displayVolume !== void 0 ? displayVolume : DEFAULT_DISPLAY_VOLUME); }, [displayVolume]);
    useEffect(function () { return setStateDisplayFullscreen(displayFullscreen !== null && displayFullscreen !== void 0 ? displayFullscreen : DEFAULT_DISPLAY_FULLSCREEN); }, [displayFullscreen]);
    useEffect(function () { return setStateDisplayPlay(displayPlay !== null && displayPlay !== void 0 ? displayPlay : DEFAULT_DISPLAY_PLAY); }, [displayPlay]);
    useEffect(function () { return setStateDisplayProgressBar(displayProgressBar !== null && displayProgressBar !== void 0 ? displayProgressBar : DEFAULT_DISPLAY_PROGRESS_BAR); }, [displayProgressBar]);
    useEffect(function () { return setStateDisplayTime(displayTime !== null && displayTime !== void 0 ? displayTime : DEFAULT_DISPLAY_TIME); }, [displayTime]);
    useImperativeHandle(forwardRef, function () { return ({
        setMute: function (value) { return setStateMute(value); },
        setVolume: function (value) { return setStateVolume(value); },
        setFullscreen: function (value) { return setStateFullscreen(value); },
        setPlay: function (value) { return setStatePlay(value); },
        setCurrentTime: function (value) {
            var _a, _b;
            (_a = progressControlsRef === null || progressControlsRef === void 0 ? void 0 : progressControlsRef.current) === null || _a === void 0 ? void 0 : _a.setCurrentTime(value);
            (_b = displayTimeControlsRef === null || displayTimeControlsRef === void 0 ? void 0 : displayTimeControlsRef.current) === null || _b === void 0 ? void 0 : _b.setCurrentTime(value);
        },
        setStartsAt: function (value) {
            var _a, _b;
            (_a = progressControlsRef === null || progressControlsRef === void 0 ? void 0 : progressControlsRef.current) === null || _a === void 0 ? void 0 : _a.setStartsAt(value);
            (_b = displayTimeControlsRef === null || displayTimeControlsRef === void 0 ? void 0 : displayTimeControlsRef.current) === null || _b === void 0 ? void 0 : _b.setStartsAt(value);
        },
        setDuration: function (value) {
            var _a, _b;
            (_a = progressControlsRef === null || progressControlsRef === void 0 ? void 0 : progressControlsRef.current) === null || _a === void 0 ? void 0 : _a.setDuration(value);
            (_b = displayTimeControlsRef === null || displayTimeControlsRef === void 0 ? void 0 : displayTimeControlsRef.current) === null || _b === void 0 ? void 0 : _b.setDuration(value);
        },
        setDisplay: function (value) { return setStateDisplay(value); },
        setDisplayVolume: function (value) { return setStateDisplayVolume(value); },
        setDisplayFullscreen: function (value) { return setStateDisplayFullscreen(value); },
        setDisplayPlay: function (value) { return setStateDisplayPlay(value); },
        setDisplayProgressBar: function (value) { return setStateDisplayProgressBar(value); },
        setDisplayTime: function (value) { return setStateDisplayTime(value); },
    }); }, []);
    var getContentWithButtonWithoutVolumeAndDisplayTime = function () {
        var _a, _b, _c, _d;
        var playWidth = 0;
        var fullscreenWidth = 0;
        if ((_a = playContainerRef === null || playContainerRef === void 0 ? void 0 : playContainerRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) {
            playWidth = (_b = playContainerRef === null || playContainerRef === void 0 ? void 0 : playContainerRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth;
        }
        if ((_c = fullscreenContainerRef === null || fullscreenContainerRef === void 0 ? void 0 : fullscreenContainerRef.current) === null || _c === void 0 ? void 0 : _c.offsetWidth) {
            fullscreenWidth = (_d = fullscreenContainerRef === null || fullscreenContainerRef === void 0 ? void 0 : fullscreenContainerRef.current) === null || _d === void 0 ? void 0 : _d.offsetWidth;
        }
        return playWidth + fullscreenWidth;
    };
    var getContentWithButtonWithoutVolume = function () {
        var _a, _b;
        var displayTimeWidth = 0;
        if ((_a = displayTimeContainerRef === null || displayTimeContainerRef === void 0 ? void 0 : displayTimeContainerRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) {
            displayTimeWidth = (_b = displayTimeContainerRef === null || displayTimeContainerRef === void 0 ? void 0 : displayTimeContainerRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth;
        }
        return getContentWithButtonWithoutVolumeAndDisplayTime() + displayTimeWidth;
    };
    var getRowButtonHeight = function () {
        var _a, _b;
        return ((_b = (_a = rowButtonContainerRef === null || rowButtonContainerRef === void 0 ? void 0 : rowButtonContainerRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) !== null && _b !== void 0 ? _b : 0);
    };
    var getMinimumElementSizeWithClassicVolume = function () { return (getContentWithButtonWithoutVolume() + VOLUME_SIZE_NEEDED + 20); };
    var getMinimumElementSizeWithClassicVolumeWithoutDisplayTime = function () { return (getContentWithButtonWithoutVolumeAndDisplayTime() + VOLUME_SIZE_NEEDED + 20); };
    var displayVolumeSlider = false;
    var enableDisplayVolumeSlider = true;
    var enableDisplayVolumeSliderOnHover = true;
    if (isMobilePlatform() || isTouchEnabled()) {
        enableDisplayVolumeSlider = false;
        enableDisplayVolumeSliderOnHover = false;
    }
    var volumeMode = VideoVolumeControlsMode.HORIZONTAL;
    if (!stateDisplayPlay
        && stateDisplayTime === VideoDisplayTimeMode.NONE
        && !stateDisplayProgressBar) {
        // volumeMode = VideoVolumeControlsMode.VERTICAL;
        volumeMode = VideoVolumeControlsMode.HORIZONTAL;
    }
    if ((elementSize === null || elementSize === void 0 ? void 0 : elementSize.width) && elementSize.width < getMinimumElementSizeWithClassicVolumeWithoutDisplayTime()) {
        if (!parentElement || parentElement.clientWidth * 0.9 < getMinimumElementSizeWithClassicVolumeWithoutDisplayTime()) {
            volumeMode = VideoVolumeControlsMode.VERTICAL;
        }
    }
    if (enableDisplayVolumeSlider) {
        if (volumeIsHovered || isTouchEnabled()) {
            displayVolumeSlider = true;
        }
        else if (!stateDisplayProgressBar
            && !stateDisplayPlay
            && stateDisplayTime === VideoDisplayTimeMode.NONE
            && volumeMode === VideoVolumeControlsMode.HORIZONTAL) {
            displayVolumeSlider = true;
        }
    }
    useElementHovered(volumeContainerRef, function (details) {
        if (!enableDisplayVolumeSliderOnHover)
            return;
        if (details.isHovered !== volumeIsHovered)
            setVolumeIsHovered(details.isHovered);
    });
    var divStyle = {};
    Object.assign(divStyle, { backgroundColor: color || DEFAULT_BACKGROUND_COLOR });
    var currentRowButtonsContainerStyle = {};
    if (stateDisplayVolume) {
        if (!stateDisplayPlay && stateDisplayTime === VideoDisplayTimeMode.NONE) {
            currentRowButtonsContainerStyle.justifyContent = 'flex-end';
        }
        if (stateDisplayProgressBar) {
            Object.assign(divStyle, {
                minWidth: "".concat(MIN_ELEMENT_SIZE, "px"),
            });
        }
        else {
            currentRowButtonsContainerStyle.marginBottom = '2px';
            if (stateDisplayPlay || stateDisplayTime !== VideoDisplayTimeMode.NONE) {
                Object.assign(divStyle, {
                    minWidth: "max(".concat(MIN_ELEMENT_SIZE, "px, min(90%, ").concat(getMinimumElementSizeWithClassicVolumeWithoutDisplayTime(), "px))"),
                });
            }
            else if (volumeMode === VideoVolumeControlsMode.HORIZONTAL && enableDisplayVolumeSlider) {
                Object.assign(divStyle, {
                    minWidth: "max(".concat(MIN_ELEMENT_SIZE, "px, min(90%, ").concat(getMinimumElementSizeWithClassicVolumeWithoutDisplayTime(), "px))"),
                });
            }
        }
    }
    if (stateDisplayProgressBar
        || ((stateDisplayPlay || stateDisplayTime !== VideoDisplayTimeMode.NONE) && (stateDisplayVolume || stateDisplayFullscreen))) {
        divStyle.width = '500px';
    }
    else {
        divStyle.width = 'unset';
    }
    if (style)
        Object.assign(divStyle, style);
    if (containerRef.current)
        Object.assign(containerRef.current.style, divStyle);
    var renderPlay = function () { return (React.createElement("div", { className: "video_controls-play-container", ref: playContainerRef },
        React.createElement(TouchableImageSwitch, { enable: statePlay, srcEnabled: PauseButton, srcDisabled: PlayButton, onEnable: function (e) {
                if (e !== statePlay)
                    setStatePlay(e);
                onPlay === null || onPlay === void 0 ? void 0 : onPlay(e);
            }, className: "video_controls-play", imageClass: "video_controls-play-image" }))); };
    var renderDisplayTime = function () {
        var currentContainerStyle = {};
        if ((elementSize === null || elementSize === void 0 ? void 0 : elementSize.width) && elementSize.width < getMinimumElementSizeWithClassicVolume() && enableDisplayVolumeSlider) {
            currentContainerStyle.position = 'absolute';
            currentContainerStyle.opacity = 0;
            currentContainerStyle.height = 0;
        }
        return (React.createElement("div", { style: currentContainerStyle, className: "video_controls-display-container", ref: displayTimeContainerRef },
            React.createElement(VideoDisplayTimeControls, { ref: displayTimeControlsRef, currentTime: currentTime, startsAt: startsAt, duration: duration, displayMode: displayTime, className: "video_controls-display" })));
    };
    var renderProgressBar = function () { return (React.createElement(VideoProgressControls, { ref: progressControlsRef, currentTime: currentTime, startsAt: startsAt, duration: duration, onChange: onTime, onFocus: function (value) {
            if (value) {
                focusingProgressBar.current.playing = statePlay;
                focusingProgressBar.current.focusing = true;
                onPlay === null || onPlay === void 0 ? void 0 : onPlay(false);
                setStatePlay(false);
            }
            else {
                focusingProgressBar.current.focusing = false;
                onPlay === null || onPlay === void 0 ? void 0 : onPlay(focusingProgressBar.current.playing);
                setStatePlay(focusingProgressBar.current.playing);
            }
        }, className: "video_controls-progress-bar" })); };
    var renderVolume = function () {
        var currentContainerStyle = {};
        var currentClasses = [];
        if (volumeMode === VideoVolumeControlsMode.HORIZONTAL) {
            currentClasses.push('video_controls-volume-horizontal');
            if (enableDisplayVolumeSlider && displayVolumeSlider) {
                currentClasses.push('video_controls-volume-extended-horizontal');
            }
        }
        else if (volumeMode === VideoVolumeControlsMode.VERTICAL) {
            Object.assign(currentContainerStyle, { minWidth: "".concat(getRowButtonHeight(), "px") });
            currentClasses.push('video_controls-volume-vertical');
            if (enableDisplayVolumeSlider && displayVolumeSlider) {
                currentClasses.push('video_controls-volume-extended-vertical');
            }
        }
        return (React.createElement("div", { style: currentContainerStyle, className: "video_controls-volume-container", ref: volumeContainerRef },
            React.createElement(VideoVolumeControls, { mute: stateMute, onMute: function (value) {
                    if (value !== stateMute)
                        setStateMute(value);
                    onMute === null || onMute === void 0 ? void 0 : onMute(value);
                }, volume: stateVolume, onVolume: function (value) {
                    if (value !== stateVolume)
                        setStateVolume(value);
                    onVolume === null || onVolume === void 0 ? void 0 : onVolume(value);
                }, displayVolumeSlider: displayVolumeSlider, className: currentClasses.join(' '), mode: volumeMode })));
    };
    var renderFullscreen = function () { return (React.createElement("div", { className: "video_controls-fullscren-container", ref: fullscreenContainerRef },
        React.createElement(TouchableImageSwitch, { enable: stateFullscreen, srcEnabled: FullscreenDisable, srcDisabled: FullscreenEnable, onEnable: function (e) {
                if (e !== stateFullscreen)
                    setStateFullscreen(e);
                onFullscreen === null || onFullscreen === void 0 ? void 0 : onFullscreen(e);
            }, className: "video_controls-fullscren", imageClass: "video_controls-fullscren-image", trigger: TouchableSwitchTrigger.PRESS_OUT }))); };
    var classes = ['video_controls-container'];
    if (className)
        classes.push(className);
    if (!stateDisplay)
        classes.push('video_controls-hide');
    var render = function () { return (React.createElement("div", { style: divStyle, className: classes.join(' '), ref: containerRef },
        React.createElement("div", { style: currentRowButtonsContainerStyle, className: "video_controls-row-buttons-container", ref: rowButtonContainerRef },
            stateDisplayPlay || stateDisplayTime !== VideoDisplayTimeMode.NONE ? (React.createElement("div", { style: { minWidth: '0px' }, className: "video_controls-buttons-container" },
                stateDisplayPlay ? renderPlay() : null,
                stateDisplayTime !== VideoDisplayTimeMode.NONE ? renderDisplayTime() : null)) : null,
            stateDisplayVolume || stateDisplayFullscreen ? (React.createElement("div", { className: "video_controls-buttons-container" },
                stateDisplayVolume ? renderVolume() : null,
                stateDisplayFullscreen ? renderFullscreen() : null)) : null),
        stateDisplayProgressBar ? renderProgressBar() : null)); };
    return render();
});
VideoControls.defaultProps = {
    mute: DEFAULT_MUTE,
    volume: DEFAULT_VOLUME,
    fullscreen: DEFAULT_FULLSCREEN,
    play: DEFAULT_PLAY,
    currentTime: DEFAULT_CURRENT_TIME,
    startsAt: DEFAULT_STARTS_AT,
    duration: DEFAULT_DURATION,
    onMute: undefined,
    onVolume: undefined,
    onFullscreen: undefined,
    onPlay: undefined,
    onTime: undefined,
    display: DEFAULT_DISPLAY,
    displayVolume: DEFAULT_DISPLAY_VOLUME,
    displayFullscreen: DEFAULT_DISPLAY_FULLSCREEN,
    displayPlay: DEFAULT_DISPLAY_PLAY,
    displayProgressBar: DEFAULT_DISPLAY_PROGRESS_BAR,
    displayTime: DEFAULT_DISPLAY_TIME,
    color: DEFAULT_BACKGROUND_COLOR,
    className: undefined,
    style: undefined,
};
export default VideoControls;
