import { SocketActionType } from "../../constants";
// ------------------------------- //
// ------------ User ------------ // 
// ------------------------------- //
function listen(payload, options) {
    return this.emitMessage(SocketActionType.WEBHOOKS_LISTEN, payload, options);
}
function unlisten(payload, options) {
    return this.emitMessage(SocketActionType.WEBHOOKS_UNLISTEN, payload, options);
}
var webhooksCommands = {
    listen: listen,
    unlisten: unlisten,
};
export default webhooksCommands;
