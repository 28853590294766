/*
 * Use this template:
 *
 * export default {
 *  getDark: (opacity : number = 1) : string => `rgba(r, g, b, ${opacity})`, // #HEXCODE
 *  getDarkBlue: (opacity : number = 1) : string => `rgba(r, g, b, ${opacity})`, // #HEXCODE
 * }
 *
 * It will force a common "code" (rgba, over HEX or css) and allow nuances via opacity
 *
 * To get color name use this site: https://chir.ag/projects/name-that-color/#FFFFFF
 */
var Colors = {
    getMainWhite: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(255, 255, 255, ".concat(opacity, ")");
    },
    getMainGreen: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(23, 191, 135, ".concat(opacity, ")");
    },
    getMainPurple: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(91, 65, 240, ".concat(opacity, ")");
    },
    getMainBlack: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(0, 0, 0, ".concat(opacity, ")");
    },
    getMainRed: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(255, 0, 0, ".concat(opacity, ")");
    },
    getMirage: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(22, 30, 42, ".concat(opacity, ")");
    },
    getGallery: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(235, 235, 235, ".concat(opacity, ")");
    },
    // Red
    getRed: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(237, 12, 12, ".concat(opacity, ")");
    },
    getDeleteRed: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(255, 14, 14, ".concat(opacity, ")");
    },
    getTorchRed: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(255, 14, 67, ".concat(opacity, ")");
    },
    // Orange
    getTreePoppy: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(255, 152, 32, ".concat(opacity, ")");
    },
    // Blue
    getClayEbony: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(37, 49, 68, ".concat(opacity, ")");
    },
    getBluePrussian: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(0, 36, 90, ".concat(opacity, ")");
    },
    getBlueOxford: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(56, 67, 84, ".concat(opacity, ")");
    },
    getBlueDodger: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(0, 186, 255, ".concat(opacity, ")");
    },
    getBlueCornflower: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(112, 87, 255, ".concat(opacity, ")");
    },
    // Grey
    getGrayLevel: function (level, opacity) {
        if (opacity === void 0) { opacity = 1; }
        var value = Math.max(0, Math.min(Math.floor(level * 255)));
        return "rgba(".concat(value, ", ").concat(value, ", ").concat(value, ", ").concat(opacity, ")");
    },
    getGraySantas: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(156, 153, 177, ".concat(opacity, ")");
    },
    getGrayAthens: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(248, 248, 249, ".concat(opacity, ")");
    },
    getGrayDusty: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(151, 151, 151, ".concat(opacity, ")");
    },
    getSilverChalice: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(171, 171, 171, ".concat(opacity, ")");
    },
    // Purple
    getPurpleHeart: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(82, 59, 209, ".concat(opacity, ")");
    },
    // Green
    getMountainMeadow: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(23, 191, 136, ".concat(opacity, ")");
    },
    getSalem: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(12, 131, 92, ".concat(opacity, ")");
    },
    // Rose
    getRoseOld: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(198, 154, 126, ".concat(opacity, ")");
    },
    getClamShell: function (opacity) {
        if (opacity === void 0) { opacity = 1; }
        return "rgba(219, 197, 183, ".concat(opacity, ")");
    }, // #DBC5B7
};
export default Colors;
