import React, { useEffect, useImperativeHandle, useState } from 'react';
import Colors from '../../../constants/colors';
import { isTouchEnabled } from '../../../services/utils';
import { Slider, SliderThumbDisplay } from '../../util/Slider';
import './ProgressControls.css';
var DEFAULT_DURATION = 0;
var DEFAULT_STARTS_AT = 0;
var DEFAULT_CURRENT_TIME = 0;
export var VideoProgressControls = React.forwardRef(function (_a, forwardRef) {
    var currentTime = _a.currentTime, startsAt = _a.startsAt, duration = _a.duration, onChange = _a.onChange, onFocus = _a.onFocus, className = _a.className, style = _a.style;
    var stepBySeconds = 100;
    var _b = useState(currentTime !== null && currentTime !== void 0 ? currentTime : DEFAULT_CURRENT_TIME), stateCurrentTime = _b[0], setStateCurrentTime = _b[1];
    var _c = useState(startsAt !== null && startsAt !== void 0 ? startsAt : DEFAULT_STARTS_AT), stateStartsAt = _c[0], setStateStartsAt = _c[1];
    var _d = useState(duration !== null && duration !== void 0 ? duration : DEFAULT_DURATION), stateDuration = _d[0], setStateDuration = _d[1];
    useEffect(function () { return setStateCurrentTime(currentTime !== null && currentTime !== void 0 ? currentTime : DEFAULT_CURRENT_TIME); }, [currentTime]);
    useEffect(function () { return setStateStartsAt(startsAt !== null && startsAt !== void 0 ? startsAt : DEFAULT_STARTS_AT); }, [startsAt]);
    useEffect(function () { return setStateDuration(duration !== null && duration !== void 0 ? duration : DEFAULT_DURATION); }, [duration]);
    useImperativeHandle(forwardRef, function () { return ({
        setCurrentTime: function (value) { return setStateCurrentTime(value); },
        setStartsAt: function (value) { return setStateStartsAt(value); },
        setDuration: function (value) { return setStateDuration(value); },
    }); }, []);
    var divStyle = {};
    if (style)
        Object.assign(divStyle, style);
    var currentDuration = Number.isNaN(stateDuration) ? 0 : stateDuration;
    var min = stateStartsAt * stepBySeconds;
    var max = (stateStartsAt + currentDuration) * stepBySeconds;
    var value = (Math.min(stateCurrentTime, currentDuration !== null && currentDuration !== void 0 ? currentDuration : 0)) * stepBySeconds;
    var render = function () { return (React.createElement(Slider, { style: divStyle, className: className, min: min, max: max, value: value, minimumTrackColor: Colors.getMainPurple(), maximumTrackColor: Colors.getClayEbony(), thumbColor: Colors.getMainWhite(), thumbSize: 15, thumbDisplay: isTouchEnabled() ? SliderThumbDisplay.ALWAYS : SliderThumbDisplay.ON_HOVER, transitionDuration: 20, onValue: function (v) {
            onChange === null || onChange === void 0 ? void 0 : onChange(v / stepBySeconds, stateStartsAt, stateDuration);
        }, onFocus: onFocus })); };
    return render();
});
VideoProgressControls.defaultProps = {
    currentTime: DEFAULT_CURRENT_TIME,
    startsAt: DEFAULT_STARTS_AT,
    duration: DEFAULT_DURATION,
    onFocus: undefined,
    className: undefined,
    style: undefined,
};
export default VideoProgressControls;
