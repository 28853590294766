import { convertSrcOvenMediaToUrl, OvenMediaReceiverWebRTCSession, WebRTCSessionState, } from '@kalyzee/kast-webrtc-client-module';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { VideoDefaultParams } from '../../constants/video';
import { usePrevious } from '../../hooks/utils';
import { deepEqual } from '../../services/utils';
import { Video } from './Video';
import './VideoOvenMedia.css';
var DEFAULT_AUTO_RETRY = true;
var DEFAULT_RETRY_AFTER = 3000;
var srcIsHls = function (src) {
    if (!src)
        return true;
    var url = new URL(typeof src === 'string' ? src : src.url);
    if (url.protocol.startsWith('ws'))
        return false;
    return true;
};
export var VideoOvenMediaState;
(function (VideoOvenMediaState) {
    VideoOvenMediaState[VideoOvenMediaState["WEBRTC_WAITING"] = 0] = "WEBRTC_WAITING";
    VideoOvenMediaState[VideoOvenMediaState["WEBRTC_LOADING"] = 1] = "WEBRTC_LOADING";
    VideoOvenMediaState[VideoOvenMediaState["WEBRTC_ERROR"] = 2] = "WEBRTC_ERROR";
    VideoOvenMediaState[VideoOvenMediaState["WEBRTC_RUNNING"] = 3] = "WEBRTC_RUNNING";
    VideoOvenMediaState[VideoOvenMediaState["WEBRTC_CLOSED"] = 4] = "WEBRTC_CLOSED";
    VideoOvenMediaState[VideoOvenMediaState["HLS_WAITING"] = 5] = "HLS_WAITING";
    VideoOvenMediaState[VideoOvenMediaState["HLS_LOADING"] = 6] = "HLS_LOADING";
    VideoOvenMediaState[VideoOvenMediaState["HLS_RUNNING"] = 7] = "HLS_RUNNING";
    VideoOvenMediaState[VideoOvenMediaState["HLS_ERROR"] = 8] = "HLS_ERROR";
})(VideoOvenMediaState || (VideoOvenMediaState = {}));
export var VideoOvenMedia = React.forwardRef(function (_a, forwardRef) {
    var src = _a.src, autoRetry = _a.autoRetry, retryAfter = _a.retryAfter, autoStart = _a.autoStart, mute = _a.mute, volume = _a.volume, play = _a.play, autoPlay = _a.autoPlay, onMute = _a.onMute, onVolume = _a.onVolume, onPlay = _a.onPlay, onError = _a.onError, onEnd = _a.onEnd, loop = _a.loop, controls = _a.controls, onState = _a.onState, onStats = _a.onStats, onReady = _a.onReady, onMediaStream = _a.onMediaStream, className = _a.className, style = _a.style;
    var _b = useState(srcIsHls(src)), isHls = _b[0], setIsHls = _b[1];
    var _c = useState(), stream = _c[0], setStream = _c[1];
    var _d = useState(isHls ? VideoOvenMediaState.WEBRTC_WAITING : VideoOvenMediaState.HLS_WAITING), state = _d[0], setState = _d[1];
    var _e = useState(), webrtcSession = _e[0], setWebrtcSession = _e[1];
    var lastSrc = usePrevious(src);
    var callbacksRef = useRef({});
    var enableCloseCallbackRef = useRef(true);
    var videoRef = useRef();
    var statsRef = useRef({});
    var retryVideoHlsTimeoutRef = useRef();
    var stop = function () {
        webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
    };
    var start = function () {
        var _a;
        if (retryVideoHlsTimeoutRef.current) {
            clearTimeout(retryVideoHlsTimeoutRef.current);
            retryVideoHlsTimeoutRef.current = undefined;
        }
        if (srcIsHls(src)) {
            webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
            if (isHls) {
                (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.reload();
            }
            setIsHls(true);
            setState(VideoOvenMediaState.HLS_LOADING);
            return;
        }
        var options = {
            autoRetry: autoRetry,
            retryAfter: retryAfter,
        };
        var session = new OvenMediaReceiverWebRTCSession(options);
        enableCloseCallbackRef.current = true;
        setIsHls(false);
        setState(VideoOvenMediaState.WEBRTC_LOADING);
        setWebrtcSession(session);
    };
    // ------------ LISTENERS -------------- //
    callbacksRef.current.state = useCallback(function (currState) {
        if (currState === WebRTCSessionState.RUNNING) {
            setState(VideoOvenMediaState.WEBRTC_RUNNING);
        }
    }, [setState]);
    callbacksRef.current.stream = useCallback(function (s) {
        setStream(s);
    }, [setStream]);
    callbacksRef.current.stats = useCallback(function (_connection, track, stats) {
        if (!stats)
            return;
        if ((track === null || track === void 0 ? void 0 : track.kind) !== 'video')
            return;
        statsRef.current.rtc = stats;
        onStats === null || onStats === void 0 ? void 0 : onStats(false, statsRef.current);
    }, [statsRef, onStats]);
    useEffect(function () {
        if (typeof src !== typeof lastSrc) {
            start();
            return;
        }
        if (typeof src === 'string' && src !== lastSrc) {
            start();
            return;
        }
        if (!deepEqual(src, lastSrc)) {
            start();
        }
    }, [src]);
    var reset = function () { start(); };
    var getState = function () { return state; };
    var getMediaStream = function () { return stream; };
    var getRTCPeerConnection = function () { var _a; return (_a = webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.rtcSession) === null || _a === void 0 ? void 0 : _a.getRTCPeerConnection(); };
    useEffect(function () {
        if (autoStart)
            start();
        return function () {
            webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
        };
    }, []);
    useEffect(function () {
        if (onState)
            onState(isHls, state, stream, getRTCPeerConnection());
    }, [state]);
    useEffect(function () {
        if (stream)
            onMediaStream === null || onMediaStream === void 0 ? void 0 : onMediaStream(true, stream, getRTCPeerConnection());
    }, [stream]);
    useEffect(function () {
        var current = webrtcSession;
        var listeners = {};
        if (current) {
            var events = Object.keys(callbacksRef.current);
            events.forEach(function (event) {
                var listener = callbacksRef.current[event];
                listeners[event] = listener;
                current.addEventListener(event, listener);
            });
            current.start(src);
        }
        return function () {
            if (current) {
                var events = Object.keys(listeners);
                events.forEach(function (event) {
                    var listener = listeners[event];
                    current.removeEventListener(event, listener);
                });
                current.destroy();
            }
        };
    }, [webrtcSession]);
    useImperativeHandle(forwardRef, function () { return ({
        reconnect: function () { return start(); },
        isHls: function () { return srcIsHls(src); },
        start: start,
        reset: reset,
        stop: stop,
        getStats: function () { return statsRef.current; },
        getHls: function () { var _a; return (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHls(); },
        getState: getState,
        getMediaStream: getMediaStream,
        getRTCPeerConnection: getRTCPeerConnection,
        getVideoRef: function () { return videoRef.current; },
        getHTMLVideoElement: function () { var _a; return (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHTMLVideoElement(); },
    }); }, [webrtcSession, stream, state, videoRef, src]);
    return (React.createElement(Video, { src: isHls ? convertSrcOvenMediaToUrl(src) : undefined, srcIsHls: isHls, ref: videoRef, style: style, className: className, media: isHls ? undefined : stream, volume: volume, mute: mute, play: play, autoPlay: autoPlay, loop: loop, controls: controls, onMute: onMute, onVolume: onVolume, onPlay: onPlay, onHlsStats: function (stats) {
            statsRef.current.hls = stats;
            onStats === null || onStats === void 0 ? void 0 : onStats(true, statsRef.current);
        }, onError: function () {
            setState(isHls ? VideoOvenMediaState.HLS_ERROR : VideoOvenMediaState.WEBRTC_ERROR);
            stop();
            onError === null || onError === void 0 ? void 0 : onError();
            if (!retryVideoHlsTimeoutRef.current && videoRef.current) {
                retryVideoHlsTimeoutRef.current = setTimeout(function () {
                    var _a;
                    (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.reload();
                    clearTimeout(retryVideoHlsTimeoutRef.current);
                    retryVideoHlsTimeoutRef.current = undefined;
                }, retryAfter);
            }
        }, onEnd: onEnd, onReady: function (ready) {
            if (isHls) {
                setState(VideoOvenMediaState.HLS_RUNNING);
                onReady === null || onReady === void 0 ? void 0 : onReady(true, ready, undefined, undefined);
                return;
            }
            onReady === null || onReady === void 0 ? void 0 : onReady(false, ready, stream, getRTCPeerConnection());
        } }));
});
VideoOvenMedia.defaultProps = {
    autoRetry: DEFAULT_AUTO_RETRY,
    retryAfter: DEFAULT_RETRY_AFTER,
    autoStart: true,
    mute: VideoDefaultParams.mute,
    volume: VideoDefaultParams.volume,
    play: VideoDefaultParams.play,
    autoPlay: VideoDefaultParams.autoPlay,
    loop: VideoDefaultParams.loop,
    controls: VideoDefaultParams.controls,
    onMute: undefined,
    onVolume: undefined,
    onPlay: undefined,
    onError: undefined,
    onEnd: undefined,
    onState: undefined,
    onMediaStream: undefined,
    onStats: undefined,
    onReady: undefined,
    className: undefined,
    style: undefined,
};
export default VideoOvenMedia;
