var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SocketActionType } from "../../constants";
import { SocketActionHandler } from "../../handler";
var OrganizationGetDevicesActionHandler = /** @class */ (function (_super) {
    __extends(OrganizationGetDevicesActionHandler, _super);
    function OrganizationGetDevicesActionHandler() {
        return _super.call(this, SocketActionType.ORGANIZATION_DEVICES) || this;
    }
    return OrganizationGetDevicesActionHandler;
}(SocketActionHandler));
export { OrganizationGetDevicesActionHandler };
var OrganizationGetDeviceUpdateSessionsActionHandler = /** @class */ (function (_super) {
    __extends(OrganizationGetDeviceUpdateSessionsActionHandler, _super);
    function OrganizationGetDeviceUpdateSessionsActionHandler() {
        return _super.call(this, SocketActionType.ORGANIZATION_DEVICES_UPDATE_SESSIONS) || this;
    }
    return OrganizationGetDeviceUpdateSessionsActionHandler;
}(SocketActionHandler));
export { OrganizationGetDeviceUpdateSessionsActionHandler };
var OrganizationGetInformationAboutDevicesActionHandler = /** @class */ (function (_super) {
    __extends(OrganizationGetInformationAboutDevicesActionHandler, _super);
    function OrganizationGetInformationAboutDevicesActionHandler() {
        return _super.call(this, SocketActionType.ORGANIZATION_DEVICES_INFOMRATION) || this;
    }
    return OrganizationGetInformationAboutDevicesActionHandler;
}(SocketActionHandler));
export { OrganizationGetInformationAboutDevicesActionHandler };
