var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { KalyzeeReceiverWebRTCSession, WebRTCSessionState } from '@kalyzee/kast-webrtc-client-module';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { VideoDefaultParams } from '../../constants/video';
import { usePrevious } from '../../hooks/utils';
import { deepEqual } from '../../services/utils';
import { Video } from './Video';
import './VideoKalyzeeWebRTC.css';
export var VideoKalyzeeWebRTCState;
(function (VideoKalyzeeWebRTCState) {
    VideoKalyzeeWebRTCState[VideoKalyzeeWebRTCState["UNAUTHORIZED"] = 0] = "UNAUTHORIZED";
    VideoKalyzeeWebRTCState[VideoKalyzeeWebRTCState["NOT_FOUND"] = 1] = "NOT_FOUND";
    VideoKalyzeeWebRTCState[VideoKalyzeeWebRTCState["WAITING"] = 2] = "WAITING";
    VideoKalyzeeWebRTCState[VideoKalyzeeWebRTCState["LOADING"] = 3] = "LOADING";
    VideoKalyzeeWebRTCState[VideoKalyzeeWebRTCState["ERROR"] = 4] = "ERROR";
    VideoKalyzeeWebRTCState[VideoKalyzeeWebRTCState["RUNNING"] = 5] = "RUNNING";
    VideoKalyzeeWebRTCState[VideoKalyzeeWebRTCState["CLOSED"] = 6] = "CLOSED";
})(VideoKalyzeeWebRTCState || (VideoKalyzeeWebRTCState = {}));
export var VideoKalyzeeWebRTC = React.forwardRef(function (_a, forwardRef) {
    var src = _a.src, autoStart = _a.autoStart, mute = _a.mute, volume = _a.volume, play = _a.play, autoPlay = _a.autoPlay, onMute = _a.onMute, onVolume = _a.onVolume, onPlay = _a.onPlay, onError = _a.onError, onEnd = _a.onEnd, loop = _a.loop, controls = _a.controls, onStateChange = _a.onStateChange, onStats = _a.onStats, onVideoReady = _a.onVideoReady, onStatsInterval = _a.onStatsInterval, onMediaStream = _a.onMediaStream, className = _a.className, style = _a.style;
    var _b = useState(), stream = _b[0], setStream = _b[1];
    var _c = useState(VideoKalyzeeWebRTCState.WAITING), state = _c[0], setState = _c[1];
    var _d = useState(), webrtcSession = _d[0], setWebrtcSession = _d[1];
    var lastSrc = usePrevious(src);
    var callbacksRef = useRef({});
    var enableCloseCallbackRef = useRef(true);
    var videoRef = useRef();
    var stop = function () {
        webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
    };
    var start = function () {
        var options = {
            enableRtcStats: false,
        };
        var session = new KalyzeeReceiverWebRTCSession(options);
        enableCloseCallbackRef.current = true;
        setState(VideoKalyzeeWebRTCState.LOADING);
        setWebrtcSession(session);
    };
    // ------------ LISTENERS -------------- //
    callbacksRef.current.state = useCallback(function (currState) {
        if (currState === WebRTCSessionState.RUNNING) {
            setState(VideoKalyzeeWebRTCState.RUNNING);
        }
    }, [setState]);
    callbacksRef.current.stream = useCallback(function (s) {
        setStream(s);
    }, [setStream]);
    callbacksRef.current.error = useCallback(function (_error) {
        setState(VideoKalyzeeWebRTCState.ERROR);
        webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
    }, [setState, webrtcSession]);
    callbacksRef.current.close = useCallback(function () {
        if (!enableCloseCallbackRef.current)
            return;
        setState(VideoKalyzeeWebRTCState.CLOSED);
        webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
    }, [setState, webrtcSession]);
    callbacksRef.current.generateTokenUnauthorized = useCallback(function () {
        enableCloseCallbackRef.current = false;
        setState(VideoKalyzeeWebRTCState.UNAUTHORIZED);
        webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
    }, [setState, webrtcSession]);
    callbacksRef.current.generateTokenNotFound = useCallback(function () {
        enableCloseCallbackRef.current = false;
        setState(VideoKalyzeeWebRTCState.NOT_FOUND);
        webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
    }, [setState, webrtcSession]);
    callbacksRef.current.generateTokenError = useCallback(function () {
        enableCloseCallbackRef.current = false;
        setState(VideoKalyzeeWebRTCState.ERROR);
        webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
    }, [setState, webrtcSession]);
    useEffect(function () {
        if (typeof src !== typeof lastSrc) {
            start();
            return;
        }
        if (typeof src === 'string' && src !== lastSrc) {
            start();
            return;
        }
        if (!deepEqual(src, lastSrc)) {
            start();
        }
    }, [src]);
    var reset = function () { start(); };
    var getState = function () { return state; };
    var getMediaStream = function () { return stream; };
    var getRTCPeerConnection = function () { var _a; return (_a = webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.rtcSession) === null || _a === void 0 ? void 0 : _a.getRTCPeerConnection(); };
    var getRTCPeerConnectionStats = function (track) { return __awaiter(void 0, void 0, void 0, function () {
        var rtcPeerConnection, stats;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    rtcPeerConnection = getRTCPeerConnection();
                    if (!rtcPeerConnection)
                        return [2 /*return*/, undefined];
                    return [4 /*yield*/, rtcPeerConnection.getStats(track)];
                case 1:
                    stats = _a.sent();
                    return [2 /*return*/, stats];
            }
        });
    }); };
    useEffect(function () {
        if (autoStart)
            start();
        return function () {
            webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
        };
    }, []);
    useEffect(function () {
        var intervalStats;
        if (onStatsInterval && onStatsInterval > 0) {
            intervalStats = setInterval(function () { return __awaiter(void 0, void 0, void 0, function () {
                var stats;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!onStats)
                                return [2 /*return*/];
                            return [4 /*yield*/, getRTCPeerConnectionStats()];
                        case 1:
                            stats = _a.sent();
                            if (stats)
                                onStats(stats);
                            return [2 /*return*/];
                    }
                });
            }); }, onStatsInterval);
        }
        return function () {
            if (intervalStats) {
                clearInterval(intervalStats);
            }
        };
    }, [onStatsInterval]);
    useEffect(function () {
        if (onStateChange)
            onStateChange(state, stream, getRTCPeerConnection());
    }, [state]);
    useEffect(function () {
        if (stream)
            onMediaStream === null || onMediaStream === void 0 ? void 0 : onMediaStream(stream, getRTCPeerConnection());
    }, [stream]);
    useEffect(function () {
        var current = webrtcSession;
        var listeners = {};
        if (current) {
            var events = Object.keys(callbacksRef.current);
            events.forEach(function (event) {
                var listener = callbacksRef.current[event];
                listeners[event] = listener;
                current.addEventListener(event, listener);
            });
            current.start(src);
        }
        return function () {
            if (current) {
                var events = Object.keys(listeners);
                events.forEach(function (event) {
                    var listener = listeners[event];
                    current.removeEventListener(event, listener);
                });
                current.destroy();
            }
        };
    }, [webrtcSession]);
    useImperativeHandle(forwardRef, function () { return ({
        start: start,
        reset: reset,
        stop: stop,
        getState: getState,
        getMediaStream: getMediaStream,
        getRTCPeerConnection: getRTCPeerConnection,
        getRTCPeerConnectionStats: getRTCPeerConnectionStats,
        getVideoRef: function () { return videoRef.current; },
        getHTMLVideoElement: function () { var _a; return (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHTMLVideoElement(); },
    }); }, [webrtcSession, stream, state, videoRef]);
    return (React.createElement(Video, { ref: videoRef, style: style, className: className, media: stream, volume: volume, mute: mute, play: play, autoPlay: autoPlay, loop: loop, controls: controls, onMute: onMute, onVolume: onVolume, onPlay: onPlay, onError: function () {
            setState(VideoKalyzeeWebRTCState.ERROR);
            stop();
            onError === null || onError === void 0 ? void 0 : onError();
        }, onEnd: onEnd, onReady: function (ready) { return onVideoReady === null || onVideoReady === void 0 ? void 0 : onVideoReady(ready, stream, getRTCPeerConnection()); } }));
});
VideoKalyzeeWebRTC.defaultProps = {
    autoStart: true,
    mute: VideoDefaultParams.mute,
    volume: VideoDefaultParams.volume,
    play: VideoDefaultParams.play,
    autoPlay: VideoDefaultParams.autoPlay,
    loop: VideoDefaultParams.loop,
    controls: VideoDefaultParams.controls,
    onMute: undefined,
    onVolume: undefined,
    onPlay: undefined,
    onError: undefined,
    onEnd: undefined,
    onStateChange: undefined,
    onMediaStream: undefined,
    onStats: undefined,
    onStatsInterval: 1000,
    onVideoReady: undefined,
    className: undefined,
    style: undefined,
};
export default VideoKalyzeeWebRTC;
