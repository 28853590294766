import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useOuterClick } from '../../hooks/click';
import { useElementHovered, useElementSize, useWindowSize } from '../../hooks/window';
import './ElementOnHover.css';
export function ElementOnHover(_a) {
    var targetRef = _a.targetRef, hoverOnParentLevel = _a.hoverOnParentLevel, delay = _a.delay, enableTouch = _a.enableTouch, positionFixed = _a.positionFixed, children = _a.children, className = _a.className, style = _a.style;
    var windowSize = useWindowSize();
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var showUpdatedAt = useRef();
    var divRef = useRef(null);
    var delayTimeout = useRef();
    var positionsRef = useRef();
    var touchedRef = useRef(false);
    var getDivPosition = function () {
        var _a;
        if (!divRef.current || !((_a = positionsRef.current) === null || _a === void 0 ? void 0 : _a.absolute))
            return null;
        var divElement = divRef.current;
        var _b = positionsRef.current.absolute, x = _b.x, y = _b.y;
        var margeY = 10;
        var margeX = 20;
        if (divElement && windowSize.width && windowSize.height) {
            var width = divElement.clientWidth;
            var height = divElement.clientHeight;
            if (width > 0 && height > 0) {
                if (y - margeY - height > margeY) {
                    y = y - margeY - height;
                }
                else {
                    y += margeY;
                }
                if (x - width / 2 < margeX) {
                    x = margeX;
                }
                else {
                    x -= width / 2;
                }
                if (x + width > windowSize.width - margeX) {
                    x = windowSize.width - width - margeX;
                }
                return [x, y];
            }
        }
        return null;
    };
    useElementHovered(targetRef, function (details) {
        positionsRef.current = details.positions;
        var pos = getDivPosition();
        if (divRef.current && pos) {
            var x = pos[0], y = pos[1];
            Object.assign(divRef.current.style, {
                top: "".concat(y, "px"),
                left: "".concat(x, "px"),
            });
            if (show) {
                Object.assign(divRef.current.style, {
                    visible: 'visible',
                });
            }
        }
        if (details.isHovered) {
            if (!show) {
                if (delay) {
                    if (!delayTimeout.current) {
                        delayTimeout.current = setTimeout(function () {
                            clearTimeout(delayTimeout.current);
                            delayTimeout.current = undefined;
                            setShow(true);
                        }, delay);
                    }
                }
                else {
                    setShow(true);
                }
            }
        }
        else if (enableTouch && details.isTouching) {
            if (touchedRef.current && show) {
                touchedRef.current = false;
                setShow(false);
            }
            else if (!touchedRef.current && !show) {
                touchedRef.current = true;
                setShow(true);
            }
        }
        else if (!touchedRef.current) {
            if (delayTimeout.current)
                clearTimeout(delayTimeout.current);
            delayTimeout.current = undefined;
            if (show)
                setShow(false);
        }
    }, { enableTouch: enableTouch, hoverOnParentLevel: hoverOnParentLevel });
    useOuterClick(divRef, useCallback(function () {
        if (show) {
            if (showUpdatedAt.current
                && new Date().getTime() - showUpdatedAt.current.getTime() > 100) {
                setShow(false);
            }
        }
    }, [show, setShow]));
    useElementSize(divRef);
    useEffect(function () {
        showUpdatedAt.current = new Date();
        if (!show)
            touchedRef.current = false;
    }, [show]);
    var render = function () {
        var _a, _b, _c;
        var divStyle = {};
        if (style)
            Object.assign(divStyle, style);
        Object.assign(divStyle, { visibility: 'hidden' });
        // Set position
        if (positionFixed) {
            Object.assign(divStyle, {
                top: "".concat((_a = positionFixed.y) !== null && _a !== void 0 ? _a : 0, "px"),
                left: "".concat((_b = positionFixed.x) !== null && _b !== void 0 ? _b : 0, "px"),
            });
        }
        else if ((_c = positionsRef.current) === null || _c === void 0 ? void 0 : _c.absolute) {
            var pos = getDivPosition();
            if (pos) {
                var x = pos[0], y = pos[1];
                Object.assign(divStyle, {
                    visibility: 'visible',
                    top: "".concat(y, "px"),
                    left: "".concat(x, "px"),
                });
            }
        }
        if (!show)
            Object.assign(divStyle, { display: 'none', pointerEvents: 'none' });
        var classes = ['element_on_hover-container'];
        if (className)
            classes.push(className);
        return (React.createElement("div", { ref: divRef, style: divStyle, className: classes.join(' '), role: "button", tabIndex: 0, onClick: function (e) {
                if (show)
                    setShow(false);
                e.preventDefault();
                e.stopPropagation();
            }, onKeyDown: function (e) {
                if (e.code === 'Enter') {
                    if (show)
                        setShow(false);
                    e.preventDefault();
                    e.stopPropagation();
                }
            } }, children));
    };
    return render();
}
ElementOnHover.defaultProps = {
    hoverOnParentLevel: 0,
    enableTouch: true,
    delay: 500,
    className: undefined,
    style: undefined,
};
export default ElementOnHover;
