import React, { useEffect, useState } from 'react';
import { TouchableImageSwitch } from '../../util/TouchableImageSwitch';
import Muted from '../../../../assets/icons/icon-sound-muted.svg';
import UnmutedFull from '../../../../assets/icons/icon-sound-unmuted.svg';
import UnmutedLevel2 from '../../../../assets/icons/icon-sound-unmuted_level_2.svg';
import UnmutedLevel1 from '../../../../assets/icons/icon-sound-unmuted_level_1.svg';
import UnmutedLevel0 from '../../../../assets/icons/icon-sound-unmuted_level_0.svg';
import Colors from '../../../constants/colors';
import { Slider, SliderMode, SliderThumbDisplay } from '../../util/Slider';
import { isTouchEnabled } from '../../../services/utils';
import './VolumeControls.css';
export var VideoVolumeControlsMode;
(function (VideoVolumeControlsMode) {
    VideoVolumeControlsMode["HORIZONTAL"] = "horizontal";
    VideoVolumeControlsMode["HORIZONTAL_REVERSE"] = "horizontal_reverse";
    VideoVolumeControlsMode["VERTICAL"] = "vertical";
    VideoVolumeControlsMode["VERTICAL_REVERSE"] = "vertical_reverse";
})(VideoVolumeControlsMode || (VideoVolumeControlsMode = {}));
var DEFAULT_MODE = VideoVolumeControlsMode.HORIZONTAL;
var DEFAULT_MUTE = true;
var DEFAULT_VOLUME = 1;
var DEFAULT_DISPLAY_VOLUME_SLIDER = true;
var DEFAULT_SLIDER_SIZE = 100;
export var VideoVolumeControls = function (_a) {
    var mute = _a.mute, volume = _a.volume, onMute = _a.onMute, onVolume = _a.onVolume, displayVolumeSlider = _a.displayVolumeSlider, sliderSize = _a.sliderSize, mode = _a.mode, className = _a.className, style = _a.style;
    var _b = useState(mute === undefined ? DEFAULT_MUTE : mute), smute = _b[0], setMute = _b[1];
    var _c = useState(volume === undefined ? DEFAULT_VOLUME : volume), svolume = _c[0], setVolume = _c[1];
    useEffect(function () {
        setMute(mute === undefined ? DEFAULT_MUTE : mute);
        setVolume(volume === undefined ? DEFAULT_VOLUME : volume);
    }, [mute, volume]);
    var divStyle = {};
    if (smute)
        Object.assign(divStyle, { opacity: 0.5 });
    if (style)
        Object.assign(divStyle, style);
    var updateMute = function (m) {
        setMute(m);
        if (onMute)
            onMute(m);
    };
    var updateVolume = function (v) {
        setVolume(v);
        if (v !== volume)
            updateMute(false);
        if (onVolume)
            onVolume(v);
    };
    var renderSlider = function () {
        var sliderMode = SliderMode.HORIZONTAL;
        var sliderWidth = '20px';
        var sliderLength = "".concat(sliderSize, "px");
        var sliderStyle = {
            height: sliderWidth,
            width: sliderLength,
        };
        if (mode === VideoVolumeControlsMode.HORIZONTAL_REVERSE) {
            divStyle.flexDirection = 'row-reverse';
        }
        else if (mode === VideoVolumeControlsMode.VERTICAL) {
            divStyle.flexDirection = 'column';
            sliderMode = SliderMode.VERTICAL;
            sliderStyle.height = sliderLength;
            sliderStyle.width = sliderWidth;
        }
        else if (mode === VideoVolumeControlsMode.VERTICAL_REVERSE) {
            divStyle.flexDirection = 'column-reverse';
            sliderMode = SliderMode.VERTICAL;
            sliderStyle.height = sliderLength;
            sliderStyle.width = sliderWidth;
        }
        return (React.createElement(Slider, { style: sliderStyle, min: 0, max: 100, value: svolume * 100, minimumTrackColor: smute ? Colors.getGrayLevel(0.9) : Colors.getMainPurple(), maximumTrackColor: smute ? Colors.getGrayLevel(0.15) : Colors.getClayEbony(), thumbColor: smute ? Colors.getGrayLevel(0.8) : Colors.getMainWhite(), thumbSize: 15, thumbDisplay: isTouchEnabled() ? SliderThumbDisplay.ALWAYS : SliderThumbDisplay.ON_HOVER, transitionDuration: 0, mode: sliderMode, onValue: function (v) { return updateVolume(v / 100); } }));
    };
    var renderIcon = function () {
        var currentStyle = {
            width: '20px',
            height: '20px',
        };
        var getSrcIcon = function () {
            var src = UnmutedLevel1;
            if (svolume >= 1)
                src = UnmutedFull;
            if (svolume <= 0)
                src = UnmutedLevel0;
            if (svolume > 0.5)
                src = UnmutedLevel2;
            return src;
        };
        return (React.createElement(TouchableImageSwitch, { srcEnabled: getSrcIcon(), srcDisabled: Muted, enable: !smute, onEnable: function (e) { return updateMute(!e); }, alt: smute ? 'unmute' : 'mute', style: currentStyle }));
    };
    var classes = ['volume_controls-container'];
    if (className)
        classes.push(className);
    var render = function () { return (React.createElement("div", { style: divStyle, className: classes.join(' ') },
        displayVolumeSlider ? renderSlider() : null,
        renderIcon())); };
    return render();
};
VideoVolumeControls.defaultProps = {
    mute: DEFAULT_MUTE,
    volume: DEFAULT_VOLUME,
    onMute: undefined,
    onVolume: undefined,
    displayVolumeSlider: DEFAULT_DISPLAY_VOLUME_SLIDER,
    sliderSize: DEFAULT_SLIDER_SIZE,
    mode: DEFAULT_MODE,
    className: undefined,
    style: undefined,
};
export default VideoVolumeControls;
