var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a, _b;
import React from 'react';
import { IconSelector, IconSelectorAdapter } from '../util/IconSelector';
import { ComponentDirection } from '../../constants/global';
import { DeviceView } from '../../constants/camera';
import FirstViewActive from '../../../assets/icons/icon-first-plan-active.svg';
import SecondViewActive from '../../../assets/icons/icon-second-plan-active.svg';
import ThirdViewActive from '../../../assets/icons/icon-third-plan-active.svg';
import FirstView from '../../../assets/icons/icon-first-plan.svg';
import SecondView from '../../../assets/icons/icon-second-plan.svg';
import ThirdView from '../../../assets/icons/icon-third-plan.svg';
import AssignFirstViewActive from '../../../assets/icons/icon-first-plan-update-active.svg';
import AssignSecondViewActive from '../../../assets/icons/icon-second-plan-update-active.svg';
import AssignThirdViewActive from '../../../assets/icons/icon-third-plan-update-active.svg';
import AssignFirstView from '../../../assets/icons/icon-first-plan-update.svg';
import AssignSecondView from '../../../assets/icons/icon-second-plan-update.svg';
import AssignThirdView from '../../../assets/icons/icon-third-plan-update.svg';
import './ViewControls.css';
export var ViewControlsMode;
(function (ViewControlsMode) {
    ViewControlsMode[ViewControlsMode["SELECT"] = 0] = "SELECT";
    ViewControlsMode[ViewControlsMode["ASSIGN"] = 1] = "ASSIGN";
})(ViewControlsMode || (ViewControlsMode = {}));
var DEFAULT_DIRECTION = ComponentDirection.VERTICAL;
var DEFAULT_MODE = ViewControlsMode.SELECT;
var DEFAULT_ICON_SIZE = 20;
var IconViewSelectorAdapter = /** @class */ (function (_super) {
    __extends(IconViewSelectorAdapter, _super);
    function IconViewSelectorAdapter(direction, defaultId, mode, iconSize) {
        var _this = _super.call(this, direction, defaultId) || this;
        _this.mode = mode;
        _this.data = _this.initData(direction, iconSize);
        return _this;
    }
    IconViewSelectorAdapter.prototype.initData = function (_direction, iconSize) {
        if (iconSize === void 0) { iconSize = 20; }
        var style = {};
        Object.assign(style, { width: "".concat(iconSize, "px"), height: "".concat(iconSize, "px") });
        return this.mode === ViewControlsMode.SELECT ? [
            {
                id: DeviceView.VIEW_1,
                active: React.createElement("img", { src: FirstViewActive, style: style, draggable: false, alt: "view1" }),
                inactive: React.createElement("img", { src: FirstView, style: style, draggable: false, alt: "view1" }),
            },
            {
                id: DeviceView.VIEW_2,
                active: React.createElement("img", { src: SecondViewActive, style: style, draggable: false, alt: "view2" }),
                inactive: React.createElement("img", { src: SecondView, style: style, draggable: false, alt: "view2" }),
            },
            {
                id: DeviceView.VIEW_3,
                active: React.createElement("img", { src: ThirdViewActive, style: style, draggable: false, alt: "view3" }),
                inactive: React.createElement("img", { src: ThirdView, style: style, draggable: false, alt: "view3" }),
            },
        ] : [
            {
                id: DeviceView.VIEW_1,
                active: React.createElement("img", { src: AssignFirstViewActive, style: style, draggable: false, alt: "view1" }),
                inactive: React.createElement("img", { src: AssignFirstView, style: style, draggable: false, alt: "view1" }),
            },
            {
                id: DeviceView.VIEW_2,
                active: React.createElement("img", { src: AssignSecondViewActive, style: style, draggable: false, alt: "view2" }),
                inactive: React.createElement("img", { src: AssignSecondView, style: style, draggable: false, alt: "view2" }),
            },
            {
                id: DeviceView.VIEW_3,
                active: React.createElement("img", { src: AssignThirdViewActive, style: style, draggable: false, alt: "view3" }),
                inactive: React.createElement("img", { src: AssignThirdView, style: style, draggable: false, alt: "view3" }),
            },
        ];
    };
    return IconViewSelectorAdapter;
}(IconSelectorAdapter));
export var DEFAULT_VIEW_MAPPING_KEYBOAD_KEYS = (_a = {},
    _a[DeviceView.VIEW_1] = ['1'],
    _a[DeviceView.VIEW_2] = ['2'],
    _a[DeviceView.VIEW_3] = ['3'],
    _a);
export var DEFAULT_ASSIGN_VIEW_MAPPING_KEYBOAD_KEYS = (_b = {},
    _b[DeviceView.VIEW_1] = ['4'],
    _b[DeviceView.VIEW_2] = ['5'],
    _b[DeviceView.VIEW_3] = ['6'],
    _b);
export var ViewControls = function (_a) {
    var mode = _a.mode, view = _a.view, iconSize = _a.iconSize, onView = _a.onView, display = _a.display, direction = _a.direction, enableKeyboardEvent = _a.enableKeyboardEvent, mappingKeyboardKeys = _a.mappingKeyboardKeys, className = _a.className, style = _a.style;
    var dir = direction || DEFAULT_DIRECTION;
    var m = mode === undefined ? DEFAULT_MODE : mode;
    var defaultView = undefined;
    var v = view === undefined ? defaultView : view;
    var adapter = new IconViewSelectorAdapter(dir, defaultView, m, iconSize !== null && iconSize !== void 0 ? iconSize : DEFAULT_ICON_SIZE);
    var index = adapter.getIndex(v);
    var data = adapter.getData();
    var onIndex = function (i) {
        var id = adapter.getId(i);
        if (id !== undefined)
            onView === null || onView === void 0 ? void 0 : onView(id);
    };
    // Set default values
    if (mappingKeyboardKeys === undefined) {
        if (m === ViewControlsMode.SELECT) {
            mappingKeyboardKeys = DEFAULT_VIEW_MAPPING_KEYBOAD_KEYS;
        }
        else if (m === ViewControlsMode.ASSIGN) {
            mappingKeyboardKeys = DEFAULT_ASSIGN_VIEW_MAPPING_KEYBOAD_KEYS;
        }
    }
    if (enableKeyboardEvent && mappingKeyboardKeys) {
        var views = Object.keys(mappingKeyboardKeys);
        views.forEach(function (key) {
            if (mappingKeyboardKeys[key]) {
                data.forEach(function (d) {
                    var _a;
                    if (key === ((_a = d.id) === null || _a === void 0 ? void 0 : _a.toString())) {
                        d.keyboard = mappingKeyboardKeys[key];
                    }
                });
            }
        });
    }
    return (React.createElement(IconSelector, { index: index, onIndex: onIndex, data: data, direction: direction, display: display, style: style, className: className }));
};
ViewControls.defaultProps = {
    mode: ViewControlsMode.SELECT,
    view: undefined,
    iconSize: DEFAULT_ICON_SIZE,
    display: true,
    direction: DEFAULT_DIRECTION,
    enableKeyboardEvent: true,
    mappingKeyboardKeys: undefined,
    className: undefined,
    style: undefined,
    onView: undefined,
};
export default ViewControls;
