var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var IconInfo = function (props) { return (React.createElement("svg", __assign({}, props, { version: "1.1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }),
    React.createElement("g", null,
        React.createElement("path", { d: "M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,\n256,256c141.491,0,256-114.497,256-256 C512,114.509,397.503,0,256,0zM256,\n472.922c-119.611,0-216.922-97.311-216.922-216.922S136.389,39.078,256,39.078 S472.922,136.389,472.922,256S375.611,472.922,256,472.922z" })),
    React.createElement("g", { transform: "scale(1.4) translate(-60, -60)" },
        React.createElement("g", null,
            React.createElement("circle", { cx: "241.3", cy: "159.2", r: "29.1" })),
        React.createElement("g", null,
            React.createElement("polygon", { points: "285.1,359.9 270.4,359.9 219.6,359.9 204.9,359.9 204.9,321 219.6,\n321 219.6,254.8 205.1,254.8 205.1,215.9 \n219.6,215.9 263.1,215.9 270.4,215.9 270.4,321 285.1,321" }))))); };
export default IconInfo;
