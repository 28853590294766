export var DeviceScene;
(function (DeviceScene) {
    DeviceScene[DeviceScene["MIXED"] = 0] = "MIXED";
    DeviceScene[DeviceScene["CAMERA_ONLY"] = 1] = "CAMERA_ONLY";
    DeviceScene[DeviceScene["INPUT_HDMI_ONLY"] = 2] = "INPUT_HDMI_ONLY";
    DeviceScene[DeviceScene["HALF_MODE"] = 3] = "HALF_MODE";
})(DeviceScene || (DeviceScene = {}));
export var DeviceView;
(function (DeviceView) {
    DeviceView[DeviceView["VIEW_1"] = 0] = "VIEW_1";
    DeviceView[DeviceView["VIEW_2"] = 1] = "VIEW_2";
    DeviceView[DeviceView["VIEW_3"] = 2] = "VIEW_3";
})(DeviceView || (DeviceView = {}));
export var DeviceMoveDirection;
(function (DeviceMoveDirection) {
    DeviceMoveDirection["UP"] = "up";
    DeviceMoveDirection["DOWN"] = "down";
    DeviceMoveDirection["LEFT"] = "left";
    DeviceMoveDirection["RIGHT"] = "right";
})(DeviceMoveDirection || (DeviceMoveDirection = {}));
export var DeviceZoomDirection;
(function (DeviceZoomDirection) {
    DeviceZoomDirection["ZOOM_IN"] = "zoomIn";
    DeviceZoomDirection["ZOOM_OUT"] = "zoomOut";
})(DeviceZoomDirection || (DeviceZoomDirection = {}));
