export var addTimeOffsetToDeviceState = function (data) {
    if (!data)
        return data;
    var time = data.time;
    if (time !== undefined) {
        data.timeOffset = new Date().getTime() - time;
    }
    return data;
};
export var addOffsetToISODateTime = function (isoDatetime, offset) {
    if (!isoDatetime)
        return isoDatetime;
    var date = new Date(isoDatetime);
    var result = new Date(date.getTime() - offset);
    return result.toISOString();
};
export var formatDeviceState = function (data) {
    var _a;
    if (!data)
        return data;
    var result = addTimeOffsetToDeviceState(data);
    var timeOffset = (_a = result === null || result === void 0 ? void 0 : result.timeOffset) !== null && _a !== void 0 ? _a : 0;
    var context = result === null || result === void 0 ? void 0 : result.context;
    var videoContext = context === null || context === void 0 ? void 0 : context.videoContext;
    if (videoContext) {
        if (videoContext.liveStartedAt) {
            videoContext.liveStartedAtInLocalTime = addOffsetToISODateTime(videoContext.liveStartedAt, timeOffset);
        }
        if (videoContext.recordStartedAt) {
            videoContext.recordStartedAtInLocalTime = addOffsetToISODateTime(videoContext.recordStartedAt, timeOffset);
        }
        if (videoContext.recordStoppedAt) {
            videoContext.recordStoppedAtInLocalTime = addOffsetToISODateTime(videoContext.recordStoppedAt, timeOffset);
        }
    }
    return result;
};
