import { useEffect, useRef } from 'react';
export var useVideoInterface = function (initialState) {
    var currentTimeRef = useRef(initialState.currentTime);
    var durationRef = useRef(initialState.duration);
    var muteRef = useRef(initialState.mute);
    var volumeRef = useRef(initialState.volume);
    var playRef = useRef(initialState.play);
    var ctx = {
        getMute: function () { return muteRef.current; },
        getVolume: function () { return volumeRef.current; },
        getPlay: function () { return playRef.current; },
        getCurrentTime: function () { return currentTimeRef.current; },
        getDuration: function () { return durationRef.current; },
        setMute: function (m) { muteRef.current = m; },
        setVolume: function (v) { volumeRef.current = v; },
        setPlay: function (p) { playRef.current = p; },
        setCurrentTime: function (t) { currentTimeRef.current = t; },
        setDuration: function (d) { durationRef.current = d; },
    };
    return ctx;
};
export var useVideoRef = function (params, callbacks) {
    var ref = useRef();
    // eslint-disable-next-line arrow-body-style
    var isPlaying = function (el) {
        /* console.log(' Is playing  el.currentTime > 0 && !el.paused && !el.ended && el.readyState > 2 : ', `
          ${el.currentTime} - ${el.paused} - ${el.ended} - ${el.readyState}
        `); */
        return el.currentTime > 0 && !el.paused && !el.ended && el.readyState >= 1;
    };
    var playVideo = function (el, value) {
        var _a, _b;
        if (value === void 0) { value = true; }
        if (!el)
            return false;
        if (!((_b = (_a = el.src) === null || _a === void 0 ? void 0 : _a.trim()) === null || _b === void 0 ? void 0 : _b.length) && !el.srcObject)
            return false; // no src
        var success = false;
        try {
            if (value) {
                var promise = el.play();
                if (promise)
                    promise.catch(function () { });
            }
            else
                el.pause();
            success = true;
        }
        catch (err) {
            success = false;
        }
        return success;
    };
    var setValues = function (el) {
        if (params.autoPlay !== undefined)
            el.autoplay = params.autoPlay;
        if (params.mute !== undefined)
            el.muted = params.mute;
        if (params.volume !== undefined)
            el.volume = params.volume;
        if (params.loop !== undefined)
            el.loop = params.loop;
        if (params.controls !== undefined)
            el.controls = params.controls;
        if (params.play !== undefined) {
            if (params.play)
                playVideo(el, true);
            else
                playVideo(el, false);
        }
        if (params.currentTime !== undefined
            && Math.abs(params.currentTime - el.currentTime) > 0.5) {
            el.currentTime = params.currentTime;
        }
    };
    useEffect(function () {
        var listeners = {};
        var el = ref.current;
        if (el) {
            var callback_1 = function (e) {
                var _a, _b, _c;
                var playing = isPlaying(el) || e.type === 'playing';
                if (params.volume !== el.volume)
                    (_a = callbacks.onVolume) === null || _a === void 0 ? void 0 : _a.call(callbacks, el.volume);
                if (params.mute !== el.muted)
                    (_b = callbacks.onMute) === null || _b === void 0 ? void 0 : _b.call(callbacks, el.muted);
                (_c = callbacks.onPlay) === null || _c === void 0 ? void 0 : _c.call(callbacks, playing);
            };
            listeners.pause = function (event) {
                var _a;
                (_a = callbacks.onCurrentTime) === null || _a === void 0 ? void 0 : _a.call(callbacks, el.currentTime, el.duration);
                callback_1(event);
            };
            listeners.abort = callback_1;
            listeners.volumechange = callback_1;
            listeners.error = function (event) {
                var _a;
                (_a = callbacks.onError) === null || _a === void 0 ? void 0 : _a.call(callbacks);
                callback_1(event);
            };
            listeners.ended = function (event) {
                var _a;
                (_a = callbacks.onEnd) === null || _a === void 0 ? void 0 : _a.call(callbacks);
                callback_1(event);
            };
            listeners.playing = callback_1;
            listeners.durationchange = function (_) {
                var _a;
                (_a = callbacks.onDuration) === null || _a === void 0 ? void 0 : _a.call(callbacks, el.duration);
            };
            listeners.timeupdate = function (_) {
                var _a;
                (_a = callbacks.onCurrentTime) === null || _a === void 0 ? void 0 : _a.call(callbacks, el.currentTime, el.duration);
            };
            setValues(el);
            var events = Object.keys(listeners);
            events.forEach(function (ev) { return el.addEventListener(ev, listeners[ev]); });
        }
        return function () {
            if (el) {
                var events = Object.keys(listeners);
                events.forEach(function (ev) { return el.removeEventListener(ev, listeners[ev]); });
            }
        };
    });
    return ref;
};
export default {};
