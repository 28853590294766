import { __assign, __awaiter, __extends, __generator } from "tslib";
import { KalyzeeSessionMode } from "./constant";
import { defaultKalyzeeWebRTCSessionOptions, KalyzeeBaseWebRTCSession } from "./webrtc";
;
{ }
;
export var defaultKalyzeeReceiverWebRTCSessionOptions = __assign({}, defaultKalyzeeWebRTCSessionOptions);
var KalyzeeReceiverWebRTCSession = /** @class */ (function (_super) {
    __extends(KalyzeeReceiverWebRTCSession, _super);
    function KalyzeeReceiverWebRTCSession(options) {
        return _super.call(this, KalyzeeSessionMode.RECVONLY, __assign(__assign({}, defaultKalyzeeReceiverWebRTCSessionOptions), (options !== null && options !== void 0 ? options : {}))) || this;
    }
    // Override but no changes for the moment
    KalyzeeReceiverWebRTCSession.prototype.start = function (src) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.startSession.call(this, src)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KalyzeeReceiverWebRTCSession.prototype.destroy = function (reason) {
        this.close(reason);
        this.removeAllEventListeners();
    };
    return KalyzeeReceiverWebRTCSession;
}(KalyzeeBaseWebRTCSession));
export { KalyzeeReceiverWebRTCSession };
