import React, { useEffect, useRef } from 'react';
import { useElementSize } from '../../hooks/window';
import Colors from '../../constants/colors';
import './Slider.css';
export var SliderMode;
(function (SliderMode) {
    SliderMode["HORIZONTAL"] = "horizontal";
    SliderMode["HORIZONTAL_REVERSE"] = "horizontal_reverse";
    SliderMode["VERTICAL"] = "vertical";
    SliderMode["VERTICAL_REVERSE"] = "vertical_reverse";
})(SliderMode || (SliderMode = {}));
var DEFAULT_SLIDER_MODE = SliderMode.HORIZONTAL;
export var SliderThumbDisplay;
(function (SliderThumbDisplay) {
    SliderThumbDisplay["ALWAYS"] = "always";
    SliderThumbDisplay["NEVER"] = "never";
    SliderThumbDisplay["ON_HOVER"] = "on_hover";
})(SliderThumbDisplay || (SliderThumbDisplay = {}));
var DEFAULT_SLIDER_THUMB_DISPLAY = SliderThumbDisplay.ON_HOVER;
var DEFAULT_MIN = 0;
var DEFAULT_MAX = 100;
var DEFAULT_VALUE = 100;
var DEFAULT_MIN_TRACK_COLOR = Colors.getMainPurple();
var DEFAULT_MAX_TRACK_COLOR = Colors.getClayEbony();
var DEFAULT_THUMB_COLOR = 'white';
var DEFAULT_THUMB_SIZE = 20;
var DEFAULT_TRANSITION_DURATION = 0;
export var Slider = function (_a) {
    var min = _a.min, max = _a.max, value = _a.value, minimumTrackColor = _a.minimumTrackColor, maximumTrackColor = _a.maximumTrackColor, thumbColor = _a.thumbColor, thumbSize = _a.thumbSize, thumbDisplay = _a.thumbDisplay, transitionDuration = _a.transitionDuration, mode = _a.mode, onValue = _a.onValue, onFocus = _a.onFocus, native = _a.native, className = _a.className, style = _a.style;
    var containerRef = useRef(null);
    var trackRef = useRef(null);
    var thumbRef = useRef(null);
    var currentValueRef = useRef(value !== null && value !== void 0 ? value : DEFAULT_VALUE);
    var focusRef = useRef(false);
    var elementSize = useElementSize(containerRef);
    var currentThumbSize = thumbSize !== null && thumbSize !== void 0 ? thumbSize : DEFAULT_THUMB_SIZE;
    useEffect(function () {
        currentValueRef.current = value === undefined || Number.isNaN(value) ? DEFAULT_VALUE : value;
    }, [value, min, max]);
    var getPercentage = function () {
        if (currentValueRef.current !== undefined
            && max !== undefined
            && min !== undefined
            && max !== min) {
            return currentValueRef.current / (max - min);
        }
        return 0;
    };
    var getTrackWidth = function () { return "calc(".concat(getPercentage() * 100, "% - 6px)"); };
    var getThumbLeftPosition = function (size) { return ("min(calc(100% - ".concat(Math.floor(size / 2), "px), max(").concat(Math.floor(size / 2), "px, ").concat(getPercentage() * 100, "%))")); };
    var backgroundStyle = { backgroundColor: maximumTrackColor };
    var trackStyle = {};
    Object.assign(trackStyle, {
        backgroundColor: minimumTrackColor,
        width: getTrackWidth(),
    });
    var thumbStyle = {};
    var thumbClasses = ['custom_slider-custom-thumb'];
    if (thumbDisplay === SliderThumbDisplay.ON_HOVER)
        thumbClasses.push('custom_slider-custom-thumb-display');
    Object.assign(thumbStyle, {
        width: "".concat(currentThumbSize, "px"),
        height: "".concat(currentThumbSize, "px"),
        left: getThumbLeftPosition(currentThumbSize),
        background: thumbColor,
    });
    if (thumbDisplay === SliderThumbDisplay.ALWAYS) {
        Object.assign(thumbStyle, {
            width: "".concat(currentThumbSize, "px"),
            height: "".concat(currentThumbSize, "px"),
            left: getThumbLeftPosition(currentThumbSize),
            opacity: 1,
        });
    }
    if (transitionDuration !== null && transitionDuration !== void 0 ? transitionDuration : DEFAULT_TRANSITION_DURATION >= 0) {
        Object.assign(trackStyle, {
            transition: "width ".concat(transitionDuration, "ms linear"),
        });
        Object.assign(thumbStyle, {
            transition: "left ".concat(transitionDuration, "ms linear"),
        });
    }
    var currentContentStyle = {};
    if (mode === SliderMode.HORIZONTAL_REVERSE) {
        currentContentStyle.transform = 'scale(-1, 1)';
        currentContentStyle.transformOrigin = '50% 50%';
    }
    else if (mode === SliderMode.VERTICAL) {
        currentContentStyle.width = "".concat(elementSize.height, "px");
        currentContentStyle.height = "".concat(elementSize.width, "px");
        currentContentStyle.transform = 'rotate(-90deg) translate(-100%, 0)';
        currentContentStyle.transformOrigin = '0px 0px';
    }
    else if (mode === SliderMode.VERTICAL_REVERSE) {
        currentContentStyle.width = "".concat(elementSize.height, "px");
        currentContentStyle.height = "".concat(elementSize.width, "px");
        currentContentStyle.transform = 'rotate(90deg) translate(0, -100%)';
        currentContentStyle.transformOrigin = '0px 0px';
    }
    var focusing = function (focus) {
        if (focusRef.current === focus)
            return;
        focusRef.current = focus;
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(focus);
    };
    var classes = ['custom_slider-container'];
    if (className)
        classes.push(className);
    var render = function () { return (React.createElement("div", { style: style, className: classes.join(' '), ref: containerRef },
        React.createElement("div", { style: currentContentStyle, className: "custom_slider-content" },
            !native ? React.createElement("div", { style: backgroundStyle, className: "custom_slider-background-input" }) : null,
            !native ? React.createElement("div", { style: trackStyle, className: "custom_slider-track-input", ref: trackRef }) : null,
            !native ? React.createElement("div", { style: thumbStyle, className: thumbClasses.join(' '), ref: thumbRef }) : null,
            React.createElement("input", { type: "range", min: min, max: max, value: currentValueRef.current, onChange: function (event) {
                    focusing(true);
                    var v = Number(event.target.value);
                    currentValueRef.current = v;
                    if (trackRef.current)
                        trackRef.current.style.width = getTrackWidth();
                    if (thumbRef.current)
                        thumbRef.current.style.left = getThumbLeftPosition(currentThumbSize);
                    onValue === null || onValue === void 0 ? void 0 : onValue(v);
                }, onMouseDown: function () { return focusing(true); }, onMouseUp: function () { return focusing(false); }, onMouseLeave: function () { return focusing(false); }, onTouchStart: function () { return focusing(true); }, onTouchStartCapture: function () { return focusing(true); }, onTouchEnd: function () { return focusing(false); }, onTouchCancel: function () { return focusing(false); }, style: { width: '100%' }, className: "custom_slider" })))); };
    return render();
};
Slider.defaultProps = {
    enable: true,
    min: DEFAULT_MIN,
    max: DEFAULT_MAX,
    value: DEFAULT_VALUE,
    minimumTrackColor: DEFAULT_MIN_TRACK_COLOR,
    maximumTrackColor: DEFAULT_MAX_TRACK_COLOR,
    thumbColor: DEFAULT_THUMB_COLOR,
    thumbSize: DEFAULT_THUMB_SIZE,
    thumbDisplay: DEFAULT_SLIDER_THUMB_DISPLAY,
    transitionDuration: DEFAULT_TRANSITION_DURATION,
    mode: DEFAULT_SLIDER_MODE,
    onValue: undefined,
    onFocus: undefined,
    native: false,
    className: undefined,
    style: undefined,
};
export default Slider;
