import React, { useImperativeHandle, useRef } from 'react';
import { Touchable } from './Touchable';
import './TouchableImage.css';
var defaultAdditionalStylePressed = {
    opacity: 0.5,
};
export var TouchableImage = React.forwardRef(function (_a, forwardRef) {
    var src = _a.src, alt = _a.alt, onPress = _a.onPress, onPressIn = _a.onPressIn, onPressOut = _a.onPressOut, additionalStylePressed = _a.additionalStylePressed, className = _a.className, style = _a.style, imageStyle = _a.imageStyle;
    var innerRef = useRef();
    var getHTMLElement = function () { var _a; return (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.getHTMLElement(); };
    useImperativeHandle(forwardRef, function () { return ({
        getHTMLElement: getHTMLElement,
    }); });
    var classes = ['touchable_image-container '];
    if (className)
        classes.push(className);
    return (React.createElement(Touchable, { style: style, className: className, onPress: onPress, onPressIn: onPressIn, onPressOut: onPressOut, additionalStylePressed: additionalStylePressed },
        React.createElement("img", { style: imageStyle, className: "touchable_image-image", src: src, alt: alt })));
});
TouchableImage.defaultProps = {
    className: undefined,
    imageStyle: undefined,
    style: undefined,
    additionalStylePressed: defaultAdditionalStylePressed,
    onPress: undefined,
    onPressIn: undefined,
    onPressOut: undefined,
};
export default TouchableImage;
