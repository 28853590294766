import { __assign, __extends, __read } from "tslib";
import { AntmediaWebRTCAdaptorCallbackType, AntmediaWebRTCAdaptorErrorCallbackType, } from "./interface";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import { BaseWebRTCSession, defaultWebRTCSessionOptions, WebRTCSessionState, } from "../session/session";
import { ANTMEDIA_DEBUG_MODE, ANTMEDIA_PEERCONNECTION_CONFIG, ANTMEDIA_WEBRTC_ADAPTOR_CONSTRAINS, ANTMEDIA_WEBRTC_CONNECTION_TIMEOUT, AntmediaWebRTCTimeoutType, AntmediaWebRTCError, } from "./constant";
import { convertSrcAntmediaToWebSocketUrl } from "../service/utils";
var ENABLE_PING_PONG = true;
;
// Overide function because there is an error in the original prototy !
// eslint-disable-next-line func-names
WebRTCAdaptor.prototype.onTrack = function (event, streamId) {
    var data = {
        stream: event.streams[0],
        track: event.track,
        streamId: streamId,
    };
    this.callback('newStreamAvailable', data);
};
export var defaultAntmediaWebRTCSessionOptions = __assign(__assign({}, defaultWebRTCSessionOptions), { enableStats: true });
var AntmediaWebRTCSession = /** @class */ (function (_super) {
    __extends(AntmediaWebRTCSession, _super);
    function AntmediaWebRTCSession(options) {
        var _this = _super.call(this, __assign(__assign({}, defaultAntmediaWebRTCSessionOptions), (options !== null && options !== void 0 ? options : {}))) || this;
        // TIMEOUT
        _this.iceConnected = false;
        _this.state = WebRTCSessionState.CLOSED;
        return _this;
    }
    AntmediaWebRTCSession.prototype.startSession = function (src, conf) {
        var _a, _b, _c;
        if (this.webRTCAdaptor)
            this.close();
        var _d = __read(convertSrcAntmediaToWebSocketUrl(src), 2), websocketUrl = _d[0], streamid = _d[1];
        this.streamId = streamid;
        this.setConnectionTimeout();
        this.state = WebRTCSessionState.LOADING;
        var initValues = {
            websocket_url: websocketUrl,
            mediaConstraints: (_a = conf === null || conf === void 0 ? void 0 : conf.mediaConstraints) !== null && _a !== void 0 ? _a : ANTMEDIA_WEBRTC_ADAPTOR_CONSTRAINS.mediaConstraints,
            sdp_constraints: (_b = conf === null || conf === void 0 ? void 0 : conf.sdp_constraints) !== null && _b !== void 0 ? _b : ANTMEDIA_WEBRTC_ADAPTOR_CONSTRAINS.sdpConstraints,
            dataChannelEnabled: (_c = conf === null || conf === void 0 ? void 0 : conf.dataChannelEnabled) !== null && _c !== void 0 ? _c : false,
            localVideoId: conf === null || conf === void 0 ? void 0 : conf.localVideoId,
            bandwidth: conf === null || conf === void 0 ? void 0 : conf.bandwidth,
            isPlayMode: true,
            debug: ANTMEDIA_DEBUG_MODE,
            peerconnection_config: ANTMEDIA_PEERCONNECTION_CONFIG,
            callback: this.callback.bind(this),
            callbackError: this.errorCallback.bind(this),
        };
        this.webRTCAdaptor = new WebRTCAdaptor(initValues);
    };
    AntmediaWebRTCSession.prototype.close = function () {
        var _a, _b, _c, _d;
        if (!this.webRTCAdaptor) {
            this.state = WebRTCSessionState.CLOSED;
            this.triggersCloseListeners();
            return;
        }
        if (this.state === WebRTCSessionState.CLOSED) {
            this.triggersCloseListeners();
            return;
        }
        this.state = WebRTCSessionState.CLOSED;
        this.clearConnectionTimeout();
        try {
            if (this.streamId)
                this.webRTCAdaptor.disableStats(this.streamId);
        }
        catch (err) {
            (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'error disable stats : ', err);
        }
        try {
            if (this.streamId)
                this.webRTCAdaptor.stop(this.streamId);
        }
        catch (err) {
            (_b = this.log) === null || _b === void 0 ? void 0 : _b.call(this, 'error stop : ', err);
        }
        try {
            this.webRTCAdaptor.closeWebSocket();
        }
        catch (err) {
            (_c = this.log) === null || _c === void 0 ? void 0 : _c.call(this, 'error close websocket : ', err);
        }
        try {
            this.webRTCAdaptor.closeStream();
        }
        catch (err) {
            (_d = this.log) === null || _d === void 0 ? void 0 : _d.call(this, 'error close stream : ', err);
        }
        this.triggersCloseListeners();
        this.webRTCAdaptor = undefined;
    };
    AntmediaWebRTCSession.prototype.stop = function () {
        this.close();
    };
    AntmediaWebRTCSession.prototype.getRTCPeerConnection = function () {
        var _a, _b;
        if (!this.streamId)
            return undefined;
        return (_b = (_a = this.webRTCAdaptor) === null || _a === void 0 ? void 0 : _a.remotePeerConnection) === null || _b === void 0 ? void 0 : _b[this.streamId];
    };
    AntmediaWebRTCSession.prototype.stopPingPong = function () {
        var _a, _b;
        var pingTimerId = (_b = (_a = this.webRTCAdaptor) === null || _a === void 0 ? void 0 : _a.webSocketAdaptor) === null || _b === void 0 ? void 0 : _b.pingTimerId;
        if (pingTimerId)
            clearInterval(pingTimerId);
    };
    ;
    // ---------------- TIMEOUTS / INTERVALS -------------- //
    AntmediaWebRTCSession.prototype.clearConnectionTimeout = function () {
        if (this.connectionTimeout)
            clearTimeout(this.connectionTimeout);
        this.connectionTimeout = undefined;
    };
    ;
    AntmediaWebRTCSession.prototype.setConnectionTimeout = function () {
        var _this = this;
        this.clearConnectionTimeout();
        this.connectionTimeout = setTimeout(function () {
            _this.clearConnectionTimeout();
            _this.triggersTimeoutListeners(AntmediaWebRTCTimeoutType.CONNECTION);
        }, ANTMEDIA_WEBRTC_CONNECTION_TIMEOUT);
    };
    ;
    // ---------------- CALLBACKS -------------- //
    AntmediaWebRTCSession.prototype.callback = function (info, description) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        if (info === AntmediaWebRTCAdaptorCallbackType.INITIALIZED) {
            this.state = WebRTCSessionState.RUNNING;
            (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'initialized');
            this.iceConnected = false;
            if (!ENABLE_PING_PONG)
                this.stopPingPong();
            if (this.streamId)
                (_b = this.webRTCAdaptor) === null || _b === void 0 ? void 0 : _b.getStreamInfo(this.streamId);
            this.clearConnectionTimeout();
            this.triggersInitializedListeners();
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.STREAM_INFO) {
            (_c = this.log) === null || _c === void 0 ? void 0 : _c.call(this, 'stream information');
            var data = description;
            if (((_d = this.options) === null || _d === void 0 ? void 0 : _d.enableStats) && this.streamId) {
                (_e = this.webRTCAdaptor) === null || _e === void 0 ? void 0 : _e.enableStats(this.streamId);
            }
            this.triggersStreamExistListeners(true, data);
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.PLAY_START) {
            (_f = this.log) === null || _f === void 0 ? void 0 : _f.call(this, 'play started');
            this.triggersPlayListeners(true);
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.PLAY_FINISHED) {
            (_g = this.log) === null || _g === void 0 ? void 0 : _g.call(this, 'play finished');
            if (((_h = this.options) === null || _h === void 0 ? void 0 : _h.enableStats) && this.streamId) {
                (_j = this.webRTCAdaptor) === null || _j === void 0 ? void 0 : _j.disableStats(this.streamId);
            }
            this.triggersPlayListeners(false);
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.CLOSE) {
            this.close();
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.BITRATE_MEASUREMENT) {
            var data = {
                audioBitrate: description.audioBitrate,
                videoBitrate: description.videoBitrate,
                targetBitrate: description.targetBitrate,
                totalBitrate: ((_k = description.audioBitrate) !== null && _k !== void 0 ? _k : 0) + ((_l = description.videoBitrate) !== null && _l !== void 0 ? _l : 0),
            };
            this.triggersBitrateListeners(data);
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.ICE_CONNECTION_STATE_CHANGED) {
            (_m = this.log) === null || _m === void 0 ? void 0 : _m.call(this, "ice connection state changed to ".concat(description.state));
            if (description.state === 'connected' || description.state === 'completed') {
                // it means the ice connection has been established
                this.iceConnected = true;
            }
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.NEW_STREAM_AVAILABLE) {
            var data = description;
            (_o = this.log) === null || _o === void 0 ? void 0 : _o.call(this, 'On track', data);
            (_p = this.onTrack) === null || _p === void 0 ? void 0 : _p.call(this, data);
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.UPDATE_STATS) {
            var data = __assign(__assign({}, description), { averageOutgoingBitrate: description.averageOutgoingBitrate, currentFPS: Number(description.currentFPS), averageIncomingBitrate: description.averageIncomingBitrate, currentOutgoingBitrate: description.currentOutgoingBitrate, currentIncomingBitrate: description.currentIncomingBitrate });
            this.triggersAntmediaStatsListeners(data);
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.HIGH_RESOURCE_USAGE) {
            this.triggersErrorListeners(AntmediaWebRTCError.HIGH_RESOURCE_USAGE);
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.PUBLISH_STARTED) {
            this.triggersPublishListeners(true);
        }
        else if (info === AntmediaWebRTCAdaptorCallbackType.PUBLISH_FINISHED) {
            this.triggersPublishListeners(true);
        }
    };
    AntmediaWebRTCSession.prototype.errorCallback = function (error) {
        var _a;
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, "error callback: ".concat(JSON.stringify(error)));
        if (error === AntmediaWebRTCAdaptorErrorCallbackType.NO_STREAM_EXIST) {
            this.clearConnectionTimeout();
            this.triggersStreamExistListeners(false);
        }
        else if (error === AntmediaWebRTCAdaptorErrorCallbackType.NOT_SET_REMOTE_DESCRIPTION) {
            /*
            * If getting codec incompatible or remote description error, it will redirect HLS player.
            */
            this.clearConnectionTimeout();
            this.triggersIncompatibleWebrtcListeners();
        }
        else if (error === AntmediaWebRTCAdaptorErrorCallbackType.WEBSOCKET_NOT_CONNECTED) {
            this.close();
        }
    };
    ;
    // ------------------- Listeners ----------------- //
    AntmediaWebRTCSession.prototype.triggersPlayListeners = function (play) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'play : ', this.state);
        (_b = this.listeners.play) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(play); });
    };
    AntmediaWebRTCSession.prototype.triggersPublishListeners = function (publishing) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'publish');
        (_b = this.listeners.publish) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(publishing); });
    };
    AntmediaWebRTCSession.prototype.triggersStreamExistListeners = function (value, description) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'stream exist : ', this.state);
        (_b = this.listeners.streamExist) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(value, description); });
    };
    AntmediaWebRTCSession.prototype.triggersBitrateListeners = function (bitrate) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'bitrate : ', this.state);
        (_b = this.listeners.bitrate) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(bitrate); });
    };
    AntmediaWebRTCSession.prototype.triggersIncompatibleWebrtcListeners = function () {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'incompatibleWebrtc : ', this.state);
        (_b = this.listeners.incompatibleWebrtc) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(); });
    };
    AntmediaWebRTCSession.prototype.triggersTimeoutListeners = function (type) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'timeout');
        (_b = this.listeners.timeout) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(type); });
    };
    AntmediaWebRTCSession.prototype.triggersAntmediaStatsListeners = function (antmediaStats) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'peerStats');
        (_b = this.listeners.antmediaStats) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(antmediaStats); });
    };
    AntmediaWebRTCSession.prototype.triggersErrorListeners = function (type, data) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'error');
        (_b = this.listeners.error) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(type, data); });
    };
    AntmediaWebRTCSession.prototype.triggersCloseListeners = function () {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'close');
        (_b = this.listeners.close) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(); });
    };
    return AntmediaWebRTCSession;
}(BaseWebRTCSession));
export { AntmediaWebRTCSession };
