var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AntmediaReceiverWebRTCSession } from '@kalyzee/kast-webrtc-client-module';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { VideoDefaultParams } from '../../constants/video';
import { addToPathNameUrl } from '../../services/utils';
import { Video } from './Video';
import './VideoAntmedia.css';
var DEFAULT_RETRY_DELAY = 3000;
export var VideoAntmediaMode;
(function (VideoAntmediaMode) {
    VideoAntmediaMode["MIXED"] = "mixed";
    VideoAntmediaMode["HLS"] = "hls";
    VideoAntmediaMode["WEBRTC"] = "webrtc";
})(VideoAntmediaMode || (VideoAntmediaMode = {}));
export var VideoAntmediaState;
(function (VideoAntmediaState) {
    VideoAntmediaState["HLS"] = "hls";
    VideoAntmediaState["HLS_ADAPTIVE"] = "hls_adaptive";
    VideoAntmediaState["WEBRTC"] = "webrtc";
    VideoAntmediaState["NO_STREAM"] = "no_stream";
})(VideoAntmediaState || (VideoAntmediaState = {}));
export var VideoAntmedia = React.forwardRef(function (_a, forwardRef) {
    var mode = _a.mode, antmediaBaseUrl = _a.antmediaBaseUrl, antmediaApplication = _a.antmediaApplication, antmediaId = _a.antmediaId, adaptiveHls = _a.adaptiveHls, mute = _a.mute, volume = _a.volume, play = _a.play, autoPlay = _a.autoPlay, onMute = _a.onMute, onVolume = _a.onVolume, onPlay = _a.onPlay, onError = _a.onError, onEnd = _a.onEnd, loop = _a.loop, controls = _a.controls, onReady = _a.onReady, onStats = _a.onStats, onNetwork = _a.onNetwork, onState = _a.onState, onMediaStream = _a.onMediaStream, className = _a.className, style = _a.style;
    var _b = useState(), media = _b[0], setMedia = _b[1];
    var _c = useState(), src = _c[0], setSrc = _c[1];
    var _d = useState(), currentState = _d[0], setCurrentState = _d[1];
    var _e = useState(undefined), nextState = _e[0], setNextState = _e[1];
    var _f = useState(), webrtcSession = _f[0], setWebrtcSession = _f[1];
    var videoRef = useRef();
    var componentIsAlivedRef = useRef(true);
    var callbacksRef = useRef({});
    var statsRef = useRef({});
    var getNextState = function (state) {
        var next;
        if (state === undefined || state === VideoAntmediaState.NO_STREAM) {
            if (mode === VideoAntmediaMode.MIXED || mode === VideoAntmediaMode.WEBRTC) {
                next = VideoAntmediaState.WEBRTC;
            }
            else if (mode === VideoAntmediaMode.HLS) {
                next = VideoAntmediaState.HLS;
            }
        }
        else if (state === VideoAntmediaState.WEBRTC) {
            if (mode === VideoAntmediaMode.MIXED || mode === VideoAntmediaMode.HLS) {
                next = VideoAntmediaState.HLS;
            }
            else if (mode === VideoAntmediaMode.WEBRTC) {
                next = VideoAntmediaState.WEBRTC;
            }
        }
        else if (state === VideoAntmediaState.HLS) {
            if (mode === VideoAntmediaMode.MIXED || mode === VideoAntmediaMode.HLS) {
                if (adaptiveHls)
                    next = VideoAntmediaState.HLS_ADAPTIVE;
                else
                    next = VideoAntmediaState.WEBRTC;
            }
            else if (mode === VideoAntmediaMode.WEBRTC) {
                next = VideoAntmediaState.WEBRTC;
            }
        }
        else if (state === VideoAntmediaState.HLS_ADAPTIVE) {
            if (mode === VideoAntmediaMode.MIXED || mode === VideoAntmediaMode.WEBRTC) {
                next = VideoAntmediaState.WEBRTC;
            }
            else if (mode === VideoAntmediaMode.HLS) {
                next = VideoAntmediaState.HLS;
            }
        }
        return next;
    };
    var sendStats = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var stats;
        return __generator(this, function (_a) {
            if (currentState === undefined || !onStats)
                return [2 /*return*/];
            stats = { state: currentState };
            if (currentState === VideoAntmediaState.WEBRTC) {
                stats.webrtc = statsRef.current.webrtc;
            }
            else if (currentState === VideoAntmediaState.HLS
                || currentState === VideoAntmediaState.HLS_ADAPTIVE) {
                stats.hls = statsRef.current.hls;
            }
            onStats(stats);
            return [2 /*return*/];
        });
    }); }, [currentState, onStats]);
    var createNewWebrtcConnection = function () {
        if (!componentIsAlivedRef.current)
            return;
        var options = {
            enableRtcStats: true,
            enableStats: true,
            retryAfter: DEFAULT_RETRY_DELAY,
            autoRetry: true,
        };
        var session = new AntmediaReceiverWebRTCSession(options);
        setWebrtcSession(session);
    };
    // ------------ LISTENERS -------------- //
    callbacksRef.current.play = useCallback(function (playing) {
        if (playing) {
            setNextState(undefined);
            setCurrentState(VideoAntmediaState.WEBRTC);
            onReady === null || onReady === void 0 ? void 0 : onReady(true);
        }
        else if (currentState === VideoAntmediaState.WEBRTC) {
            onReady === null || onReady === void 0 ? void 0 : onReady(false);
        }
    }, [currentState, setCurrentState, setNextState, onReady]);
    callbacksRef.current.streamExist = useCallback(function (exist, description) {
        if (exist) {
            if (currentState === VideoAntmediaState.NO_STREAM) {
                var next = getNextState(currentState);
                if (next)
                    setNextState(next);
            }
            if (description) {
                if (!statsRef.current.webrtc)
                    statsRef.current.webrtc = {};
                statsRef.current.webrtc.resolutions = description.streamInfo;
            }
        }
        else {
            setNextState(undefined);
            setCurrentState(VideoAntmediaState.NO_STREAM);
        }
    }, [currentState, setCurrentState, setNextState, statsRef]);
    callbacksRef.current.bitrate = useCallback(function (bitrate) {
        statsRef.current.webrtc = Object.assign(statsRef.current.webrtc || {}, bitrate);
        sendStats();
    }, [statsRef, currentState, sendStats, onNetwork]);
    callbacksRef.current.networkLevel = useCallback(function (networkLevel) {
        if (!statsRef.current.webrtc)
            statsRef.current.webrtc = {};
        statsRef.current.webrtc.networkLevel = networkLevel;
        onNetwork === null || onNetwork === void 0 ? void 0 : onNetwork(networkLevel);
        sendStats();
    }, [statsRef, currentState, sendStats, onNetwork]);
    callbacksRef.current.stats = useCallback(function (_connection, track, stats) {
        if (!stats)
            return;
        if ((track === null || track === void 0 ? void 0 : track.kind) !== 'video')
            return;
        statsRef.current.webrtc = Object.assign(statsRef.current.webrtc || {}, {
            rtcPeerConnection: stats,
        });
        sendStats();
    }, [statsRef, currentState, sendStats]);
    callbacksRef.current.resolution = useCallback(function (info) {
        statsRef.current.webrtc = Object.assign(statsRef.current.webrtc || {}, info);
        if (!statsRef.current.webrtc)
            statsRef.current.webrtc = {};
        statsRef.current.webrtc.resolution = info;
        sendStats();
    }, [statsRef, currentState, sendStats]);
    callbacksRef.current.incompatibleWebrtc = useCallback(function () {
        if (currentState !== VideoAntmediaState.WEBRTC)
            return;
        var next = getNextState(currentState);
        if (next)
            setNextState(next);
    }, [currentState, setNextState]);
    callbacksRef.current.timeout = useCallback(function (_type) {
        if (currentState !== VideoAntmediaState.WEBRTC)
            return;
        var next = getNextState(currentState);
        if (next)
            setNextState(next);
    }, [currentState, setNextState]);
    callbacksRef.current.stream = useCallback(function (stream) {
        setNextState(undefined);
        setCurrentState(VideoAntmediaState.WEBRTC); // PRIORITY TO WEBRTC
        setMedia(stream);
    }, [currentState, setCurrentState, setNextState, setMedia]);
    callbacksRef.current.antmediaStats = useCallback(function (stats) {
        statsRef.current.webrtc = Object.assign(statsRef.current.webrtc || {}, stats);
        sendStats();
    }, [statsRef, currentState, sendStats]);
    callbacksRef.current.error = useCallback(function (_type) {
        if (currentState !== VideoAntmediaState.WEBRTC)
            return;
        var next = getNextState(currentState);
        if (next)
            setNextState(next);
    }, [currentState, setNextState]);
    // ----------------------
    // ------- VIDEO CALLBACKS
    // ----------------------
    var videoCallbackOnError = useCallback(function () {
        var next = getNextState(currentState);
        if (next)
            setNextState(next);
        onError === null || onError === void 0 ? void 0 : onError();
    }, [currentState, setNextState, onError]);
    var videoCallbackOnEnded = useCallback(function () {
        var next = getNextState(currentState);
        if (next)
            setNextState(next);
        onEnd === null || onEnd === void 0 ? void 0 : onEnd();
    }, [currentState, setNextState, onEnd]);
    var videoCallbackOnPlay = useCallback(function (playing) {
        if (playing)
            onReady === null || onReady === void 0 ? void 0 : onReady(true);
        onPlay === null || onPlay === void 0 ? void 0 : onPlay(playing);
    }, [onReady, onPlay]);
    // ----------------------
    var getMediaStream = function () { return media; };
    var getRTCPeerConnection = function () { return webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.getRTCPeerConnection(); };
    var getRTCPeerConnectionStats = function (track) { return __awaiter(void 0, void 0, void 0, function () {
        var rtcPeerConnection, stats;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    rtcPeerConnection = getRTCPeerConnection();
                    if (!rtcPeerConnection)
                        return [2 /*return*/, undefined];
                    return [4 /*yield*/, rtcPeerConnection.getStats(track)];
                case 1:
                    stats = _a.sent();
                    return [2 /*return*/, stats];
            }
        });
    }); };
    var reconnect = function () {
        if (mode === VideoAntmediaMode.MIXED || mode === VideoAntmediaMode.WEBRTC) {
            createNewWebrtcConnection();
        }
        setCurrentState(currentState);
    };
    useImperativeHandle(forwardRef, function () { return ({
        reconnect: reconnect,
        getVideoRef: function () { return videoRef.current; },
        getState: function () { return currentState; },
        getStats: function () { return statsRef.current; },
        getHls: function () { var _a; return (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHls(); },
        getMediaStream: getMediaStream,
        getRTCPeerConnection: getRTCPeerConnection,
        getRTCPeerConnectionStats: getRTCPeerConnectionStats,
        getHTMLVideoElement: function () { var _a; return (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHTMLVideoElement(); },
    }); }, [webrtcSession, videoRef, currentState, statsRef]);
    useEffect(function () {
        componentIsAlivedRef.current = true;
        return function () {
            componentIsAlivedRef.current = false;
            webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
        };
    }, []);
    useEffect(function () {
        if (media)
            onMediaStream === null || onMediaStream === void 0 ? void 0 : onMediaStream(media, webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.getRTCPeerConnection());
    }, [media, webrtcSession]);
    useEffect(function () {
        if (mode === VideoAntmediaMode.MIXED || mode === VideoAntmediaMode.WEBRTC) {
            setNextState(undefined);
            setCurrentState(VideoAntmediaState.WEBRTC);
            createNewWebrtcConnection();
        }
        else if (mode === VideoAntmediaMode.HLS) {
            setNextState(undefined);
            setCurrentState(VideoAntmediaState.HLS);
            webrtcSession === null || webrtcSession === void 0 ? void 0 : webrtcSession.destroy();
        }
    }, [mode, antmediaApplication, antmediaBaseUrl, antmediaId]);
    useEffect(function () {
        var timeout;
        if (nextState) {
            onReady === null || onReady === void 0 ? void 0 : onReady(false);
            timeout = setTimeout(function () {
                setCurrentState(nextState);
                setNextState(undefined);
            }, DEFAULT_RETRY_DELAY);
        }
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    }, [nextState]);
    useEffect(function () {
        if (nextState)
            return;
        if (currentState === VideoAntmediaState.WEBRTC) {
            // Do nothing, auto retry is implemented in session
        }
        else if (currentState === VideoAntmediaState.HLS) {
            var m3u8FileName = "".concat(antmediaId, ".m3u8");
            var m3u8Url = addToPathNameUrl(antmediaBaseUrl, antmediaApplication, 'streams', m3u8FileName);
            setSrc(m3u8Url);
        }
        else if (currentState === VideoAntmediaState.HLS_ADAPTIVE) {
            var m3u8FileName = "".concat(antmediaId, "_adaptive.m3u8");
            var m3u8Url = addToPathNameUrl(antmediaBaseUrl, antmediaApplication, 'streams', m3u8FileName);
            setSrc(m3u8Url);
        }
        else if (currentState === VideoAntmediaState.NO_STREAM) {
            onReady === null || onReady === void 0 ? void 0 : onReady(false);
        }
    }, [currentState, nextState]);
    useEffect(function () {
        if (!currentState)
            return;
        statsRef.current.state = currentState;
        sendStats();
        onState === null || onState === void 0 ? void 0 : onState(currentState);
    }, [currentState, sendStats]);
    useEffect(function () {
        var current = webrtcSession;
        var listeners = {};
        if (current) {
            var events = Object.keys(callbacksRef.current);
            events.forEach(function (event) {
                var listener = callbacksRef.current[event];
                listeners[event] = listener;
                current.addEventListener(event, listener);
            });
            var url = {
                url: addToPathNameUrl(antmediaBaseUrl, antmediaApplication, 'websocket'),
                streamId: antmediaId,
            };
            current.start(url);
        }
        return function () {
            statsRef.current.webrtc = {};
            if (current) {
                var events = Object.keys(listeners);
                events.forEach(function (event) {
                    var listener = listeners[event];
                    current.removeEventListener(event, listener);
                });
                current.destroy();
            }
        };
    }, [webrtcSession]);
    var render = function () { return (React.createElement(Video, { ref: videoRef, style: style, className: className, media: currentState === VideoAntmediaState.WEBRTC ? media : undefined, src: (currentState === VideoAntmediaState.HLS || currentState === VideoAntmediaState.HLS_ADAPTIVE) ? src : undefined, srcIsHls: true, volume: volume, mute: mute, play: play, autoPlay: autoPlay, loop: loop, controls: controls, onHlsStats: function (stats) {
            statsRef.current.hls = stats;
            sendStats();
        }, onMute: onMute, onVolume: onVolume, onPlay: videoCallbackOnPlay, onError: videoCallbackOnError, onEnd: videoCallbackOnEnded })); };
    return render();
});
VideoAntmedia.defaultProps = {
    mode: VideoAntmediaMode.MIXED,
    adaptiveHls: true,
    mute: VideoDefaultParams.mute,
    volume: VideoDefaultParams.volume,
    play: VideoDefaultParams.play,
    loop: VideoDefaultParams.loop,
    controls: VideoDefaultParams.controls,
    onMute: undefined,
    onVolume: undefined,
    onPlay: undefined,
    onReady: undefined,
    className: undefined,
    style: undefined,
};
export default VideoAntmedia;
