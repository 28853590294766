var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Touchable } from '../util/Touchable';
import Loading from '../../../assets/icons/loading.svg';
import Refresh from '../../../assets/icons/refresh.svg';
import { VideoState } from '../../constants/video';
import './VideoOverlay.css';
var DISPLAY_REFRESH_AFTER_DELAY = 20 * 1000; // 20 secondes
var DEFAULT_MESSAGES = {
    loadingMessage: 'Loading',
    errorMessage: 'An error has appeared ...',
    errorButton: 'Retry',
};
export var VideoOverlay = function (_a) {
    var state = _a.state, messages = _a.messages, children = _a.children, onRetry = _a.onRetry, className = _a.className, style = _a.style;
    var _b = useState(false), displayRefresh = _b[0], setDisplayRefresh = _b[1];
    var retry = function () { return onRetry === null || onRetry === void 0 ? void 0 : onRetry(); };
    useEffect(function () {
        var timeout;
        if (state === VideoState.LOADING) {
            timeout = setTimeout(function () {
                setDisplayRefresh(true);
            }, DISPLAY_REFRESH_AFTER_DELAY);
        }
        else {
            setDisplayRefresh(false);
        }
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    }, [state]);
    var renderRunning = function () {
        var msg = __assign({}, (messages || DEFAULT_MESSAGES));
        var result = children === null || children === void 0 ? void 0 : children(VideoState.RUNNING, retry, msg);
        var defaultNode = null;
        if (result === undefined)
            result = defaultNode;
        return result;
    };
    var renderLoading = function () {
        var msg = __assign({}, (messages || DEFAULT_MESSAGES));
        var result = children === null || children === void 0 ? void 0 : children(VideoState.LOADING, retry, msg);
        var defaultNode = (React.createElement("div", { className: "video_overlay-content video_overlay-loading" },
            msg.loadingMessage,
            React.createElement("img", { src: Loading, alt: "loading", className: "video_overlay-loading-image" }),
            displayRefresh ? (React.createElement(Touchable, { onPress: retry, className: "video_overlay-loading-refresh-button" },
                React.createElement("img", { src: Refresh, alt: "refresh", className: "video_overlay-loading-refresh-icon" }))) : null));
        if (result === undefined)
            result = defaultNode;
        return result;
    };
    var renderStopped = function () {
        var msg = __assign({}, (messages || DEFAULT_MESSAGES));
        var result = children === null || children === void 0 ? void 0 : children(VideoState.STOPPED, retry, msg);
        var defaultNode = (React.createElement("div", { className: "video_overlay-content video_overlay-error" },
            msg.errorMessage,
            React.createElement(Touchable, { onPress: retry, className: "video_overlay-error-button" }, msg.errorButton)));
        if (result === undefined)
            result = defaultNode;
        return result;
    };
    var render = function () {
        if (state === VideoState.RUNNING)
            return renderRunning();
        if (state === VideoState.LOADING)
            return renderLoading();
        if (state === VideoState.STOPPED)
            return renderStopped();
        return null;
    };
    var classes = ['video_overlay-container'];
    if (className)
        classes.push(className);
    return (React.createElement("div", { className: classes.join(' '), style: style }, render()));
};
VideoOverlay.defaultProps = {
    className: undefined,
    style: undefined,
    onRetry: undefined,
    children: undefined,
    messages: DEFAULT_MESSAGES,
};
export default VideoOverlay;
