var _a;
import { SocketActionType } from "../..";
import { formatDeviceState } from "../../service";
var getDevicesMiddleware = function (data) {
    if (data.content) {
        data.content.forEach(function (state, index) {
            data.content[index] = formatDeviceState(state);
        });
    }
    return data;
};
export var organizationMiddlewares = {
    receive: (_a = {},
        _a[SocketActionType.ORGANIZATION_DEVICES] = getDevicesMiddleware,
        _a)
};
