export var VideoState;
(function (VideoState) {
    VideoState["LOADING"] = "loading";
    VideoState["RUNNING"] = "running";
    VideoState["STOPPED"] = "stopped";
})(VideoState || (VideoState = {}));
export var VideoDefaultParams = {
    mute: true,
    volume: 1,
    play: false,
    loop: true,
    controls: false,
    autoPlay: true,
    currentTime: 0,
};
