import { __assign, __awaiter, __extends, __generator } from "tslib";
import { convertSrcKalyzeeToWebsocketUrl } from "../service/utils";
import { defaultWebRTCSessionOptions } from "../session/session";
import { WebRTCSession } from "../session/webrtc";
import { KalyzeeSessionMode, KalyzeeSessionRole, KalyzeeSessionType } from "./constant";
import { KalyzeeSocketSession } from "./socket";
;
export var defaultKalyzeeWebRTCSessionOptions = __assign({}, defaultWebRTCSessionOptions);
var KalyzeeBaseWebRTCSession = /** @class */ (function (_super) {
    __extends(KalyzeeBaseWebRTCSession, _super);
    function KalyzeeBaseWebRTCSession(mode, options) {
        var _this = _super.call(this, new KalyzeeSocketSession(), __assign(__assign({}, defaultKalyzeeWebRTCSessionOptions), (options !== null && options !== void 0 ? options : {}))) || this;
        _this._mode = mode;
        _this.socketSession.addEventListener('generateTokenUnauthorized', function (error) {
            _this.triggersGenerateTokenUnauthorizedListeners(error);
        });
        _this.socketSession.addEventListener('generateTokenNotFound', function (error) {
            _this.triggersGenerateTokenNotFoundListeners(error);
        });
        _this.socketSession.addEventListener('generateTokenError', function (error) {
            _this.triggersGenerateTokenErrorListeners(error);
        });
        _this.socketSession.addEventListener('streamWaiting', function () {
            if (!_this.localStream) {
                _this.close('Can\'t publish stream. Stream is undefined...');
                return;
            }
            _this.addStream(_this.localStream);
        });
        _this.onStream = function (stream) {
            _this.triggersStreamListeners(stream);
        };
        return _this;
    }
    Object.defineProperty(KalyzeeBaseWebRTCSession.prototype, "mode", {
        get: function () { return this._mode; },
        enumerable: false,
        configurable: true
    });
    ;
    Object.defineProperty(KalyzeeBaseWebRTCSession.prototype, "metadata", {
        get: function () { return this._metadata; },
        enumerable: false,
        configurable: true
    });
    ;
    Object.defineProperty(KalyzeeBaseWebRTCSession.prototype, "sessionId", {
        get: function () { return this._sessionId; },
        enumerable: false,
        configurable: true
    });
    ;
    // Override
    KalyzeeBaseWebRTCSession.prototype.initSocketListenerStart = function () {
        var _this = this;
        this.socketSession.addEventListener('ready', function (sessionId, metadata, iceServers) { return __awaiter(_this, void 0, void 0, function () {
            var emitStart;
            return __generator(this, function (_a) {
                if (this._mode && this._mode !== metadata.mode) {
                    this.close("Mode defined : ".concat(this._mode, " doesn't match with the webrtc session mode : ").concat(metadata.mode, " for metadata: ").concat(metadata));
                    return [2 /*return*/];
                }
                ;
                this._metadata = metadata;
                this._mode = metadata.mode;
                this.triggersMetadataListeners(metadata);
                this._sessionId = this.sessionId;
                if (metadata.role === KalyzeeSessionRole.MASTER) {
                    emitStart = true;
                    if (metadata.type === KalyzeeSessionType.DEVICE && metadata.pairType === KalyzeeSessionType.DEVICE) {
                        emitStart = false; // particular case.
                    }
                    if (emitStart)
                        this.socketSession.emitStart(iceServers); // Emit start to slave
                }
                this.triggersInitializedListeners();
                this.triggersSessionCreatedReadyListeners(sessionId);
                return [2 /*return*/];
            });
        }); });
        this.socketSession.addEventListener('start', function (iceServers, offer, candidates) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (this.rtcSession.isStarted()) {
                            (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'RTC session already started ...');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.rtcSession.start(iceServers, offer, candidates)];
                    case 1:
                        _d.sent();
                        if (!offer) {
                            if (this.mode === KalyzeeSessionMode.SENDONLY) {
                                this.socketSession.triggersStreamWaitingListeners();
                            }
                            else if (this.mode === KalyzeeSessionMode.SENDRECV) {
                                if (((_b = this.metadata) === null || _b === void 0 ? void 0 : _b.role) === KalyzeeSessionRole.SLAVE) {
                                    this.socketSession.triggersStreamWaitingListeners();
                                }
                                else if (((_c = this.metadata) === null || _c === void 0 ? void 0 : _c.role) === KalyzeeSessionRole.MASTER) {
                                    this.rtcSession.addEventListener('connect', function () {
                                        _this.socketSession.triggersStreamWaitingListeners();
                                    });
                                }
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    KalyzeeBaseWebRTCSession.prototype.startSession = function (src, stream) {
        return __awaiter(this, void 0, void 0, function () {
            var formatedUrl;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, convertSrcKalyzeeToWebsocketUrl(src, function (status, error) {
                            if (status === 'unauthorized')
                                _this.triggersGenerateTokenUnauthorizedListeners(error);
                            else if (status === 'not_found')
                                _this.triggersGenerateTokenUnauthorizedListeners(error);
                            else if (status === 'error')
                                _this.triggersGenerateTokenUnauthorizedListeners(error);
                        })];
                    case 1:
                        formatedUrl = _a.sent();
                        if (!formatedUrl)
                            return [2 /*return*/, false];
                        return [4 /*yield*/, _super.prototype.startSession.call(this, formatedUrl, stream)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // ------------------- Action ----------------- //
    KalyzeeBaseWebRTCSession.prototype.getSessionData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.socketSession.getSessionData()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // ------------------- Listeners ----------------- //
    KalyzeeBaseWebRTCSession.prototype.triggersSessionCreatedReadyListeners = function (sessionId) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'generateTokenUnauthorized');
        (_b = this.listeners.create) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(sessionId); });
    };
    KalyzeeBaseWebRTCSession.prototype.triggersGenerateTokenUnauthorizedListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'generateTokenUnauthorized');
        (_b = this.listeners.generateTokenUnauthorized) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(error); });
    };
    KalyzeeBaseWebRTCSession.prototype.triggersGenerateTokenNotFoundListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'generateTokenNotFound');
        (_b = this.listeners.generateTokenNotFound) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(error); });
    };
    KalyzeeBaseWebRTCSession.prototype.triggersGenerateTokenErrorListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'generateTokenError');
        (_b = this.listeners.generateTokenError) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener(error); });
    };
    KalyzeeBaseWebRTCSession.prototype.triggersStreamListeners = function (stream) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'streamChange : ', stream);
        (_b = this.listeners.stream) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener === null || listener === void 0 ? void 0 : listener(stream); });
    };
    KalyzeeBaseWebRTCSession.prototype.triggersMetadataListeners = function (metadata) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'metadataChange : ', metadata);
        (_b = this.listeners.metadata) === null || _b === void 0 ? void 0 : _b.forEach(function (listener) { return listener === null || listener === void 0 ? void 0 : listener(metadata); });
    };
    return KalyzeeBaseWebRTCSession;
}(WebRTCSession));
export { KalyzeeBaseWebRTCSession };
var KalyzeeWebRTCSession = /** @class */ (function (_super) {
    __extends(KalyzeeWebRTCSession, _super);
    function KalyzeeWebRTCSession(mode, options) {
        return _super.call(this, mode, __assign(__assign({}, defaultKalyzeeWebRTCSessionOptions), (options !== null && options !== void 0 ? options : {}))) || this;
    }
    // Override but no changes for the moment
    KalyzeeWebRTCSession.prototype.start = function (src, stream) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.startSession.call(this, src, stream)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KalyzeeWebRTCSession.prototype.destroy = function (reason) {
        this.close(reason);
        this.removeAllEventListeners();
    };
    return KalyzeeWebRTCSession;
}(KalyzeeBaseWebRTCSession));
export { KalyzeeWebRTCSession };
