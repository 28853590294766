var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SocketActionType } from "../../constants";
import { SocketActionHandler } from "../../handler";
var DeviceMoveActionHandler = /** @class */ (function (_super) {
    __extends(DeviceMoveActionHandler, _super);
    function DeviceMoveActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_MOVE) || this;
    }
    return DeviceMoveActionHandler;
}(SocketActionHandler));
export { DeviceMoveActionHandler };
var DeviceZoomActionHandler = /** @class */ (function (_super) {
    __extends(DeviceZoomActionHandler, _super);
    function DeviceZoomActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_ZOOM) || this;
    }
    return DeviceZoomActionHandler;
}(SocketActionHandler));
export { DeviceZoomActionHandler };
var DeviceGetInformationActionHandler = /** @class */ (function (_super) {
    __extends(DeviceGetInformationActionHandler, _super);
    function DeviceGetInformationActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_INFORMATION) || this;
    }
    return DeviceGetInformationActionHandler;
}(SocketActionHandler));
export { DeviceGetInformationActionHandler };
var DeviceRebootActionHandler = /** @class */ (function (_super) {
    __extends(DeviceRebootActionHandler, _super);
    function DeviceRebootActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_REBOOT) || this;
    }
    return DeviceRebootActionHandler;
}(SocketActionHandler));
export { DeviceRebootActionHandler };
var DeviceFactoryResetActionHandler = /** @class */ (function (_super) {
    __extends(DeviceFactoryResetActionHandler, _super);
    function DeviceFactoryResetActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_FACTORY_RESET) || this;
    }
    return DeviceFactoryResetActionHandler;
}(SocketActionHandler));
export { DeviceFactoryResetActionHandler };
var DeviceOnStateChangeActionHandler = /** @class */ (function (_super) {
    __extends(DeviceOnStateChangeActionHandler, _super);
    function DeviceOnStateChangeActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_STATE_CHANGE) || this;
    }
    return DeviceOnStateChangeActionHandler;
}(SocketActionHandler));
export { DeviceOnStateChangeActionHandler };
var DeviceSetSystemTimeActionHandler = /** @class */ (function (_super) {
    __extends(DeviceSetSystemTimeActionHandler, _super);
    function DeviceSetSystemTimeActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_SYSTEM_SET_TIME) || this;
    }
    return DeviceSetSystemTimeActionHandler;
}(SocketActionHandler));
export { DeviceSetSystemTimeActionHandler };
var DeviceSetSystemTimezoneActionHandler = /** @class */ (function (_super) {
    __extends(DeviceSetSystemTimezoneActionHandler, _super);
    function DeviceSetSystemTimezoneActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_SYSTEM_SET_TIMEZONE) || this;
    }
    return DeviceSetSystemTimezoneActionHandler;
}(SocketActionHandler));
export { DeviceSetSystemTimezoneActionHandler };
var DeviceUpdateStartDownloadActionHandler = /** @class */ (function (_super) {
    __extends(DeviceUpdateStartDownloadActionHandler, _super);
    function DeviceUpdateStartDownloadActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_UPDATE_START_DOWNLOAD) || this;
    }
    return DeviceUpdateStartDownloadActionHandler;
}(SocketActionHandler));
export { DeviceUpdateStartDownloadActionHandler };
var DeviceUpdateScheduleSilentActionHandler = /** @class */ (function (_super) {
    __extends(DeviceUpdateScheduleSilentActionHandler, _super);
    function DeviceUpdateScheduleSilentActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_UPDATE_SCHEDULE_SILENT) || this;
    }
    return DeviceUpdateScheduleSilentActionHandler;
}(SocketActionHandler));
export { DeviceUpdateScheduleSilentActionHandler };
var DeviceUpdateInstallActionHandler = /** @class */ (function (_super) {
    __extends(DeviceUpdateInstallActionHandler, _super);
    function DeviceUpdateInstallActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_UPDATE_INSTALL) || this;
    }
    return DeviceUpdateInstallActionHandler;
}(SocketActionHandler));
export { DeviceUpdateInstallActionHandler };
var DeviceUpdateAbortActionHandler = /** @class */ (function (_super) {
    __extends(DeviceUpdateAbortActionHandler, _super);
    function DeviceUpdateAbortActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_UPDATE_ABORT) || this;
    }
    return DeviceUpdateAbortActionHandler;
}(SocketActionHandler));
export { DeviceUpdateAbortActionHandler };
var DeviceUpdateGetSessionsActionHandler = /** @class */ (function (_super) {
    __extends(DeviceUpdateGetSessionsActionHandler, _super);
    function DeviceUpdateGetSessionsActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_UPDATE_GET_SESSIONS) || this;
    }
    return DeviceUpdateGetSessionsActionHandler;
}(SocketActionHandler));
export { DeviceUpdateGetSessionsActionHandler };
var DeviceUpdateOnProgressActionHandler = /** @class */ (function (_super) {
    __extends(DeviceUpdateOnProgressActionHandler, _super);
    function DeviceUpdateOnProgressActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_UPDATE_ON_PROGRESS_DOWNLOAD) || this;
    }
    return DeviceUpdateOnProgressActionHandler;
}(SocketActionHandler));
export { DeviceUpdateOnProgressActionHandler };
var DeviceUpdateOnSessionActionHandler = /** @class */ (function (_super) {
    __extends(DeviceUpdateOnSessionActionHandler, _super);
    function DeviceUpdateOnSessionActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_UPDATE_ON_SESSION) || this;
    }
    return DeviceUpdateOnSessionActionHandler;
}(SocketActionHandler));
export { DeviceUpdateOnSessionActionHandler };
var DeviceStartLiveActionHandler = /** @class */ (function (_super) {
    __extends(DeviceStartLiveActionHandler, _super);
    function DeviceStartLiveActionHandler() {
        return _super.call(this, SocketActionType.LIVE_START) || this;
    }
    return DeviceStartLiveActionHandler;
}(SocketActionHandler));
export { DeviceStartLiveActionHandler };
var DeviceStopLiveActionHandler = /** @class */ (function (_super) {
    __extends(DeviceStopLiveActionHandler, _super);
    function DeviceStopLiveActionHandler() {
        return _super.call(this, SocketActionType.LIVE_STOP) || this;
    }
    return DeviceStopLiveActionHandler;
}(SocketActionHandler));
export { DeviceStopLiveActionHandler };
var DeviceStartRecordActionHandler = /** @class */ (function (_super) {
    __extends(DeviceStartRecordActionHandler, _super);
    function DeviceStartRecordActionHandler() {
        return _super.call(this, SocketActionType.RECORD_START) || this;
    }
    return DeviceStartRecordActionHandler;
}(SocketActionHandler));
export { DeviceStartRecordActionHandler };
var DeviceStopRecordActionHandler = /** @class */ (function (_super) {
    __extends(DeviceStopRecordActionHandler, _super);
    function DeviceStopRecordActionHandler() {
        return _super.call(this, SocketActionType.RECORD_STOP) || this;
    }
    return DeviceStopRecordActionHandler;
}(SocketActionHandler));
export { DeviceStopRecordActionHandler };
var DeviceOnRecordFailureActionHandler = /** @class */ (function (_super) {
    __extends(DeviceOnRecordFailureActionHandler, _super);
    function DeviceOnRecordFailureActionHandler() {
        return _super.call(this, SocketActionType.RECORD_FAILURE) || this;
    }
    return DeviceOnRecordFailureActionHandler;
}(SocketActionHandler));
export { DeviceOnRecordFailureActionHandler };
var DeviceGetVideoContextActionHandler = /** @class */ (function (_super) {
    __extends(DeviceGetVideoContextActionHandler, _super);
    function DeviceGetVideoContextActionHandler() {
        return _super.call(this, SocketActionType.VIDEO_CONTEXT) || this;
    }
    return DeviceGetVideoContextActionHandler;
}(SocketActionHandler));
export { DeviceGetVideoContextActionHandler };
var DeviceSwitchSceneActionHandler = /** @class */ (function (_super) {
    __extends(DeviceSwitchSceneActionHandler, _super);
    function DeviceSwitchSceneActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_SWITCH_SCENE) || this;
    }
    return DeviceSwitchSceneActionHandler;
}(SocketActionHandler));
export { DeviceSwitchSceneActionHandler };
var DeviceSwitchViewActionHandler = /** @class */ (function (_super) {
    __extends(DeviceSwitchViewActionHandler, _super);
    function DeviceSwitchViewActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_SWITCH_SCENE) || this;
    }
    return DeviceSwitchViewActionHandler;
}(SocketActionHandler));
export { DeviceSwitchViewActionHandler };
var DeviceSetViewActionHandler = /** @class */ (function (_super) {
    __extends(DeviceSetViewActionHandler, _super);
    function DeviceSetViewActionHandler() {
        return _super.call(this, SocketActionType.DEVICE_SWITCH_SCENE) || this;
    }
    return DeviceSetViewActionHandler;
}(SocketActionHandler));
export { DeviceSetViewActionHandler };
var DeviceOnVideoUploadProgressActionHandler = /** @class */ (function (_super) {
    __extends(DeviceOnVideoUploadProgressActionHandler, _super);
    function DeviceOnVideoUploadProgressActionHandler() {
        return _super.call(this, SocketActionType.VIDEO_UPLOAD_PROGRESS) || this;
    }
    return DeviceOnVideoUploadProgressActionHandler;
}(SocketActionHandler));
export { DeviceOnVideoUploadProgressActionHandler };
var DeviceOnVideoUploadSuccessActionHandler = /** @class */ (function (_super) {
    __extends(DeviceOnVideoUploadSuccessActionHandler, _super);
    function DeviceOnVideoUploadSuccessActionHandler() {
        return _super.call(this, SocketActionType.VIDEO_UPLOAD_SUCCESS) || this;
    }
    return DeviceOnVideoUploadSuccessActionHandler;
}(SocketActionHandler));
export { DeviceOnVideoUploadSuccessActionHandler };
var DeviceOnVideoUploadFailureActionHandler = /** @class */ (function (_super) {
    __extends(DeviceOnVideoUploadFailureActionHandler, _super);
    function DeviceOnVideoUploadFailureActionHandler() {
        return _super.call(this, SocketActionType.VIDEO_UPLOAD_FAILURE) || this;
    }
    return DeviceOnVideoUploadFailureActionHandler;
}(SocketActionHandler));
export { DeviceOnVideoUploadFailureActionHandler };
