var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useRef } from 'react';
export var useOuterClick = function (ref, callback) {
    // initialize mutable ref, which stores callback
    var callbackRef = useRef(undefined);
    // update cb on each render, so second useEffect has access to current value
    useEffect(function () {
        callbackRef.current = callback;
    });
    useEffect(function () {
        var handleClick = function (e) {
            if (ref.current && callbackRef.current && !ref.current.contains(e.target)) {
                callbackRef.current(e);
            }
        };
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('touchstart', handleClick, { passive: true });
        return function () {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchstart', handleClick);
        };
    }, []); // no dependencies -> stable click listener
};
export var useOuterClickRef = function (callback) {
    var innerRef = useRef(null);
    useOuterClick(innerRef, callback);
    return innerRef; // convenience for client (doesn't need to init ref himself)
};
export var UseOnPressDirecion;
(function (UseOnPressDirecion) {
    UseOnPressDirecion[UseOnPressDirecion["PRESS_IN"] = 0] = "PRESS_IN";
    UseOnPressDirecion[UseOnPressDirecion["PRESS_OUT"] = 1] = "PRESS_OUT";
})(UseOnPressDirecion || (UseOnPressDirecion = {}));
export var onPressOptionsDefault = {
    disableRightClick: true,
    stopPropagation: false,
    preventDefault: true,
};
export var useOnPress = function (ref, callback, opts) {
    var pressedRef = useRef(false);
    // initialize mutable ref, which stores callback
    var callbackRef = useRef();
    var options = __assign(__assign({}, onPressOptionsDefault), (opts || {}));
    // update cb on each render, so second useEffect has access to current value
    useEffect(function () {
        callbackRef.current = callback;
    });
    // update cb on each render, so second useEffect has access to current value
    useEffect(function () {
        var handleTouchStart;
        var handleTouchEnd;
        var handlePressIn;
        var handlePressOut;
        var handleClick;
        var element = ref.current;
        if (element) {
            element.oncontextmenu = function (ev) {
                if (!options.disableRightClick)
                    return;
                ev.preventDefault();
            };
            handleTouchStart = function (e) {
                var _a;
                (_a = callbackRef.current) === null || _a === void 0 ? void 0 : _a.call(callbackRef, UseOnPressDirecion.PRESS_IN, e);
                pressedRef.current = true;
                if (options.stopPropagation)
                    e.stopPropagation();
                if (options.preventDefault)
                    e.preventDefault();
                return false;
            };
            handleTouchEnd = function (e) {
                var _a;
                if (pressedRef.current) {
                    (_a = callbackRef.current) === null || _a === void 0 ? void 0 : _a.call(callbackRef, UseOnPressDirecion.PRESS_OUT, e);
                    if (options.stopPropagation)
                        e.stopPropagation();
                    return false;
                }
                return true;
            };
            element.addEventListener('touchstart', handleTouchStart, { passive: false });
            element.addEventListener('touchend', handleTouchEnd, { passive: true });
            element.addEventListener('touchcancel', handleTouchEnd, { passive: true });
            handlePressIn = function (e) {
                var _a, _b;
                if ((_a = e === null || e === void 0 ? void 0 : e.sourceCapabilities) === null || _a === void 0 ? void 0 : _a.firesTouchEvents)
                    return false;
                (_b = callbackRef.current) === null || _b === void 0 ? void 0 : _b.call(callbackRef, UseOnPressDirecion.PRESS_IN, e);
                if (options.stopPropagation)
                    e.stopPropagation();
                if (options.preventDefault)
                    e.preventDefault();
                pressedRef.current = true;
                return false;
            };
            handlePressOut = function (e) {
                var _a, _b;
                if ((_a = e === null || e === void 0 ? void 0 : e.sourceCapabilities) === null || _a === void 0 ? void 0 : _a.firesTouchEvents)
                    return false;
                if (pressedRef.current) {
                    (_b = callbackRef.current) === null || _b === void 0 ? void 0 : _b.call(callbackRef, UseOnPressDirecion.PRESS_OUT, e);
                    if (options.stopPropagation)
                        e.stopPropagation();
                    pressedRef.current = false;
                    return false;
                }
                return true;
            };
            handleClick = function (e) {
                var _a;
                if (pressedRef.current) {
                    (_a = callbackRef.current) === null || _a === void 0 ? void 0 : _a.call(callbackRef, UseOnPressDirecion.PRESS_OUT, e);
                    if (options.stopPropagation)
                        e.stopPropagation();
                    pressedRef.current = false;
                    return false;
                }
                e.stopPropagation();
                e.preventDefault();
                return true;
            };
            element.addEventListener('mousedown', handlePressIn, { passive: false });
            element.addEventListener('mouseup', handlePressOut, { passive: true });
            element.addEventListener('mouseleave', handlePressOut, { passive: true });
            element.addEventListener('click', handleClick, { passive: false });
        }
        return function () {
            if (element) {
                element.removeEventListener('touchstart', handleTouchStart);
                element.removeEventListener('touchend', handleTouchEnd);
                element.removeEventListener('touchcancel', handleTouchEnd);
                element.removeEventListener('mousedown', handlePressIn);
                element.removeEventListener('mouseup', handlePressOut);
                element.removeEventListener('mouseleave', handlePressOut);
                element.removeEventListener('click', handleClick);
            }
        };
    });
};
export var useOnPressRef = function (callback) {
    // returned to client, who marks "border" element
    var innerRef = useRef(null);
    useOnPress(innerRef, callback);
    return innerRef; // convenience for client (doesn't need to init ref himself)
};
