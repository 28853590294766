var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
import React from 'react';
import { IconSelector, IconSelectorAdapter } from '../util/IconSelector';
import { ComponentDirection } from '../../constants/global';
import { DeviceScene } from '../../constants/camera';
import CompositeSceneActive from '../../../assets/icons/icon-scene-composite-active.svg';
import CameraSceneActive from '../../../assets/icons/icon-scene-cam-active.svg';
import PresentationSceneActive from '../../../assets/icons/icon-scene-presentation-active.svg';
import SplittedSceneActive from '../../../assets/icons/icon-scene-splitted-active.svg';
import CompositeScene from '../../../assets/icons/icon-scene-composite-inactive.svg';
import CameraScene from '../../../assets/icons/icon-scene-cam-inactive.svg';
import PresentationScene from '../../../assets/icons/icon-scene-presentation-inactive.svg';
import SplittedScene from '../../../assets/icons/icon-scene-splitted-inactive.svg';
import './SceneControls.css';
var DEFAULT_DIRECTION = ComponentDirection.VERTICAL;
var DEFAULT_SCENE_SELECTED = DeviceScene.CAMERA_ONLY;
var DEFAULT_ICON_SIZE = 20;
var IconViewSelectorAdapter = /** @class */ (function (_super) {
    __extends(IconViewSelectorAdapter, _super);
    function IconViewSelectorAdapter(direction, defaultId, iconSize) {
        var _this = _super.call(this, direction, defaultId) || this;
        _this.data = _this.initData(direction, iconSize);
        return _this;
    }
    IconViewSelectorAdapter.prototype.initData = function (_direction, iconSize) {
        if (iconSize === void 0) { iconSize = 20; }
        var style = {};
        Object.assign(style, { width: "".concat(iconSize, "px"), height: "".concat(iconSize, "px") });
        return [
            {
                id: DeviceScene.CAMERA_ONLY,
                active: React.createElement("img", { src: CameraSceneActive, style: style, draggable: false, alt: "cam" }),
                inactive: React.createElement("img", { src: CameraScene, style: style, draggable: false, alt: "cam" }),
            },
            {
                id: DeviceScene.INPUT_HDMI_ONLY,
                active: React.createElement("img", { src: PresentationSceneActive, style: style, draggable: false, alt: "hdmi" }),
                inactive: React.createElement("img", { src: PresentationScene, style: style, draggable: false, alt: "hdmi" }),
            },
            {
                id: DeviceScene.HALF_MODE,
                active: React.createElement("img", { src: SplittedSceneActive, style: style, draggable: false, alt: "splitted" }),
                inactive: React.createElement("img", { src: SplittedScene, style: style, draggable: false, alt: "splitted" }),
            },
            {
                id: DeviceScene.MIXED,
                active: React.createElement("img", { src: CompositeSceneActive, style: style, draggable: false, alt: "mixed" }),
                inactive: React.createElement("img", { src: CompositeScene, style: style, draggable: false, alt: "mixed" }),
            },
        ];
    };
    return IconViewSelectorAdapter;
}(IconSelectorAdapter));
export var DEFAULT_SCENE_MAPPING_KEYBOAD_KEYS = (_a = {},
    _a[DeviceScene.CAMERA_ONLY] = ['A', 'a', 'Q', 'q'],
    _a[DeviceScene.INPUT_HDMI_ONLY] = ['Z', 'z'],
    _a[DeviceScene.HALF_MODE] = ['E', 'e'],
    _a[DeviceScene.MIXED] = ['R', 'r'],
    _a);
export var SceneControls = function (_a) {
    var scene = _a.scene, onScene = _a.onScene, display = _a.display, direction = _a.direction, iconSize = _a.iconSize, enableKeyboardEvent = _a.enableKeyboardEvent, mappingKeyboardKeys = _a.mappingKeyboardKeys, className = _a.className, style = _a.style;
    var dir = direction || DEFAULT_DIRECTION;
    var adapter = new IconViewSelectorAdapter(dir, DEFAULT_SCENE_SELECTED, iconSize !== null && iconSize !== void 0 ? iconSize : DEFAULT_ICON_SIZE);
    var index = adapter.getIndex(scene);
    var data = adapter.getData();
    var onIndex = function (i) {
        var id = adapter.getId(i);
        if (id !== undefined)
            onScene === null || onScene === void 0 ? void 0 : onScene(id);
    };
    if (enableKeyboardEvent && mappingKeyboardKeys) {
        var scenes = Object.keys(mappingKeyboardKeys);
        scenes.forEach(function (key) {
            if (mappingKeyboardKeys[key]) {
                data.forEach(function (d) {
                    var _a;
                    if (key === ((_a = d.id) === null || _a === void 0 ? void 0 : _a.toString())) {
                        d.keyboard = mappingKeyboardKeys[key];
                    }
                });
            }
        });
    }
    return (React.createElement(IconSelector, { index: index, onIndex: onIndex, data: data, direction: direction, display: display, style: style, className: className }));
};
SceneControls.defaultProps = {
    scene: DEFAULT_SCENE_SELECTED,
    display: true,
    direction: DEFAULT_DIRECTION,
    iconSize: DEFAULT_ICON_SIZE,
    enableKeyboardEvent: true,
    mappingKeyboardKeys: DEFAULT_SCENE_MAPPING_KEYBOAD_KEYS,
    className: undefined,
    style: undefined,
    onScene: undefined,
};
export default SceneControls;
