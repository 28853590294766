var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SocketActionType } from "../../constants";
import { SocketActionHandler } from "../../handler";
var WebhooksOnHookActionHandler = /** @class */ (function (_super) {
    __extends(WebhooksOnHookActionHandler, _super);
    function WebhooksOnHookActionHandler() {
        return _super.call(this, SocketActionType.WEBHOOKS_ON_EVENT) || this;
    }
    WebhooksOnHookActionHandler.is = function (webhook, content) {
        return (content === null || content === void 0 ? void 0 : content.name) === webhook;
    };
    WebhooksOnHookActionHandler.prototype.is = function (webhook, content) {
        return WebhooksOnHookActionHandler.is(webhook, content);
    };
    return WebhooksOnHookActionHandler;
}(SocketActionHandler));
export { WebhooksOnHookActionHandler };
