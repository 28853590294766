var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useVideoInterface } from '../../hooks/video';
import { Video } from '../video/Video';
import { PlayerDefaultCommonProps } from '../../constants/player';
import { PlayerControls } from '../controls/PlayerControls';
import { VideoInfo } from './videoInfo';
import './player.css';
export var PlayerMode;
(function (PlayerMode) {
    PlayerMode["CLASSIC"] = "classic";
    PlayerMode["ANTMEDIA"] = "antmedia";
})(PlayerMode || (PlayerMode = {}));
var DEFAULT_SRC_IS_HLS = false;
export var Player = React.forwardRef(function (_a, forwardRef) {
    var displayInfo = _a.displayInfo, customDisplayInfo = _a.customDisplayInfo, onReady = _a.onReady, 
    // Controls
    onDisplayVideoControls = _a.onDisplayVideoControls, onDisplayCameraControls = _a.onDisplayCameraControls, enableVideoControls = _a.enableVideoControls, enableCameraControls = _a.enableCameraControls, alwaysDisplayVideoControls = _a.alwaysDisplayVideoControls, alwaysDisplayCameraControls = _a.alwaysDisplayCameraControls, delayAfterHideControls = _a.delayAfterHideControls, enableVolume = _a.enableVolume, enableFullscreen = _a.enableFullscreen, enableMove = _a.enableMove, enableScene = _a.enableScene, enableView = _a.enableView, enableZoom = _a.enableZoom, enableVideoProgress = _a.enableVideoProgress, displayTime = _a.displayTime, 
    // Controls by keyboard
    enableZoomKeyboardEvent = _a.enableZoomKeyboardEvent, enableViewKeyboardEvent = _a.enableViewKeyboardEvent, enableAssignViewKeyboardEvent = _a.enableAssignViewKeyboardEvent, enableSceneKeyboardEvent = _a.enableSceneKeyboardEvent, enableMoveKeyboardEvent = _a.enableMoveKeyboardEvent, zoomMappingKeyboardKeys = _a.zoomMappingKeyboardKeys, viewMappingKeyboardKeys = _a.viewMappingKeyboardKeys, assignViewMappingKeyboardKeys = _a.assignViewMappingKeyboardKeys, sceneMappingKeyboardKeys = _a.sceneMappingKeyboardKeys, moveMappingKeyboardKeys = _a.moveMappingKeyboardKeys, 
    // Sources
    src = _a.src, srcIsHls = _a.srcIsHls, media = _a.media, onHlsStats = _a.onHlsStats, 
    // Video
    mute = _a.mute, volume = _a.volume, loop = _a.loop, controls = _a.controls, play = _a.play, autoPlay = _a.autoPlay, live = _a.live, playAndPauseAtClick = _a.playAndPauseAtClick, displayPlay = _a.displayPlay, onMute = _a.onMute, onVolume = _a.onVolume, onPlay = _a.onPlay, onTime = _a.onTime, onError = _a.onError, onEnd = _a.onEnd, videoStyle = _a.videoStyle, 
    // Camera
    scene = _a.scene, view = _a.view, onMove = _a.onMove, onStopMove = _a.onStopMove, onZoom = _a.onZoom, onStopZoom = _a.onStopZoom, onScene = _a.onScene, onView = _a.onView, onAssignView = _a.onAssignView, color = _a.color, children = _a.children, className = _a.className, style = _a.style;
    var initialVideoState = {
        mute: mute === undefined ? PlayerDefaultCommonProps.mute : mute,
        volume: volume === undefined ? PlayerDefaultCommonProps.volume : volume,
        play: play === undefined ? PlayerDefaultCommonProps.play : play,
        currentTime: 0,
        duration: 0,
    };
    var videoControls = useVideoInterface(initialVideoState);
    var containerRef = useRef();
    var videoRef = useRef();
    var controlsRef = useRef();
    var hlsStatsRef = useRef();
    var videoInfoRef = useRef();
    var _b = useState(false), videoIsReady = _b[0], setVideoIsReady = _b[1];
    useImperativeHandle(forwardRef, function () { return ({
        isReady: function () { return videoIsReady; },
        getHls: function () { var _a; return (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHls(); },
        getHlsStats: function () { return hlsStatsRef.current; },
        isDisplayingCameraControls: function () { var _a; return ((_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.isDisplayingCameraControls()) || false; },
        isDisplayingVideoControls: function () { var _a; return ((_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.isDisplayingVideoControls()) || false; },
        getControlsRef: function () { return controlsRef.current; },
        getVideoRef: function () { return videoRef.current; },
        getHTMLVideoElement: function () { var _a; return (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHTMLVideoElement(); },
    }); }, [videoIsReady]);
    var onVideoIsReady = function (ready) {
        onReady === null || onReady === void 0 ? void 0 : onReady(ready);
        setVideoIsReady(ready);
    };
    var getInfoType = function () { return (srcIsHls ? 'HLS' : undefined); };
    var getInfoResotion = function () {
        var _a;
        var videoElement = (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHTMLVideoElement();
        var hlsStats = hlsStatsRef.current;
        var resolution;
        if (srcIsHls && (hlsStats === null || hlsStats === void 0 ? void 0 : hlsStats.width) && (hlsStats === null || hlsStats === void 0 ? void 0 : hlsStats.height)) {
            resolution = "".concat(hlsStats.width, "x").concat(hlsStats.height);
        }
        else if ((videoElement === null || videoElement === void 0 ? void 0 : videoElement.videoWidth) && (videoElement === null || videoElement === void 0 ? void 0 : videoElement.videoHeight)) {
            resolution = "".concat(videoElement.videoWidth, "x").concat(videoElement.videoHeight);
        }
        return resolution;
    };
    var updateContentInfo = function () {
        if (!videoInfoRef.current)
            return;
        videoInfoRef.current.setType(getInfoType());
        videoInfoRef.current.setResolution(getInfoResotion());
    };
    var renderInfo = function () {
        if (!displayInfo)
            return null;
        if (customDisplayInfo)
            return customDisplayInfo;
        var getDetails = function () {
            var _a;
            var videoElement = (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.getHTMLVideoElement();
            var hlsStats = hlsStatsRef.current;
            var details = {
                player: 'classic',
                src: src,
                hls: srcIsHls,
                videoElement: {
                    width: videoElement === null || videoElement === void 0 ? void 0 : videoElement.videoWidth,
                    height: videoElement === null || videoElement === void 0 ? void 0 : videoElement.videoHeight,
                    readyState: videoElement === null || videoElement === void 0 ? void 0 : videoElement.readyState,
                    duration: videoElement === null || videoElement === void 0 ? void 0 : videoElement.duration,
                    error: videoElement === null || videoElement === void 0 ? void 0 : videoElement.error,
                    networkState: videoElement === null || videoElement === void 0 ? void 0 : videoElement.networkState,
                },
                hlsStats: hlsStats,
            };
            return JSON.stringify(details, null, 6);
        };
        return (React.createElement(VideoInfo, { ref: videoInfoRef, type: getInfoType(), resolution: getInfoResotion(), getDetails: getDetails }));
    };
    var renderControls = function () { return (React.createElement(PlayerControls, { ref: controlsRef, onDisplayVideoControls: onDisplayVideoControls, onDisplayCameraControls: onDisplayCameraControls, enabled: videoIsReady, enableCameraControls: enableCameraControls, enableVideoControls: enableVideoControls, alwaysDisplayCameraControls: alwaysDisplayCameraControls, alwaysDisplayVideoControls: alwaysDisplayVideoControls, delayAfterHideControls: delayAfterHideControls, className: "player-controls-container", fullscreenTargetRef: containerRef, mute: videoControls.getMute(), volume: videoControls.getVolume(), play: videoControls.getPlay(), onMute: function (m) {
            var _a;
            videoControls.setMute(m);
            (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.setMute(m);
            onMute === null || onMute === void 0 ? void 0 : onMute(m);
        }, onVolume: function (v) {
            var _a;
            videoControls.setVolume(v);
            (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.setVolume(v);
            onVolume === null || onVolume === void 0 ? void 0 : onVolume(v);
        }, onPlay: function (p) {
            var _a;
            videoControls.setPlay(p);
            (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.setPlay(p);
            onPlay === null || onPlay === void 0 ? void 0 : onPlay(p);
        }, onTime: function (t) {
            var _a;
            videoControls.setCurrentTime(t);
            (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.setCurrentTime(t);
            onTime === null || onTime === void 0 ? void 0 : onTime(t, 0, videoControls.getDuration());
        }, startsAt: 0, currentTime: videoControls.getCurrentTime(), duration: videoControls.getDuration(), enablePlay: !videoControls.getPlay() || displayPlay, enableFullscreen: enableFullscreen, enableVolume: enableVolume, scene: scene, view: view, onMove: onMove, onStopMove: onStopMove, onZoom: onZoom, onStopZoom: onStopZoom, onScene: onScene, onView: onView, onAssignView: onAssignView, enableMove: enableMove, enableScene: enableScene, enableView: enableView, enableZoom: enableZoom, enableVideoProgress: enableVideoProgress, displayTime: displayTime, 
        // Controls by keyboard
        enableZoomKeyboardEvent: enableZoomKeyboardEvent, enableViewKeyboardEvent: enableViewKeyboardEvent, enableAssignViewKeyboardEvent: enableAssignViewKeyboardEvent, enableSceneKeyboardEvent: enableSceneKeyboardEvent, enableMoveKeyboardEvent: enableMoveKeyboardEvent, zoomMappingKeyboardKeys: zoomMappingKeyboardKeys, viewMappingKeyboardKeys: viewMappingKeyboardKeys, assignViewMappingKeyboardKeys: assignViewMappingKeyboardKeys, sceneMappingKeyboardKeys: sceneMappingKeyboardKeys, moveMappingKeyboardKeys: moveMappingKeyboardKeys, color: color, displayInfo: renderInfo() })); };
    var renderVideo = function () { return (React.createElement(Video, { ref: videoRef, style: videoStyle, className: "player-video", media: media, src: src, srcIsHls: srcIsHls, controls: controls, loop: loop, autoPlay: autoPlay, mute: videoControls.getMute(), volume: videoControls.getVolume(), play: live === true || videoControls.getPlay(), onMute: function (value) {
            var _a;
            videoControls.setMute(value);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setMute(value);
        }, onVolume: function (value) {
            var _a;
            videoControls.setVolume(value);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setVolume(value);
        }, onPlay: function (value) {
            var _a, _b;
            if (!value && live) {
                setTimeout(function () {
                    var _a;
                    // In live mode, don't pause the video
                    (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.setPlay(true);
                }, 10);
            }
            videoControls.setPlay(value);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setPlay(value);
            (_b = controlsRef.current) === null || _b === void 0 ? void 0 : _b.setDisplayPlay(!value || (displayPlay !== null && displayPlay !== void 0 ? displayPlay : false));
        }, onCurrentTime: function (time, duration) {
            var _a, _b;
            videoControls.setCurrentTime(time);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setCurrentTime(time);
            (_b = controlsRef.current) === null || _b === void 0 ? void 0 : _b.setDuration(duration);
        }, onDuration: function (time) {
            var _a;
            videoControls.setDuration(time);
            (_a = controlsRef.current) === null || _a === void 0 ? void 0 : _a.setDuration(time);
        }, currentTime: videoControls.getCurrentTime(), onEnd: onEnd, onError: onError, onHlsStats: function (stats) {
            hlsStatsRef.current = stats;
            updateContentInfo();
            onHlsStats === null || onHlsStats === void 0 ? void 0 : onHlsStats(stats);
        }, onReady: function (ready) {
            updateContentInfo();
            onVideoIsReady === null || onVideoIsReady === void 0 ? void 0 : onVideoIsReady(ready);
        } })); };
    var renderChildren = function () {
        var result = children === null || children === void 0 ? void 0 : children();
        if (result) {
            return (React.createElement("div", { className: "player-children-container" }, result));
        }
        return null;
    };
    var classes = ['player-container'];
    if (className)
        classes.push(className);
    var render = function () { return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    React.createElement("div", { ref: function (el) {
            containerRef.current = el;
            if (!el)
                return;
            // onClick in div doesn't work in fullscreen.
            el.onclick = function () {
                if (!playAndPauseAtClick)
                    return;
                if (videoRef.current)
                    videoRef.current.setPlay(!videoRef.current.getPlay());
            };
        }, style: style, className: classes.join(' ') },
        renderVideo(),
        renderChildren(),
        renderControls())); };
    return render();
});
Player.defaultProps = __assign(__assign({}, PlayerDefaultCommonProps), { 
    // Sources
    src: undefined, srcIsHls: DEFAULT_SRC_IS_HLS, media: undefined, children: undefined, className: undefined, style: undefined });
export default Player;
