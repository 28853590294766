import { __awaiter, __extends, __generator, __read, __spreadArray } from "tslib";
import 'webrtc-adapter';
import { io } from 'socket.io-client';
import { Session } from './session';
import { w3cwebsocket as WebSocket } from 'websocket';
var socketOptions = {
    forceNew: true,
    reconnection: false,
    transports: ['websocket'],
};
export var SocketType;
(function (SocketType) {
    SocketType["SOCKETIO"] = "socketio";
    SocketType["WEBSOCKET"] = "websocket";
})(SocketType || (SocketType = {}));
var SocketSession = /** @class */ (function (_super) {
    __extends(SocketSession, _super);
    function SocketSession(type) {
        var _this = _super.call(this) || this;
        _this.type = type;
        return _this;
    }
    SocketSession.prototype.connect = function (url) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var socketUrl, socket, err_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'start - ', typeof url, ' - url : ', url);
                        this.started = true;
                        this.closed = false;
                        socketUrl = url;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        (_b = this.log) === null || _b === void 0 ? void 0 : _b.call(this, 'Connect to : ', socketUrl);
                        return [4 /*yield*/, this.initSocket(socketUrl)];
                    case 2:
                        socket = _d.sent();
                        (_c = this.log) === null || _c === void 0 ? void 0 : _c.call(this, 'Connected : ', socket);
                        if (socket) {
                            if (this.closed)
                                this.close("Can't connect: session already closed.");
                            else
                                this.socket = socket;
                        }
                        else if (!this.closed) {
                            throw new Error('Error socket connection ...');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _d.sent();
                        this.started = false;
                        this.triggersErrorListeners(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, this.socket];
                }
            });
        });
    };
    SocketSession.prototype.close = function (reason) {
        if (this.closed)
            return;
        this.started = false;
        this.closed = true;
        this.disconnect();
        this.triggersCloseListeners(reason);
        this.socket = undefined;
    };
    SocketSession.prototype.stop = function (reason) {
        this.close(reason);
    };
    SocketSession.prototype.getSocket = function () {
        return this.socket;
    };
    SocketSession.prototype.socketIsSocketIO = function (socket) {
        return this.type === SocketType.SOCKETIO;
    };
    SocketSession.prototype.socketIsWebsocket = function (socket) {
        return this.type === SocketType.WEBSOCKET;
    };
    // ---------------- Listeners ------------------ //
    SocketSession.prototype.triggersConnectedListeners = function () {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'connected ');
        (_b = this.listeners.connect) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(); });
    };
    SocketSession.prototype.triggersStartListeners = function (iceServers, offer, candidates) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'start : ', iceServers);
        (_b = this.listeners.start) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(iceServers, offer, candidates); });
    };
    SocketSession.prototype.triggersErrorListeners = function (error) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'error', error);
        (_b = this.listeners.error) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(error); });
    };
    SocketSession.prototype.triggersCloseListeners = function (reason) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'close');
        (_b = this.listeners.close) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(reason); });
    };
    SocketSession.prototype.triggersIceCandidateListeners = function (candidate) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'iceCandidateCB : ', candidate);
        (_b = this.listeners.iceCandidate) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(candidate); });
    };
    SocketSession.prototype.triggersSdpListeners = function (offer) {
        var _a, _b;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'sdpCB: ', offer);
        (_b = this.listeners.sdp) === null || _b === void 0 ? void 0 : _b.forEach(function (curr) { return curr(offer); });
    };
    // ---------------- SOCKET ------------------ //
    SocketSession.prototype.initSocket = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var socket_1, socket;
            var _this = this;
            return __generator(this, function (_a) {
                if (this.closed)
                    return [2 /*return*/, null];
                if (this.type === SocketType.SOCKETIO) {
                    socket_1 = io(url, socketOptions);
                    socket_1.on('connect', this.socketEventOnConnect.bind(this));
                    socket_1.on('disconnect', this.socketEventOnDisconnect.bind(this));
                    socket_1.io.on('error', this.socketEventOnError.bind(this));
                    socket_1.onAny(function (event) {
                        var data = [];
                        for (var _i = 1; _i < arguments.length; _i++) {
                            data[_i - 1] = arguments[_i];
                        }
                        _this.onReceivedMessage.bind(_this)({
                            event: event,
                            data: data
                        });
                    });
                    // this.socketEventOnMessage.bind(this)
                    return [2 /*return*/, socket_1];
                }
                socket = new WebSocket(url);
                socket.onopen = function () { _this.socketEventOnConnect.bind(_this)(); };
                socket.onclose = function () { _this.socketEventOnDisconnect.bind(_this); };
                socket.onmessage = function (event) {
                    _this.onReceivedMessage.bind(_this)(event.data);
                };
                socket.onerror = function (event) { _this.socketEventOnError.bind(_this)(event); };
                return [2 /*return*/, socket];
            });
        });
    };
    SocketSession.prototype.disconnect = function () {
        if (!this.socket)
            return;
        if (this.socketIsSocketIO(this.socket)) {
            this.socket.disconnect();
            return;
        }
        this.socket.close();
    };
    SocketSession.prototype.emit = function (data) {
        var _a;
        var _b;
        (_b = this.log) === null || _b === void 0 ? void 0 : _b.call(this, 'EMIT - ', this.type, ' : ', data);
        if (!this.socket)
            return;
        if (this.socketIsSocketIO(this.socket)) {
            var message_1 = data;
            (_a = this.socket).emit.apply(_a, __spreadArray([message_1.event], __read(message_1.data), false));
            return;
        }
        var message = data;
        this.socket.send(typeof message === 'string' ? message : JSON.stringify(message));
    };
    // ---------------- SOCKET Events ------------------ //
    SocketSession.prototype.socketEventOnConnect = function () {
        var _a;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'socketOnConnect');
        if (this.closed)
            this.stop("Can't connect: session already closed.");
        else
            this.triggersConnectedListeners();
    };
    SocketSession.prototype.socketEventOnDisconnect = function () {
        var _a;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'socketOnDisconnect');
        this.close("Socket disconnected");
    };
    SocketSession.prototype.socketEventOnError = function (error) {
        var _a;
        (_a = this.log) === null || _a === void 0 ? void 0 : _a.call(this, 'socketOnError : ', error);
        this.triggersErrorListeners(error);
    };
    return SocketSession;
}(Session));
export { SocketSession };
