import { SocketActionType } from "../../constants";
// ------------------------------- //
// ------------ Ptz ------------ // 
// ------------------------------- //
function move(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_MOVE, payload, options);
}
function stopMove(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_STOP_MOVE, payload, options);
}
function zoom(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_ZOOM, payload, options);
}
function stopZoom(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_STOP_ZOOM, payload, options);
}
// ------------------------------- //
// ------------ System ------------ // 
// ------------------------------- //
function getInformation(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_INFORMATION, payload, options);
}
function reboot(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_REBOOT, payload, options);
}
function factoryReset(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_FACTORY_RESET, payload, options);
}
function setSystemTime(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_SYSTEM_SET_TIME, payload, options);
}
function setSystemTimezone(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_SYSTEM_SET_TIMEZONE, payload, options);
}
// ------------------------------- //
// ------------ Update ------------ // 
// ------------------------------- //
function startDownloadUpdate(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_UPDATE_START_DOWNLOAD, payload, options);
}
function installUpdate(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_UPDATE_INSTALL, payload, options);
}
function scheduleSilentUpdate(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_UPDATE_SCHEDULE_SILENT, payload, options);
}
function abortUpdate(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_UPDATE_ABORT, payload, options);
}
function getUpdateSessions(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_UPDATE_GET_SESSIONS, payload, options);
}
// ------------------------------- //
// ------------ Video ------------ // 
// ------------------------------- //
function startLive(payload, options) {
    return this.emitMessage(SocketActionType.LIVE_START, payload, options);
}
function stopLive(payload, options) {
    return this.emitMessage(SocketActionType.LIVE_STOP, payload, options);
}
function startRecord(payload, options) {
    return this.emitMessage(SocketActionType.RECORD_START, payload, options);
}
function stopRecord(payload, options) {
    return this.emitMessage(SocketActionType.RECORD_STOP, payload, options);
}
function getVideoContext(payload, options) {
    return this.emitMessage(SocketActionType.VIDEO_CONTEXT, payload, options);
}
function switchScene(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_SWITCH_SCENE, payload, options);
}
function switchView(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_SWITCH_VIEW, payload, options);
}
function setView(payload, options) {
    return this.emitMessage(SocketActionType.DEVICE_SET_VIEW, payload, options);
}
var deviceCommands = {
    // Ptz
    move: move,
    stopMove: stopMove,
    zoom: zoom,
    stopZoom: stopZoom,
    // System
    getInformation: getInformation,
    setSystemTime: setSystemTime,
    setSystemTimezone: setSystemTimezone,
    reboot: reboot,
    factoryReset: factoryReset,
    // Update
    startDownloadUpdate: startDownloadUpdate,
    installUpdate: installUpdate,
    scheduleSilentUpdate: scheduleSilentUpdate,
    abortUpdate: abortUpdate,
    getUpdateSessions: getUpdateSessions,
    // Video
    startLive: startLive,
    stopLive: stopLive,
    startRecord: startRecord,
    stopRecord: stopRecord,
    getVideoContext: getVideoContext,
    switchScene: switchScene,
    switchView: switchView,
    setView: setView
};
export default deviceCommands;
