var _a;
import React, { useState } from 'react';
import { DeviceZoomDirection } from '../../constants/camera';
import { KeyboardAction, useKeyboardTouched } from '../../hooks/keyboard';
import { useWindowUnfocused } from '../../hooks/window';
import ZoomPlusActive from '../../../assets/icons/icon-zoom-plus-active.svg';
import ZoomLessActive from '../../../assets/icons/icon-zoom-less-active.svg';
import ZoomPlus from '../../../assets/icons/icon-zoom-plus.svg';
import ZoomLess from '../../../assets/icons/icon-zoom-less.svg';
import { Touchable } from '../util/Touchable';
import { ComponentDirection } from '../../constants/global';
import './ZoomControls.css';
var DEFAULT_ICON_SIZE = 20;
export var DEFAULT_ZOOM_MAPPING_KEYBOAD_KEYS = (_a = {},
    _a[DeviceZoomDirection.ZOOM_IN] = ['+'],
    _a[DeviceZoomDirection.ZOOM_OUT] = ['-'],
    _a);
var getButtons = function (iconSize) { return [
    {
        direction: DeviceZoomDirection.ZOOM_OUT,
        active: React.createElement("img", { src: ZoomLessActive, style: { width: "".concat(iconSize, "px"), height: "".concat(iconSize, "px") }, draggable: false, alt: "zoom-" }),
        inactive: React.createElement("img", { src: ZoomLess, style: { width: "".concat(iconSize, "px"), height: "".concat(iconSize, "px") }, draggable: false, alt: "zoom-" }),
    },
    {
        direction: DeviceZoomDirection.ZOOM_IN,
        active: React.createElement("img", { src: ZoomPlusActive, style: { width: "".concat(iconSize, "px"), height: "".concat(iconSize, "px") }, draggable: false, alt: "zoom+" }),
        inactive: React.createElement("img", { src: ZoomPlus, style: { width: "".concat(iconSize, "px"), height: "".concat(iconSize, "px") }, draggable: false, alt: "zoom+" }),
    },
]; };
export var ZoomControls = function (_a) {
    var onZoom = _a.onZoom, onStopZoom = _a.onStopZoom, display = _a.display, direction = _a.direction, iconSize = _a.iconSize, enableKeyboardEvent = _a.enableKeyboardEvent, mappingKeyboardKeys = _a.mappingKeyboardKeys, className = _a.className, style = _a.style;
    var _b = useState(), zoomDirection = _b[0], setZoomDirection = _b[1];
    var zoom = function (dir) {
        onZoom === null || onZoom === void 0 ? void 0 : onZoom(dir);
        if (dir !== zoomDirection)
            setZoomDirection(dir);
    };
    var stopZoom = function () {
        onStopZoom === null || onStopZoom === void 0 ? void 0 : onStopZoom();
        setZoomDirection(null);
    };
    var buttons = getButtons(iconSize !== null && iconSize !== void 0 ? iconSize : DEFAULT_ICON_SIZE);
    useWindowUnfocused(function () {
        if (zoomDirection) {
            stopZoom();
        }
    });
    useKeyboardTouched(function (action, key, repeat, event) {
        if (!mappingKeyboardKeys || !enableKeyboardEvent)
            return;
        var directions = [DeviceZoomDirection.ZOOM_IN, DeviceZoomDirection.ZOOM_OUT];
        directions.forEach(function (dir) {
            if (mappingKeyboardKeys[dir]) {
                var keys = Array.isArray(mappingKeyboardKeys[dir])
                    ? mappingKeyboardKeys[dir] : [mappingKeyboardKeys[dir]];
                if (keys.includes(key)) {
                    if (action === KeyboardAction.DOWN) {
                        event.preventDefault();
                        if (!repeat) {
                            zoom(dir);
                        }
                    }
                    else if (action === KeyboardAction.UP) {
                        stopZoom();
                    }
                }
            }
        });
    });
    var render = function () {
        var divs = [];
        var buttonClasses = [];
        if (direction === ComponentDirection.HORIZONTAL) {
            buttonClasses.push('zoom_controls-button-horizontal');
        }
        else if (direction === ComponentDirection.VERTICAL) {
            buttonClasses.push('zoom_controls-button-vertical');
        }
        buttons.forEach(function (button) {
            divs.push(React.createElement(Touchable, { className: buttonClasses.join(' '), additionalStylePressed: {}, key: button.direction, onPressIn: function () { return zoom(button.direction); }, onPressOut: function () { return stopZoom(); } }, zoomDirection === button.direction ? button.active : button.inactive));
        });
        return divs;
    };
    var classes = ['zoom_controls-container'];
    if (className)
        classes.push(className);
    if (!display)
        classes.push('zoom_controls-hide');
    if (direction === ComponentDirection.HORIZONTAL)
        classes.push('zoom_controls-horizontal');
    else if (direction === ComponentDirection.VERTICAL)
        classes.push('zoom_controls-vertical');
    return (React.createElement("div", { style: style, className: classes.join(' ') }, render()));
};
ZoomControls.defaultProps = {
    display: true,
    direction: ComponentDirection.HORIZONTAL,
    iconSize: DEFAULT_ICON_SIZE,
    onZoom: undefined,
    onStopZoom: undefined,
    enableKeyboardEvent: true,
    mappingKeyboardKeys: DEFAULT_ZOOM_MAPPING_KEYBOAD_KEYS,
    className: undefined,
    style: undefined,
};
export default ZoomControls;
